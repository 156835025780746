import * as actionTypes from './actionTypes';

export const addCart = ( item ) => {
  return {
      type: actionTypes.ADD_CART,
      item: item
  };
};

export const removeCart = ( item ) => {
  return {
      type: actionTypes.DEL_CART,
      item: item
  };
};

export const updateCart = ( item ) => {
  return {
      type: actionTypes.UPDATE_CART,
      item: item
  };
};

export const updateAddress = ( address ) => {
  return {
    type: actionTypes.UPDATE_ADDRESS,
    address: address
  }
} 

export const updateTime = ( time ) => {
  return {
    type: actionTypes.UPDATE_TIME,
    time: time
  }
} 

export const updatePhone = ( phone ) => {
  return {
    type: actionTypes.UPDATE_PHONE,
    phone: phone
  }
} 

export const updateArea = ( area ) => {
  return {
    type: actionTypes.UPDATE_AREA,
    areaId: area
  }
} 

export const deleteCart = ( item ) => {
  return {
      type: actionTypes.CLEAR_CART,
  };
};

export const storeTable = ( table ) => {
  return {
      type: actionTypes.SET_TABLE,
      table: table
  };
};

export const setCartType = ( cartType ) => {
  return {
    type: actionTypes.SET_TYPE,
    typeId: cartType
  }
}

export const updateQtyCart = ( item ) => {
  return {
    type: actionTypes.UPDATE_QTY_CART,
    item: item
  }
} 

export const storeMerchant = ( merchant, merchantName, creator, parentId, parentName ) => {
  const merchantInfo = {
    merchantId: merchant,
    merchantName: merchantName,
    creator: creator,
    parentId: parentId,
    parentName: parentName,
  }
  return {
    type: actionTypes.SET_MERCHANT,
    merchant: merchantInfo
  }
}

export const updateMerchant = (merchant, merchantName) => {
  const merchantInfo = {
    merchantId: merchant,
    merchantName: merchantName,
  }
  return {
    type: actionTypes.UPDATE_MERCHANT,
    merchant: merchantInfo
  }
}

