import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import RoundedBottom from '../../components/UI/Button/RoundedButton/RoundedButton';
import Complete from '../../components/completePreorder';

class Success extends Component {
  render() {
    return (
      <div>
        <Complete></Complete>
        <div className="button-cart">
          <Link to="/">
            <RoundedBottom bgcolor="#8A4537" className="button-cart-list" positions="Bottom">Home</RoundedBottom>
          </Link>
        </div>
      </div>
    )
  }
}

export default Success;