import  React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import {connect} from 'react-redux';
import api from '../../../services/network';
import imageDefault from '../../../assets/images/wallet.png';
import imageBCA from '../../../assets/images/bca.png';
import { balanceApiPath } from '../../../services/networkApi';
import NumberFormat from 'react-number-format';
import showNotifications from '../../../components/toast';
import copy from 'copy-to-clipboard';
import '../../../containers/Payment/Wallet/Wallet.css';
import './Voucher.css';

class Voucher extends Component {
  state = {
    balance: 0,
    va: '',
  }

  async componentDidMount() {
    if (this.props.isLogin !== true) {
      this.props.history.push('/');
    } else {
      const balanceApi = api(balanceApiPath);
      let payload = {
        email: this.props.email,
        token: this.props.token
      }
      const balanceRes = await balanceApi.post(payload);
      if (balanceRes) { 
        const { errors } = balanceRes;
        if (errors) {
          if(errors[0].message !== '') {
            showNotifications(errors[0].message, 'error', 'Balance Wallet');
          } else {
            this.setState({ balance: balanceRes.balance, va: balanceRes.va_no})
          }
        }
      }
    }
  }

  copyToClip = () => {
    copy(this.state.va)
    alert('copied to clipboard');
  }

  render() {
    return(
      <div className="container">
        <h5 className="auth-header">Which payment do you want to choose?</h5>
        <div className="wallet-box push-bottom-lg">
          <Row noGutters={true} className="wallet-info-box">
            <Col xs={9} md={9}>
              <div className="listbody">
                <div className="information">{this.props.userName} Voucher</div>
                <div className="information-header">Current Point</div>
                  <div className="push-up information-header">
                    {/* pts {this.state.balance} */}
                    <NumberFormat value={this.state.balance} displayType={'text'} thousandSeparator={true} prefix={'pts '} />
                  </div>
              </div>
            </Col>
            <Col xs={3} md={3}>
              <div className="img-wallet">
                <img src={imageDefault} alt=""/>
              </div>
            </Col>
          </Row>            
        </div>

        <div className="d-flex justify-content-center marginsections push-up information">
          TOP UP
        </div>

        <div className="bank-box push-up-md">
          <Row noGutters={true}>
            <Col xs={9} md={9}>
              <div className="information text-middle">Bank Central Asia</div>
              <div className="information-small text-middle">No VA</div>
              <div className="information text-middle">{this.state.va}</div>
              <div className="information-small text-middle" tag="a" onClick={this.copyToClip}><u>Copy to ClipBoard</u></div>
            </Col>
            <Col xs={3} md={3}>
              <div className="voucher-bank">
                <img src={imageBCA} alt=""/>
              </div>
            </Col>
          </Row>        
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      token: state.user.token,
      isLogin: state.user.isLoggedIn,
      userName: state.user.fullName,
      email: state.user.email
  };
};


export default connect(mapStateToProps, null) (Voucher)