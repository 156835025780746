import React from 'react';
import { Card, Row, Col } from 'react-bootstrap';
import { imageApi } from '../../config';
import RoundedBottom from '../../components/UI/Button/RoundedButton/RoundedButton';
import imageDefault from '../../assets/images/okgo.png';
import NumberFormat from 'react-number-format';
import './Cart.css';

const Cart = (props) => {
  const remarkNotes = props.menuRemark === '' ? 'Make Notes' : 'Update Notes';
  const addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }
  return (
    <Card className="card-no-border">
        <Row noGutters={true}>
          <Col xs={4} md={4}>
            <div className="list">
              <img onError={addDefaultSrc} src={imageApi+props.menuImage} alt=""/>
            </div>
          </Col>
          <Col xs={8} md={8}>
            <div className="listbody">
              <div className="information-header">{props.menuName}</div>
              <div className="information">{props.merchant}</div>
              <div className="information">
                {/* IDR {props.menuPrice/1000} */}
                <NumberFormat value={props.menuPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
              </div>
              <div className="information push-bottom">
                <div tag="a" onClick={props.addRemark}>
                  <RoundedBottom border='1px solid' color="black" width="70%" height="auto" className="no-color">{remarkNotes}</RoundedBottom>
                </div>
              </div>
              <div>
                {/* <button onClick={props.decQty} className="button-order push-right">-</button> 
                {props.menuQty} 
                <button onClick={props.incQty} className="button-order push-left">+</button> */}
                <div className="button-counter-plus-minus-cart space-margin-b-10 bottom-0 information">
                  <button
                    className="dec qty" onClick={props.decQty}> - </button>
                  <input
                    className="plus-minus-box"
                    type="text"
                    value={props.menuQty}
                    readOnly />
                  <button
                    className="inc qty" onClick={props.incQty}> + </button>
                </div>
              </div>
            </div>
          </Col>
        </Row>            
    </Card>
  )
}

export default Cart;