import { createStore, applyMiddleware, combineReducers } from 'redux';
import jwt from 'jsonwebtoken';
import thunk from 'redux-thunk';
import {secretKeyStorage} from '../config';
import userReducer from './Reducers/user';
import cartReducer from './Reducers/cart';

// const loadState = () => {
//   try {
//     const serializedState = localStorage.getItem('state');
//     if(serializedState === null) {
//       return undefined;
//     }
//     console.log(JSON.parse(serializedState))
//     return JSON.parse(serializedState);
//   } catch (e) {
//     return undefined;
//   }
// };

// const loadState = () => {
//   try {
//     jwt.verify(localStorage.getItem('state'), secretKeyStorage, (err, decoded) => {
//       // console.log(localStorage.getItem('state'))
//       // console.log(localStorage.getItem('stateToken'))
//       // console.log(decoded)
//       console.log(localStorage.getItem('stateToken').toString())
//       console.log(hashAlgorithm(JSON.stringify(localStorage.getItem('ticket'))).toString())
//       if (decoded) {
//         if (localStorage.getItem('state')) {
//           const info = decoded;
//           console.log(info)
//           if (hashAlgorithm(JSON.stringify(localStorage.getItem('ticket'))).toString() !== localStorage.getItem('stateToken').toString()) {
//             localStorage.clear();
//           }

//           return info;
//         }
//         return undefined;
//       }
//       localStorage.clear();
//     })
//   } catch (e) {
//     return undefined;
//   }
// };

const hashAlgorithm = (s) => {
  return 'znjsgkyrss19' + s + 'cbdhiase7812'
}

const saveState = (state) => {
  try {
    const { user, cart } = state;
    const crypto = jwt.sign({
      user, cart
    }, secretKeyStorage);
    let randId = '';
    if (localStorage.getItem('stateToken') == null) {
      randId = Math.floor((Math.random() * 100000) + 1).toString();
    } else {
      randId = localStorage.getItem('ticket').toString();
    }
    localStorage.setItem('state', crypto);
    localStorage.setItem('stateToken', hashAlgorithm(randId));
    localStorage.setItem('ticket', randId)
  } catch (e) {
   
  }
};

// const saveState = (state) => {
//   try {
//     const serializedState = JSON.stringify(state);
//     localStorage.setItem('state', serializedState);
//   } catch (e) {
   
//   }
// };

const peristedState = jwt.verify(localStorage.getItem('state'), secretKeyStorage, (err, decoded) => {
  
  if (decoded) {
    if (localStorage.getItem('state')) {
      let { user, cart } = decoded;
      if (hashAlgorithm(localStorage.getItem('ticket')).toString() !== localStorage.getItem('stateToken').toString()) {
        localStorage.clear();
        user = [];
        cart = [];
      }
      return { user, cart };
    }
    return undefined;
  }
  localStorage.clear();
})

const rootReducer = combineReducers({
    user: userReducer,
    cart: cartReducer
  });
  
const store = createStore(rootReducer, peristedState, applyMiddleware(thunk));

store.subscribe(() => {
  saveState(store.getState());
});

export default store;