import React, { Component } from 'react';
import { myOrderApiPath } from '../../../services/networkApi';
import api from '../../../services/network';
import { connect } from 'react-redux';
import showNotifications from '../../../components/toast';
import Order from '../../../components/Order/Order';
import Spinner from '../../../components/UI/Spinner/Spinner';
import './OrderList.css';

class OrderList extends Component {
  state = {
    listingData: [],
    loading: true,
  }

  async componentDidMount() {
    if (this.props.isLogin !== true) {
      this.props.history.push('/');
    } else {
      const myOrderApi = api(myOrderApiPath);
      let payload = {
        token: this.props.token,
        email: this.props.email
      }
      
      const myOrderRes = await myOrderApi.post(payload); 
      if (myOrderRes) { 
        if (myOrderRes.errors ) {
          showNotifications(myOrderRes.errors[0].message, 'error', 'My Order')
        } else {
          this.setState({
            listingData: myOrderRes,
            loading: false,
          });
        }
      }
    }
  }

  clicked = (el) => {
    const queryParams = [];
    queryParams.push('tid=' + el.id);
    queryParams.push('invoice=' + el.invoice);
    queryParams.push('phone=' + el.phone);
    const queryString = queryParams.join('&');
    this.props.history.push({
      pathname: this.props.match.path + '/details',
      search: '?' + queryString
    });
  }

  render() {
    let listing = null;
    let spin = null;
    listing = this.state.listingData
    .map( el => (
      <Order key={el.id} 
        orderInvoice={el.invoice} 
        merchant={el.merchantName} 
        orderDate={el.createdAt}
        status={el.status}
        clicked={() => this.clicked(el)}
        ></Order>
    ))

    if ( this.state.loading ) {
      spin = <Spinner />;
    }

    return (
      <div className="container">
        <div className="app-fixed-center front">
          {spin}
        </div>
        
        <h6 className="push-bottom-md">MY ORDERS</h6>
        {listing}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.user.token,
    email: state.user.email,
    isLogin: state.user.isLoggedIn
  };
};

export default connect(mapStateToProps, null)(OrderList);