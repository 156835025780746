import React, {Component} from 'react';
import Aux from '../Aux/Aux';
import './Layout.css';
import Toolbar from '../../components/Navigation/Toolbar/Toolbar';
import SideDrawer from '../../components/Navigation/SideDrawer/SideDrawer';

class Layout extends Component {
  state = {
    showSideDrawer: false,
    switch: true,
  }

  switchHandler = () => {
    this.setState( ( prevState ) => {
      return { switch: !prevState.switch };
    } );
  }

  sideDrawerClosedHandler = () => {
    this.setState( { showSideDrawer: false } );
  }

  sideDrawerToggleHandler = () => {
    this.setState( ( prevState ) => {
      return { showSideDrawer: !prevState.showSideDrawer };
    } );
  }

  render() {

    // const ListChild = (props) => {
    //   let elements = React.Children.toArray(this.props.children)
    //   // console.log(elements);
    //   elements = React.cloneElement(elements[0], { data: this.state })
    //   return (
    //   <main className="Content">
    //     {elements}
    //   </main>)
    // }

    return (
      <Aux>
        <Toolbar switchState={this.state.switch} switchClicked={this.switchHandler} drawerToggleClicked={this.sideDrawerToggleHandler}></Toolbar>
        <SideDrawer open={this.state.showSideDrawer} closed={this.sideDrawerClosedHandler}></SideDrawer>
        {/* <ListChild></ListChild> */}
          <main className="Content">
            {this.props.children}
          </main>
      </Aux>
    )
  }
}

export default Layout;