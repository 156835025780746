import React, { Component } from 'react';
import api from '../../../services/network';
import {connect} from 'react-redux';
import { linkAjaUpdateStatusApi } from '../../../services/networkApi';
import showNotifications from '../../../components/toast';

class LinkAjaPaymentSuccess extends Component {
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let trxId = '';
    for (let param of query.entries()) {
      if (param[0] === 'trxId') {
        trxId = param[1];
      }
    }
    if (trxId !== '') {
      const updataLinkAjaApi = api(linkAjaUpdateStatusApi);
      const lastIndex = trxId.lastIndexOf("-");

      const transId = trxId.substr(0, lastIndex)

      let payload = {
        token: this.props.token,
        invoice: transId
      }
      
      const updateLinkAjaRes = await updataLinkAjaApi.post(payload);

      if (updateLinkAjaRes) {
        const { errors } = updateLinkAjaRes;
        if (errors) {
          if(errors[0].message !== '') {
            showNotifications('Maaf terjadi kesalahan pada app, mohon hubungi pihak Okgo', 'error', 'Checkout with linkaja');
          } else {
            // showNotifications('Terima kasih telah menggunakan OkGo', 'success', 'Checkout');
            this.props.history.push('/payment/success');
          }
        } 
      }
    } else {
      showNotifications('Maaf terjadi kesalahan pada app, mohon hubungi pihak Okgo', 'error', 'Checkout with linkaja'); 
    }
  }

  render() {
    return (
      <div></div>
    )
  }
}

const mapStateToProps = state => {
  return {
      token: state.user.token
  };
};

export default connect(mapStateToProps, null) (LinkAjaPaymentSuccess);


