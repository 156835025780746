import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import ListMenu from '../ListMenu/ListMenu';
import PropTypes from 'prop-types';

const PaginatedMenus = (props) => {
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + props.itemsPerPage;
    setCurrentItems(props.items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(props.items.length / props.itemsPerPage));
  }, [itemOffset, props.itemsPerPage, props.items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * props.itemsPerPage) % props.items.length;
    setItemOffset(newOffset);
  };

  return (
    <React.Fragment>
      <ListMenu 
        currentItems={currentItems} 
        incMenu={props.triggerInc}
        decMenu={props.triggerDec}
        updateMenuQty={props.triggerUpdateQty}
        // onClickMenu={props.onClickItem} 
      />
  
      <div className="d-flex justify-content-center marginsections">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />
      </div>
    </React.Fragment>
  )
}

PaginatedMenus.propTypes = {
  items: PropTypes.array,
  itemsPerPage: PropTypes.number,
  triggerInc: PropTypes.func,
  triggerDec: PropTypes.func,
  triggerUpdateQty: PropTypes.func
  // onClickItem: PropTypes.func,
}

export default PaginatedMenus;