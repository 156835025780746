import React, { useState } from 'react';
import './NavigationItems.css';
import NavigationItem from './NavigationItem/NavigationItem';
import { connect } from 'react-redux';
import Aux from '../../../hoc/Aux/Aux';
import * as actions from '../../../store/actions/index';
import history from '../../../utils/history';
import imageDefault from '../../../assets/images/okgo.png';

const NavigationItems = ( props ) => {
    const [logoutState, setLogout] = useState(false);
    let signoutView = null;
    const logout = () => {
        props.onRefreshCart();
        props.onRemoveAuth();
        props.clicked();
        localStorage.clear();
        if (logoutState) {}
        setLogout(true);
        history.push('/login');
    }

    if(props.isLoggedIn) {
        signoutView = (
            <ul className="NavigationItemsBottom">
                <NavigationItem clicked={props.clicked} link="/profile">My Profile</NavigationItem> 
                <div className="SidebarMenu">
                    <li className="NavigationItem">
                        <div className="div-a" tag="a" onClick={logout}>Logout</div>
                    </li>
                </div>
            </ul>
        )
    }
    
    return (
        <Aux>
            <ul className="NavigationItemsTop">
                <img src={imageDefault} alt=""/>
            </ul>
            <ul className="NavigationItems">
                <NavigationItem clicked={props.clicked} link="/" exact>Home</NavigationItem>
                <NavigationItem clicked={props.clicked} link="/my-orders">My Order</NavigationItem> 
                <NavigationItem clicked={props.clicked} link="/my-voucher">My Wallet</NavigationItem> 
                <NavigationItem clicked={props.clicked} link="/cart">My Cart</NavigationItem> 
            </ul>
            {signoutView}
        </Aux>
)};

const mapStateToProps = state => {
    return {
        isLoggedIn: state.user.isLoggedIn
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onRemoveAuth: () => dispatch(actions.authRemove()),
        onRefreshCart: () => dispatch(actions.deleteCart())
    };
  };

export default connect(mapStateToProps, mapDispatchToProps)( NavigationItems );

