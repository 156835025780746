import React, { Component } from 'react';
import { connect } from 'react-redux';
import Input from '../../../components/UI/Input/Input';
import Merchant from '../../../components/Merchant/Merchant';
import api from '../../../services/network';
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Button,
  Input as BSInput
} from "reactstrap";
import './PreorderListing.css';
import { preOrderMerchantApiPath, preOrderListingApiPath, preOrderListingLikesApiPath } from '../../../services/networkApi';
import showNotifications from '../../../components/toast';
import classnames from "classnames";
import { imageApi } from '../../../config';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import * as Hearts from '@fortawesome/free-regular-svg-icons'
import RoundedBottom from '../../../components/UI/Button/RoundedButton/RoundedButton';
import * as cartActions from '../../../store/actions/index';
import SuccessAnimation from '../../../components/UI/Animation/Success/SuccessAnimation';
import FloatingButton from '../../../components/UI/Button/FloatingButton/FloatingButton';
import Modal from '../../../components/UI/Modal/Modal';
import copy from 'copy-to-clipboard';
import NumberFormat from 'react-number-format';
import { filterList } from '../../../utils/utils';
import {daysOfNumber} from '../../../utils/utils';

class PreorderListing extends Component {
  state = {
    activeTab: "1",
    filterValue: {
      elementType: 'input-filter',
      elementConfig: {
        type: 'text',
        placeholder: 'Search Foods'
      },
      value: ''
    },
    merchantData: [],
    preorderList: [],
    loading: true,
    successAnimation: false,
    shareable: false,
    listingId: '',
  };

  async componentDidMount() {
    if (this.props.cartType !== 'PREORDER') {
      this.props.history.push('/');
    }

    const query = new URLSearchParams(this.props.location.search);
    let areaId = '';
    for (let param of query.entries()) {
      if (param[0] === 'area') {
        areaId = param[1];
      }
    } 

    if (areaId === '') {
      showNotifications('Tidak ditemukan area yang tepat', 'error', 'Checkout with linkaja'); 
    } else {
      const merchantListApi = api(preOrderMerchantApiPath)
      let payload = {
        areaId:  areaId,
        preorderTypeId: 3
      }
      const merchantList = await merchantListApi.post(payload);
      
      const preorderListApi = api(preOrderListingApiPath);
      payload = {
        token: this.props.token,
        areaId: areaId
      }
      const preorderList = await preorderListApi.post(payload);

      let results = preorderList.map( el => {
        let o = Object.assign({}, el);
        const now = moment(new Date()); 
        
        if (Object.keys(el.listing).length !== 0) {
          o.deliveryFee = Object.keys(el.listing.merchantSpecific).length !== 0 ? el.listing.merchantSpecific.deliveryFee : 0;
        } else {
          o.deliveryFee = 0;
        }

        if (el.logPreorderLikes.length === 0) {
          o.isLike = false;
        } else {
          o.isLike = el.logPreorderLikes[0].islike
        }

        let lastOrders = 'Closed';
        let lastOrderDate = '';
        let detailOrders = el.listingPreorderDetails;
        let deliveryDate = null;
        if (detailOrders !== undefined) {
          if (detailOrders.length > 0) {
            if (detailOrders[0].endPODate !== null) {
              deliveryDate = detailOrders[0].deliveryDate !== null ? moment(detailOrders[0].deliveryDate).format("DD-MM-YYYY HH:mm:ss") : null;
              let endPODate = moment(detailOrders[0].endPODate);
              let duration = moment.duration(endPODate.diff(now));
              let days = Math.floor(duration.asDays());
              let hours = Math.floor((duration.asHours() % 24));
              let minutes = Math.floor((duration.asMinutes() % 60));
            
              if (days >= 0) {
                lastOrderDate = moment(endPODate).format("DD-MM-YYYY");
                lastOrders = days + ' hari : ' + hours + ' Jam : ' + minutes + ' Menit';
              }
            } else if (detailOrders[0].days !== null) {
              let arrayDays = [];
              let daysNowofNumber = daysOfNumber(now.format('dddd'));
  
              let upperDaysList = detailOrders.filter(function(item) {
                return daysOfNumber(item.days) > daysNowofNumber;
              })
  
              let underDaysList = detailOrders.filter(function(item) {
                return daysOfNumber(item.days) <= daysNowofNumber;
              })
  
              upperDaysList = upperDaysList.sort((a, b) => {
                return daysOfNumber(a.days) - daysOfNumber(b.days)
              })
  
              underDaysList = underDaysList.sort((a, b) => {
                return daysOfNumber(a.days) - daysOfNumber(b.days)
              })
  
              arrayDays = [
                ...upperDaysList,
                ...underDaysList
              ]
  
              for (let arrData in arrayDays) {
                let daysPO = daysOfNumber(arrayDays[arrData].days);
                if (daysPO - 1 === 0) {
                  daysPO = 7;
                } else {
                  daysPO = daysPO - 1;
                }

                let selisih = 0;

                if (daysPO >= daysNowofNumber) {
                  selisih = daysPO - daysNowofNumber;
                } else {
                  selisih = (7 - daysNowofNumber) + daysPO;
                }

                let new_date = moment(now, "DD-MM-YYYY").add(selisih, 'days');
                deliveryDate = new_date.add(1, 'days').set({hour:10,minute:0,second:0,millisecond:0}).format('DD-MM-YYYY HH:mm:ss');
                new_date.set({hour:12,minute:0,second:0,millisecond:0})
                let duration = moment.duration(new_date.diff(now));
                let days = Math.floor(duration.asDays());
                let hours = Math.floor((duration.asHours() % 24));
                let minutes = Math.floor((duration.asMinutes() % 60));
                if (days >= 0) {
                  lastOrderDate = moment(new_date).format("DD-MM-YYYY");
                  lastOrders = days + ' hari : ' + hours + ' Jam : ' + minutes + ' Menit';
                  break;
                }
              }
            }
          }
        }

        o.lastOrder = lastOrders;
        o.lastOrderDate = lastOrderDate
        o.deliveryDate = deliveryDate
        return o;
      });

      this.setState({
        merchantData: merchantList,
        preorderList: results,
        loading: false,
      });
    }
  }

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      let updatedFilterValue = {
        ...this.state.filterValue
      }

      switch(tab) {
        case '1':
          updatedFilterValue.elementConfig.placeholder = 'Search Foods';
          break;
        case '2':
          updatedFilterValue.elementConfig.placeholder = 'Search Products';
          break;
        default:
          updatedFilterValue.elementConfig.placeholder = 'What would you like to filter';
          break
      }

      this.setState({
        activeTab: tab,
        filterValue: updatedFilterValue
      });
    }
  };

  filterPreorderByCategory = () => {
    const val = this.state.filterValue.value;
    const category = this.state.activeTab;

    let preorderByCategory = [
      ...this.state.preorderList
    ]

    preorderByCategory = preorderByCategory.filter(function(item) {
      return item.preorderCategoryId.toString() === category;
    })

    preorderByCategory = preorderByCategory.filter(function(item) {
      return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1 || item.description.toLowerCase().indexOf(val.toLowerCase()) > -1;
    })

    return preorderByCategory;
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      filterValue: {
        ...this.state.filterValue,
        value: value
      }
    });
  }

  likePreorder = async (item) => {
    if  (!this.state.loading && !this.state.successAnimation) {
      let updatedListing = [
        ...this.state.preorderList
      ]
  
      const itemList = updatedListing.filter(items => {
        return items.id === item.id;
      });
      
      if (itemList.length > 0) {
        if(itemList[0].isLike && itemList[0].likes > 0) {
          itemList[0].likes -= 1;
          itemList[0].isLike = false;
        } else {
          itemList[0].likes += 1;
          itemList[0].isLike = true;
        }
  
        const preorderLikesApi = api(preOrderListingLikesApiPath);
        const payload = {
          token: this.props.token,
          preorderId: item.id
        }
        const preorderLikesRes = await preorderLikesApi.post(payload);
      
        const { errors } = preorderLikesRes;
        if (errors && errors[0].message !== '') {
          showNotifications('Kesalahan pada sistem, user sementara tidak dapat melakukan like pada item', 'error', 'Checkout error');
        } else {
          this.setState({
            ...this.state.preorderList,
            ...updatedListing
          });    
        }
      }
    }
  }

  addToCart = (item) => {
    if (item.lastOrder === 'Closed') {
      showNotifications('PO telah ditutup', 'error', 'Add to Cart Preorder'); 
      return;
    }

    if (item.listing.qty === 0) {
      showNotifications('Maaf, menu telah habis', 'error', 'Listing');
      return;
    }

    if (item.listing && !this.state.loading && !this.state.successAnimation) {
      const itemCart = {
        id: item.listing.id,
        name: item.listing.name,
        price: item.listing.price,
        qty: 1,
        stock: item.listing.qty,
        subtotal: item.listing.price,
        category: item.listing.category,
        remark: '',
        merchantId: item.listing.merchantSpecificId,
        parentId: item.listing.merchantId,
        image: item.listing.image,
        merchantName: item.listing.merchantSpecific ? item.listing.merchantSpecific.name : '',
        order: 0,
        deliveryDate: item.deliveryDate,
        deliveryFee: item.deliveryFee,
        supplierId: item.listing.supplierId
      }
      
      this.props.onStoreCart(itemCart);
      this.setState({
        successAnimation: true,
      });

      setTimeout(() => {
        this.setState({
          successAnimation: false,
        });
      }, 2000);
    }
  }

  gotoCart = () => {  
    if(!this.state.loading && !this.state.successAnimation) {
      this.props.history.push('/cart');
    }
  }

  clicked = (el) => {
    // if(el.opened) {
    //   this.props.onUpdateMerchant(el.id, el.name);
    //   this.props.history.push('/preorder/listing-menu');
    // }
    this.props.onUpdateMerchant(el.id, el.name);
    this.props.history.push('/preorder/listing-menu');
    // if(el.opened) {
    //   this.props.onUpdateMerchant(el.id, el.name);
    //   this.props.history.push('/preorder/listing-menu');
    // }
  }

  sharePreorder = async(el) => {
    let productName = el.name;

    if (Object.keys(el.listing).length !== 0) {
      productName = el.listing.name;
    }
    // this.setState({
    //   shareable: true,
    //   listingId: el.listingId
    // });

    if (navigator.share) {
      // we can use web share!
      // const shareData = {
      //   title: 'Preorder',
      //   text: 'Preorder',
      //   url: 'https://okgo.co.id/preorder/listing/details?listingId=' + el.listingId,
      // }
      const shareData = {
        title: el.listing.merchantSpecific.name,
        text: productName,
        url: 'https://okgo.co.id/preorder/listing/details?listingId=' + el.listingId,
      }
  
      await navigator.share(shareData)
    } else {
      console.log('failed')
    }
  }

  shareCanceledHandler = () => {
    this.setState({
      shareable: false,
      listingId: '',
    });
  }

  copyToClip = (url) => {
    copy(url)
    alert('copied to clipboard');
    this.setState({
      shareable: false,
      listingId: '',
    });
  }

  render() {
    const list = filterList(this.state.merchantData, this.state.filterValue.value)
    let listing = null;
    let successView = null;
    let floatButton = null;
    let sharedModal = null;
    let listPreorder = [];
    let rowsId = '';
    let rowCount = 0;
    let content = [];
    let shareUrl = '';
    
    if (this.state.listingId !== '') {
      shareUrl = "https://okgo.co.id/preorder/listing/details?listingId=" + this.state.listingId;
    }

    sharedModal = (
      <div className="justify-content-center">
        <h6>Share Link</h6>
        <div className="information">
          <InputGroup>
            <BSInput className="Input-BS" placeholder="and..." disabled value={shareUrl}></BSInput>
            <InputGroupAddon addonType="append"><Button onClick={() => {this.copyToClip(shareUrl)}} className="Input-BS" color="secondary">copy</Button></InputGroupAddon>
          </InputGroup>
        </div>
      </div>
    )

    let filterBox = <Input 
                      elementType={this.state.filterValue.elementType}
                      elementConfig={this.state.filterValue.elementConfig}
                      value={this.state.filterValue.value}
                      isForms='false'
                      changed={(event) => this.inputChangedHandler(event)} />
    listing = list.map( el => (
      <Merchant key={el.id} foodcourtName={el.name} status={el.opened} images={el.logo} clicked={() => this.clicked(el)}/>
    ))

    const preorderListByCategory = this.filterPreorderByCategory();
        
    preorderListByCategory.map( (el, index) => {
      let img = el.listing.image !== undefined ? el.listing.image : el.image;      
      let oldPrice = el.listing.oldPrice !== null ? el.listing.oldPrice : 0;
      let priceDiv = null;
      let periodePo = 'Periode Order : - ';
      let countEndPo = 'Closed';
      let deliveryDate = 'Tanggal Antar : - ';
      let likeIcons = el.isLike ? <FontAwesomeIcon icon={faHeart} size="lg" color="red"/>  : <FontAwesomeIcon icon={Hearts.faHeart} size="lg"/> 
      let productName = el.name;
      let productDetail = el.description;

      if (Object.keys(el.listing).length !== 0) {
        productName = el.listing.name;
        productDetail = el.listing.detail;
      }

      if (el.lastOrder !== 'Closed') {
        periodePo = 'Periode Order : ' + el.lastOrderDate
        countEndPo = 'PO akah berakhir dalam (' + el.lastOrder + ')'
        deliveryDate = 'Tanggal Antar : ' + el.deliveryDate;
      }

      if (oldPrice === 0) {
        priceDiv = (
          <div className="float-right">
            <div><NumberFormat value={el.listing.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /></div>
          </div>
        )
      } else {
        priceDiv = (<div className="float-right">
                    <div>
                      <span className="outer">
                        <span className="inner">
                          <NumberFormat value={oldPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                        </span>
                      </span>
                    </div>
                    <div><NumberFormat value={el.listing.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /></div>
                  </div>)
      }
      
      content.push(
        <Col sm="6" key={el.id}>
          <Card className="text-left margin-upper">
            <CardBody>
              <div className="information-header"><CardTitle>{productName}</CardTitle></div>
            </CardBody>
            <img className="img-preorder" width="100%" src={imageApi + img} alt=""/>
            <CardBody className="card-listing-detail-h">
                <div className="information push-bottom-padd">
                  <div className="float-left preorder-description">
                  <span className="truncate preorder-description">
                    {productDetail} </span>
                    {/* <a href="#">Read more</a> */}
                  </div>
                  {priceDiv}
                </div>
            </CardBody>
              <CardBody>
                <div className="justify-content-center">
                  <RoundedBottom clicked={() => {this.addToCart(el)}} width="100%" bgcolor="black" color="white">ORDER</RoundedBottom>
                </div>
              </CardBody>
              
            <CardFooter>
                <div className="information-small">
                  <div> {periodePo} </div>
                  <div> {deliveryDate} </div>
                  <div> {countEndPo}</div>
                  <div className="push-left float-right" tag="a" onClick={() => {this.sharePreorder(el)}}>
                    <FontAwesomeIcon icon={faShareAlt} size="lg" /> 
                  </div>
                  <div className="push-right float-right" tag="a" onClick={() => {this.likePreorder(el)}}>
                    {el.likes} {likeIcons}
                  </div>
                </div>
            </CardFooter>
          </Card>
        </Col>
      );
      
      if (index % 2 !== 0) {
        rowCount += 1;
        rowsId = 'row' + (rowCount);
        listPreorder.push(
          <Row key={rowsId}>
            {content}
          </Row>
        )
        content = [];
      }

      return true;
    });

    if (content.length > 0) {
      rowCount += 1;
      rowsId = 'row' + (rowCount);
      listPreorder.push(
        <Row key={rowsId}>
          {content}
        </Row>
      )
    }

    successView = this.state.successAnimation ? <SuccessAnimation></SuccessAnimation> : null;

    floatButton = this.props.cart.length > 0 ? <FloatingButton clicked={this.gotoCart} cartCount={this.props.cart.length}></FloatingButton> : null;

    return (
      <React.Fragment>

        <Modal show={this.state.shareable} modalClosed={this.shareCanceledHandler}>
          {sharedModal}
        </Modal>

        {floatButton}
        {successView}
        <div>
          <Nav tabs className="nav-justified tab-head">
              <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === "1" })}
                    onClick={() => {
                      this.toggle("1");
                    }}>
                    <div className="information">FOODS</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === "2" })}
                    onClick={() => {
                      this.toggle("2");
                    }}>
                    <div className="information">OTHERS</div>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                    className={classnames({ active: this.state.activeTab === "3" })}
                    onClick={() => {
                      this.toggle("3");
                    }}>
                    <div className="information">MERCHANTS</div>
                </NavLink>
              </NavItem>
          </Nav>
              
            <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <div className="preorder-merchant-header">
                    {filterBox}
                  </div>
                  <div className="margin-preorderlist">
                    {listPreorder}
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="preorder-merchant-header">
                    {filterBox}
                  </div>
                  <div className="margin-preorderlist">
                    {listPreorder}
                  </div>
                </TabPane>
                <TabPane tabId="3">
                <div className="preorder-merchant-header">
                  {filterBox}
                </div>
                <div className="preorder-merchant-content">
                  {listing}
                </div>
                </TabPane>
            </TabContent>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      isLoggedIn: state.user.isLoggedIn,
      token: state.user.token,
      cartType: state.cart.cartType,  
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onStoreCart: (item) => dispatch(cartActions.addCart(item)),
      onUpdateMerchant: (merchant, name) => dispatch(cartActions.updateMerchant(merchant, name))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)( PreorderListing );