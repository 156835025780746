import { isArray } from 'util';
import { reduce } from 'underscore';
import React from 'react';
import CryptoJS from 'crypto-js';
import { iv, secretKey } from '../config';

export function prettifyErrorMessage(message) {
    if(isArray(message)) {
        let errorTemplate = []
        let no
        message.forEach((msg, index) => {
            no = index + 1
            errorTemplate.push(<div key={index}>{no}. {msg}</div>)
        })
        return errorTemplate
    } else {
        return message
    }
}

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};

export const checkValidity = ( value, rules ) => {
    let isValid = true;
    if ( !rules ) {
        return true;
    }

    if ( rules.required ) {
        isValid = value.trim() !== '' && isValid;
    }

    if ( rules.minLength ) {
        isValid = value.length >= rules.minLength && isValid
    }

    if ( rules.maxLength ) {
        isValid = value.length <= rules.maxLength && isValid
    }

    if ( rules.isEmail ) {
        const pattern = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        isValid = pattern.test( value ) && isValid
    }

    if ( rules.isNumeric ) {
        const pattern = /^\d+$/;
        isValid = pattern.test( value ) && isValid
    }

    return isValid;
  }

  export const encrypt = (msg) => {
   let cipher = CryptoJS.AES.encrypt(msg, CryptoJS.enc.Utf8.parse(secretKey), {
       iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV 
       padding: CryptoJS.pad.Pkcs7,
       mode: CryptoJS.mode.CBC
   });

   return cipher.toString(); // returns B6AeMHPHkEe7/KHsZ6TW/Q==
  }

  export const decrypt = (msg) => {
    let cipher = CryptoJS.AES.decrypt(msg, CryptoJS.enc.Utf8.parse(secretKey), {
        iv: CryptoJS.enc.Utf8.parse(iv), // parse the IV 
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });
    return JSON.parse(cipher.toString(CryptoJS.enc.Utf8));
  }

//   function string2Bin(str) {
//     var result = [];
//     for (var i = 0; i < str.length; i++) {
//       result.push(str.charCodeAt(i));
//     }
//     return result;
//   }

//   function bin2String(array) {
//     return String.fromCharCode.apply(String, array);
//   }


export const toUTF8Array = (str) => {
    var utf8 = [];
    for (var i=0; i < str.length; i++) {
        var charcode = str.charCodeAt(i);
        if (charcode < 0x80) utf8.push(charcode);
        else if (charcode < 0x800) {
            utf8.push(0xc0 | (charcode >> 6), 
                      0x80 | (charcode & 0x3f));
        }
        else if (charcode < 0xd800 || charcode >= 0xe000) {
            utf8.push(0xe0 | (charcode >> 12), 
                      0x80 | ((charcode>>6) & 0x3f), 
                      0x80 | (charcode & 0x3f));
        }
        // surrogate pair
        else {
            i++;
            // UTF-16 encodes 0x10000-0x10FFFF by
            // subtracting 0x10000 and splitting the
            // 20 bits of 0x0-0xFFFFF into two halves
            charcode = 0x10000 + (((charcode & 0x3ff)<<10)
                      | (str.charCodeAt(i) & 0x3ff));
            utf8.push(0xf0 | (charcode >>18), 
                      0x80 | ((charcode>>12) & 0x3f), 
                      0x80 | ((charcode>>6) & 0x3f), 
                      0x80 | (charcode & 0x3f));
        }
    }
    return utf8;
}

export const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

export const daysOfNumber = (str) => {
    switch(str.toUpperCase()) {
        case 'SUNDAY': 
            return 7;
        case 'MONDAY':
            return 1;
        case 'TUESDAY':
            return 2;
        case 'WEDNESDAY':
            return 3;
        case 'THURSDAY':
            return 4;
        case 'FRIDAY':
            return 5;
        case 'SATURDAY':
            return 6;
        default:
            return -1;
    }
}

export const getTotalDeliveryFee = (carts) => {
    let arrDeliveryFee = [];
    carts.reduce(function(res, value) {
      if (!res[value.merchantId]) {
        
        res[value.merchantId] = { Id: value.merchantId, fee: value.deliveryFee === undefined ? 0 : value.deliveryFee};
        arrDeliveryFee.push(res[value.merchantId])
      }
      
      return res;
    }, {});

    return reduce(arrDeliveryFee, function(value, obj) { return value + obj.fee }, 0);
}

export const isBlockedPaymentCashless = (carts) => {
    let merchantList = [138, 150, 151, 152, 168, 295, 296, 297, 298, 299, 300, 301, 302, 303, 304, 305, 311, 312, 313, 316, 317, 320, 321, 323, 324, 325, 326, 327, 328, 329, 330, 332, 333, 338, 339, 340, 351, 352, 353, 354, 355, 356, 357];
    let found = false;
    let i = 0;
    const result = {
        found: false
    };

    while (i < merchantList.length && found === false) {
        let merchantId = merchantList[i];
        let index = carts.findIndex(x => x.merchantId === merchantId);
        
        if (index >= 0) { 
            result.merchant = carts[index].merchantName
            result.found = true
            found = true
        }
        i++;
    }

    return result;
}

export const filterListByCategory = (listData, filterValue, activeFilter) => {
    let filteredList = [
      ...listData
    ]
    
    if (activeFilter === 0) {
      filteredList = filteredList.filter(function(item) {
        return item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
      })
    } else {
      filteredList = filteredList.filter(function(item) {
        let categoryItem = item.merchantSubcategoryId == null ? '' : item.merchantSubcategoryId.toString().toLowerCase();

        return item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1 && categoryItem === activeFilter.toString().toLowerCase();
      })
    }

    return filteredList;
}

export const filterList = (listData, filterValue) => {
    let filteredList = [
      ...listData
    ]

    filteredList = filteredList.filter(function(item) {
      return item.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
    })

    return filteredList
}