import React from 'react';
import './SuccessAnimation.css';

const SuccessAnimation = (props) => {
  const colors = {
    // backgroundColor: 'rgb(255, 255, 255)'
  }
  
  return (
    <div className="app-fixed-center front">
      <div className="swal2-icon swal2-success swal2-animate-success-icon">
        <div className="swal2-success-circular-line-left" style={colors}></div>
        <span className="swal2-success-line-tip"></span>
        <span className="swal2-success-line-long"></span>
        <div className="swal2-success-ring"></div> 
        <div className="swal2-success-fix" style={colors}></div>
        <div className="swal2-success-circular-line-right" style={colors}></div>
      </div>
    </div>
  )
  
};

export default SuccessAnimation;