import React, { Component } from 'react';
import imgComingSoon from '../../assets/images/comingsoon.png';

class NewFeatures extends Component {
  alerts = () => {
    console.log('')
  }

  render() {
    const myStyle = {
      width: 'inherit',
      height: 'inherit',
    }
    return (
      <div className="container app-center">  
        <img src={imgComingSoon} alt="" style={myStyle}></img>
      </div>
    )
  }
}

export default NewFeatures;


