import React from 'react';
import './OrderDetail.css';
import { Card, Row, Col } from 'react-bootstrap';
import imageDefault from '../../assets/images/beverage.png';
import RoundedBottom from '../../components/UI/Button/RoundedButton/RoundedButton';

const OrderDetail = (props) => {

  return (
    <Card className="card-no-border" tag='a' onClick={props.clicked}>
        <Row noGutters={true}>
          <Col xs={3} md={3}>
            <div className="list">
              <img src={imageDefault} alt=""/>
            </div>
          </Col>
          <Col xs={7} md={7}>
            <div className="listbody">
              <div className="information">{props.name}</div>
            </div>
          </Col>
          <Col xs={2} md={2} className="my-auto">
            <div className="listbody">
              <RoundedBottom width="8vw" className="no-color" border="1px solid">{props.qty}</RoundedBottom>
            </div>
          </Col>
        </Row>            
    </Card>
  )
}

export default OrderDetail;