import  React, { Component } from 'react';
import Aux from '../../../hoc/Aux/Aux';
import {Row, Col} from 'react-bootstrap';
import imageScan from '../../../assets/images/okgo-scan.png';
import imagePreorder from '../../../assets/images/okgo-preorder.png';
import imageReservation from '../../../assets/images/okgo-reservation.png';
import imageDriveThru from '../../../assets/images/okgo-drive.png';
import imagePos from '../../../assets/images/okgo-pos2.png';
import imageGoogle from '../../../assets/images/google.png';
import './AboutUs.css';

class AboutUs extends Component {
  render() {
    return (
      <Aux>
        <div className="section-about">
          <div className="section-about-text">
            <h2 className="about-header">Your Daily Application</h2> <br></br>
            <p> OKGO developed in 2018, base on a personal experience We found it hard to place order in cafe in peak hour and waste a lot of time in a long quee and also waiting too long for the meal to be prepare, we started by selling coffee in a container using OKGO drive thru application to make a fast drive thru solution, now OKGO can be use for many thing in your daily activities. OKGO apps can be used to make an order by using qr code technology OR to make online Reservation, all the order will be integrated to our OKPOS Point Of Sales system, Save time, no more waiting in a long queue line, Download and install OKGO to make your life more easy and convenient</p>
          </div>	
        </div>
        
        <div className="section-about">
          <h2 className="about-header">Our Services</h2> <br></br>
          <Row>
            <Col lg={4}>  
              <div className="gallery-about col-centered">                  
                <img src={imageScan} alt="" />
              </div>
            </Col>
            <Col lg={4}>
              <div className="gallery-about col-centered">                  
                <img src={imageReservation} alt=""/>
              </div>
            </Col>
            <Col lg={4}>
              <div className="gallery-about col-centered">
                <img src={imagePreorder} alt=""/>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <div className="gallery-about col-centered">
                <img src={imageDriveThru} alt=""/>
              </div>
            </Col>
            <Col lg={4}>
              <div className="gallery-about col-centered">
                <img src={imagePos} alt=""/>
              </div>
            </Col>
            <Col lg={4}>
            </Col>
          </Row>
          <div className="d-flex justify-content-center">
            <a href="https://play.google.com/store/apps/details?id=com.app.okgo"><img src={imageGoogle} alt="Google" />  </a>
          </div>	
        </div>
      </Aux>
    )
  }
}

export default AboutUs;