import React from 'react';
import './Footer.css';
import FooterItems from '../FooterItems/FooterItems';

const footer = (props) => (
  <footer className="Footer">
    <nav>
      <FooterItems></FooterItems>
    </nav>
  </footer>
)

export default footer;