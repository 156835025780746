// import React from 'react';
// import { Row, Col } from 'react-bootstrap';
// import RoundedBottom from '../../UI/Button/RoundedButton/RoundedButton';
// import Aux from '../../../hoc/Aux/Aux';
// import './CheckoutBar.css';
// import NumberFormat from 'react-number-format';

// const CheckoutBar = (props) => {
//   let disabled = props.price <= 0 ? true : false;
//   // let disabled = false;
//   let buttons = <RoundedBottom width="100%" disabled={disabled} clicked={props.payContinued}>CHECKOUT</RoundedBottom>;
//   if (props.creator === 'FOODCOURT') {
//     buttons = (
//       <Aux>
//         <RoundedBottom width="50%" disabled={disabled} clicked={props.payContinued}>CHECKOUT</RoundedBottom>
//         <RoundedBottom width="50%" bgcolor="green" disabled={disabled} clicked={props.backToList}>Keranjang +</RoundedBottom>
//       </Aux>
//     )
//   }

//   return(
//     <div className="app-bottom cart-box information d-flex justify-content-center">
//       <div className="cart-box-info">
//         <Row>
//           <Col>
//             <div className="float-left">Sub Total</div>
//           </Col>
//           <Col>
//             <div className="float-right">
//               {/* IDR {props.price} */}
//               <NumberFormat value={props.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
//             </div>
//           </Col>
//         </Row>
//         <Row>
//           <Col>
//             <div className="float-left">Tax</div>
//           </Col>
//           <Col>
//           <div className="float-right">-</div>
//           </Col>
//         </Row>  
//         <Row>
//           <Col>
//             <div className="float-left">Ongkir</div>
//           </Col>
//           <Col>
//           <div className="float-right">-</div>
//           </Col>
//         </Row>
//         <Row className="d-flex justify-content-center">
//           <RoundedBottom width="70%" className="no-color" disabled={disabled}>ADD PROMO COUPON</RoundedBottom>
//         </Row>
//         <Row>
//           <Col>
//             <div className="float-left">Total</div>
//           </Col>
//           <Col>
//             <div className="float-right">
//               <NumberFormat value={props.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
//             </div>
//           </Col>
//         </Row>
//         <Row className="d-flex justify-content-center">
//           {buttons}
//         </Row>
//       </div>
//     </div>
//   )
// }

// export default CheckoutBar;

import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RoundedBottom from '../../UI/Button/RoundedButton/RoundedButton';
import Aux from '../../../hoc/Aux/Aux';
import './CheckoutBar.css';
import NumberFormat from 'react-number-format';

const CheckoutBar = (props) => {
  let ongkirFee = props.deliveryFee === 0 ? '-' : props.deliveryFee;
  let taxFee = props.tax === 0 ? '-' : <NumberFormat value={props.tax} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />;
  let serviceFee = props.serviceTax === 0 ? '-' : props.serviceTax;
  let disabled = props.price <= 0 ? true : false;
  let totalPrice = props.orderType === 'PREORDER' ? props.price + props.deliveryFee + props.tax : props.price + props.tax + props.serviceTax;
  let buttons = <RoundedBottom width="100%" disabled={disabled} clicked={props.payContinued}>CHECKOUT</RoundedBottom>;
  let remarkCharge = props.orderType === 'PREORDER' ? 'Ongkir' : 'Service Charge';
  let remarkValue = props.orderType === 'PREORDER' ? (
    <NumberFormat value={ongkirFee} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
  ) : <NumberFormat value={serviceFee} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />


  if (props.creator === 'FOODCOURT') {
    buttons = (
      <Aux>
        <RoundedBottom width="50%" disabled={disabled} clicked={props.payContinued}>CHECKOUT</RoundedBottom>
        <RoundedBottom width="50%" bgcolor="green" disabled={disabled} clicked={props.backToList}>Keranjang +</RoundedBottom>
      </Aux>
    )
  }

  return(
    <div className="app-bottom cart-box information d-flex justify-content-center">
      <div className="cart-box-info">
        <Row>
          <Col>
            <div className="float-left">Sub Total</div>
          </Col>
          <Col>
            <div className="float-right">
              {/* IDR {props.price} */}
              <NumberFormat value={props.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div className="float-left">Tax</div>
          </Col>
          <Col>
            <div className="float-right">{taxFee}</div>
          </Col>
        </Row>  
        <Row>
          <Col>
            <div className="float-left">{remarkCharge}</div>
          </Col>
          <Col>
            <div className="float-right">{remarkValue}</div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <RoundedBottom width="70%" className="no-color" disabled={disabled}>ADD PROMO COUPON</RoundedBottom>
        </Row>
        <Row>
          <Col>
            <div className="float-left">Total</div>
          </Col>
          <Col>
            <div className="float-right">
              <NumberFormat value={totalPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {buttons}
        </Row>
      </div>
    </div>
  )
}

export default CheckoutBar;