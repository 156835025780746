import React, { Component } from 'react';
import './OrderListDetail.css';
import {Tabs, Tab} from 'react-bootstrap';
import QRCode from 'qrcode.react';
import { encrypt } from '../../../../utils/utils';
import { myOrderDetailApiPath } from '../../../../services/networkApi';
import api from '../../../../services/network';
import OrderDetail from '../../../../components/OrderDetail/OrderDetail';
import Spinner from '../../../../components/UI/Spinner/Spinner';
// import RoundedBottom from '../../../../components/UI/Button/RoundedButton/RoundedButton';
import { connect } from 'react-redux';

class OrderListDetail extends Component {
  state = {
    tid: '',
    inv: '',
    phone: '',
    tidEncrypted: '',
    listingData: [],
    loading: true
  }

  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let tid = '';
    let inv = '';
    let phone = '';

    for (let param of query.entries()) {
        if (param[0] === 'tid') {
            tid = param[1];
        } else if (param[0] === 'invoice')  {
            inv = param[1];
        } else {
          phone = param[1];
        }
    } 

    const myOrderApi = api(myOrderDetailApiPath);
    let payload = {
      token: this.props.token,
      transactionId: tid
    }
    
    const myOrderRes = await myOrderApi.post(payload); 

    this.setState({
      tid: tid,
      inv: inv,
      phone: phone,
      tidEncrypted: encrypt(tid),
      listingData: myOrderRes,
      loading:false
    })
  }

  render() {
    let listing = null;

    listing = this.state.listingData
    .map( el => (
      <OrderDetail key={el.id} 
        name={el.listName}
        qty={el.qty}
        ></OrderDetail>
    ))

    if ( this.state.loading ) {
      listing = <Spinner />;
    }

    return (
      <Tabs defaultActiveKey="barcode" id="Tab" className="tab-center">
        <Tab eventKey="barcode" title="Barcode">
          <div className="app-center-barcode">
            <QRCode size={200} value={this.state.tidEncrypted} />
          </div>
        </Tab>
        <Tab eventKey="detail" title="Detail">
          {listing}
          {/* <div className="information tab-cost"> 
            This cost IDR 
            <RoundedBottom bgcolor="white" border='1px solid' width="auto" height="auto" color="#8A4537">Cashier</RoundedBottom>
          </div> */}
        </Tab>
      </Tabs>
    )
  }
}

const mapStateToProps = state => {
  return {
    token: state.user.token,
    email: state.user.email,
    isLogin: state.user.isLoggedIn
  };
};

export default connect(mapStateToProps, null)(OrderListDetail);