export const loginApiPath = 'mobile/login';
export const loginSosmedApiPath = 'mobile/login/sosmed';
export const forgotApiPath = 'mobile/user/password/reset';
export const registerApiPath = 'mobile/register';
export const profileApiPath = 'mobile/user/profile';
export const sosmedApiPath = 'mobile/user/profile/connect/sosmed';
export const updateProfileApiPath = 'mobile/user/profile/update';
export const updatePasswordApiPath = 'mobile/user/password/update';

export const orderApiPath = 'mobile/check/dinein';
export const foodcourtApiPath = 'mobile/foodbeverage/merchant';
export const listmenuApiPath = 'mobile/v4/foodbeverage/listing';
export const listSpecificMenuApiPath = 'mobile/v4/foodbeverage/listing';
// export const orderCheckoutPath = 'mobile/v7/order/create';
export const orderCheckoutPath = 'mobile/b2b/order/create';
// export const orderCheckoutPath = 'mobile/temp/order/create';

export const myOrderApiPath = 'mobile/user/v3/history/all';
export const myOrderDetailApiPath = 'mobile/user/v2/history/detail';
export const balanceApiPath = 'mobile/user/balance';

export const areaApiPath = 'mobile/area';
export const preOrderMerchantApiPath = 'mobile/preorder/merchant';
export const preOrderListingApiPath = 'mobile/preorder/listing';
export const preOrderListingLikesApiPath =  'mobile/preorder/listing/like';
export const preOrderListingMerchantApiPath = 'mobile/foodbeverage/preorderlisting';
export const preOrderListingDetailApiPath = 'mobile/preorder/listing';
export const preOrderCheckoutPath = 'mobile/preorder/order/create';
// export const preOrderCheckoutPath = 'mobile/temp/preorder/order/create';

export const linkAjaTokenApi = 'mobile/linkaja/payment/token'
export const linkAjaCheckoutApi = 'https://payment.linkaja.id/checkout/payment';
export const linkAjaUpdateStatusApi = 'mobile/order/v2/linkaja/update/status';

export const xenditGetBalanceApi = 'https://api.xendit.co/balance?account_type=CASH';
export const xenditBalanceApi = 'mobile/xendit/payment';

export const userLocationApiPath = 'mobile/sales/location';

export const merchantListFeeInfoPath = 'mobile/listing/addcharge';
export const merchantSubCategoryInfoApiPath = 'mobile/merchant/subcategory';
export const merchantFeeInfoApiPath = 'mobile/tax';


