import React, { Component } from 'react';
import './FoodcourtList.css';
import Input from '../../../components/UI/Input/Input';
import api from '../../../services/network';
import Foodcourt from '../../../components/Foodcourt/Foodcourt';
import Spinner from '../../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux';
import { foodcourtApiPath } from '../../../services/networkApi';
import * as actions from '../../../store/actions/index';

class FoodcourtList extends Component {
  state = {
    filterValue: {
      elementType: 'input-filter',
      elementConfig: {
          type: 'text',
          placeholder: 'What would you like to eat?'
      },
      value: ''
    },
    foodcourtData: [],
    loading: true,
  }

  async componentDidMount() {
    if (this.props.parentId === 'undefined' || this.props.parentId === '' || this.props.parentId === null) {
      this.props.history.push('/');
    } else {
      const foodcourtListApi = api(foodcourtApiPath)
      const payload = {parentId:  this.props.parentId}
      const foodcourtList = await foodcourtListApi.post(payload);

      this.setState({
        foodcourtData: foodcourtList,
        loading: false,
      });
    }
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      filterValue: {
        ...this.state.filterValue,
        value: value
      }
    });
  }

  filterList = () => {
    const val = this.state.filterValue.value;
    let filteredList = [
      ...this.state.foodcourtData
    ]

    filteredList = filteredList.filter(function(item) {
      return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
    })

    return filteredList
  }

  clicked = (el) => {
    if(el.opened) {
      this.props.onUpdateMerchant(el.id, el.name);
      this.props.history.push('/orders/listing-menu');
    }
  }

  render() {
    const list = this.filterList();
    let listing = null;

    let filterBox = <Input 
                      elementType={this.state.filterValue.elementType}
                      elementConfig={this.state.filterValue.elementConfig}
                      value={this.state.filterValue.value}
                      isForms='false'
                      changed={(event) => this.inputChangedHandler(event)} />


    listing = list.map( el => (
      <Foodcourt key={el.id} foodcourtName={el.name} status={el.opened} images={el.logo} clicked={() => this.clicked(el)}/>
    ))

    if ( this.state.loading ) {
      listing = <Spinner />;
    }

    return (
      // <div className="container">
      //   <h6>{this.props.parentName}</h6>
      //   {filterBox}
      //   {listing}
      // </div>
      <div>
        <div className="foodcourt-list-header">
          <h6 className="push-bottom-md">{this.props.parentName}</h6>
          {filterBox}
        </div>
        <div className="foodcourt-list-content">
          {listing}
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      table: state.cart.table,
      parentId: state.cart.parentId,
      parentName: state.cart.parentName,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onUpdateMerchant: (merchant, name) => dispatch(actions.updateMerchant(merchant, name))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FoodcourtList);