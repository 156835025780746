import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initState = {
    isLoggedIn: false,
    userId: '',
    fullName: null,
    email: null,
    phone: null,
    sosmed: null,
    token: null,
    error: null,
    loading: false,
}

const authStart = (state, action) => {
    return updateObject( state, { error: null, loading: false } );
}

const authFail = (state, action) => {
    return updateObject( state, { error: action.error, loading: false } );
}

const authSuccess = (state, action) => {
    const data = {
        isLoggedIn:true,
        userId: action.userInfo.userId,
        fullName: action.userInfo.fullName,
        email: action.userInfo.email,
        phone: action.userInfo.phone,
        sosmed: action.userInfo.sosmed,
        token: action.userInfo.token,
        error: null,
        loading: false,
    };
    return updateObject(state, data);
}

const removeAuth = (state, action) => {
    const data = {
        isLoggedIn:false,
        userId: '',
        fullName: null,
        email: null,
        phone: null,
        sosmed: null,
        token: null,
        error: null,
        loading: false,
    }
    return updateObject(state, data);
}

const switchSosmed = (state, action) => {
    return updateObject( state, { sosmed: action.status } );
}

const reducer = ( state = initState, action ) => {
    switch ( action.type ) {
        case actionTypes.AUTHENTICATION_START: return authStart(state, action);
        case actionTypes.AUTHENTICATION_FAIL: return authFail(state, action);
        case actionTypes.SET_AUTHENTICATION: return authSuccess(state, action);
        case actionTypes.REMOVE_AUTHENTICATION: return removeAuth(state, action);
        case actionTypes.SWITCH_SOSMED: return switchSosmed(state, action);
        default:
    }
    return state;
};

export default reducer;