import React, { Component } from 'react';
import { listmenuApiPath } from '../../../services/networkApi';
import api from '../../../services/network';
import Input from '../../../components/UI/Input/Input';
import Menu from '../../../components/Menu/Menu';
import PaginatedMenus from '../../../components/PaginatedMenus/PaginatedMenus';
import RoundedBottom from '../../../components/UI/Button/RoundedButton/RoundedButton';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Modal from '../../../components/UI/Modal/Modal';
import showNotifications from '../../../components/toast';
import * as cartActions from '../../../store/actions/index';

class ListingPaginatedMenu extends Component {
  state = {
    filterValue: {
      elementType: 'input-filter',
      elementConfig: {
          type: 'text',
          placeholder: 'What would you like to eat?'
      },
      value: ''
    },
    qty: {
      elementType: 'input',
      elementConfig: {
          type: 'number',
          placeholder: 'Quantity'
      },
      value: '0',
    },
    listingData: [],
    loading: true,
    finish: true,
    updatable: false,
    itemUpdated: {},
  }

  async componentDidMount() {
    if (this.props.merchantId === 'undefined' || this.props.merchantId === '' || this.props.merchantId === null) {
      this.props.history.push('/');
    } else {
      const listingApi = api(listmenuApiPath);
      const payload = {
        merchantId: this.props.merchantId, 
        category:'ALL', 
        preorder: this.props.cartType === 'PREORDER' ? 'true' : 'false', 
        specific: 'true'
      };
      const listingMenu = await listingApi.post(payload);

      const results = listingMenu.map( el => {
        let o = Object.assign({}, el);
        o.qtyOrders = 0;
        return o;
      })

      this.setState({
        listingData: results,
        loading: false,
      });
    }
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      filterValue: {
        ...this.state.filterValue,
        value: value
      }
    });
  }

  filterList = () => {
    const val = this.state.filterValue.value;
    let filteredList = [
      ...this.state.listingData
    ]

    filteredList = filteredList.filter(function(item) {
      return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
    })

    return filteredList
  }

  incrementQty = (item) => {
    let carts = [ ...this.props.cart ];
    const cart = carts.filter(items => {
      return items.id === item.id;
    });

    if (cart.length > 0) {
      if (cart[0].stock < cart[0].qty + 1) {
        showNotifications('Maaf, menu ' + cart[0].name + ' telah habis', 'error', 'Listing');
        return;
      }
    }

    if (item.qty === 0) {
      showNotifications('Maaf, stok telah habis', 'error', 'Listing');
      return;
    }

    this.setState({ finish: false });

    if (!this.state.finish) {
      return;
    } else {
      let updatedListing = [
        ...this.state.listingData
      ]

      const index = updatedListing.findIndex(items => {
        return items.id === item.id && items.category === item.category;
      });
      
      if (index >= 0) {
        const itemCart = {
          id: updatedListing[index].id,
          name: updatedListing[index].name,
          price: updatedListing[index].price,
          oldPrice: updatedListing[index].oldPrice,
          qty: 1,
          stock: updatedListing[index].qty,
          subtotal: updatedListing[index].price,
          category: updatedListing[index].category,
          remark: '',
          merchantId: updatedListing[index].merchantId,
          parentId: updatedListing[index].parentId,
          image: updatedListing[index].image,
          merchantName: updatedListing[index].merchantName,
          order: updatedListing[index].order,
          supplierId: updatedListing[index].supplierId,
        }
        
        updatedListing[index].qtyOrders += 1;
        this.setState({
          finish: true,
          ...this.state.listingData,
          ...updatedListing
        }, () => this.props.onStoreCart(itemCart)); 
      }   
    }
  }

  decrementQty = (item) => {
    this.setState({ finish: false });

    if (!this.state.finish) {
      return;
    } else {
      let updatedListing = [
        ...this.state.listingData
      ]
  
      const index = updatedListing.findIndex(items => {
        return items.id === item.id && items.category === item.category;
      });
      
  
      if (index >= 0) {
        if(updatedListing[index].qtyOrders > 0) {
          updatedListing[index].qtyOrders -= 1;
          const itemCart = {
            id: updatedListing[index].id,
            name: updatedListing[index].name,
            price: updatedListing[index].price,
            oldPrice: updatedListing[index].oldPrice,
            qty: 1,
            stock: updatedListing[index].qty,
            subtotal: updatedListing[index].price,
            category: updatedListing[index].category,
            remark: '',
            merchantId: updatedListing[index].merchantId,
            parentId: updatedListing[index].parentId,
            image: updatedListing[index].image,
            merchantName: updatedListing[index].merchantName,
            order: updatedListing[index].order,
            supplierId: updatedListing[index].supplierId,
          }
          this.setState({
            finish: true,
            ...this.state.listingData,
            ...updatedListing
          }, () => this.props.onRemoveCart(itemCart));
        } else {
          this.setState({ finish: true });
        }
      }
    }    
  }

  qtyChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      qty: {
        ...this.state.qty,
        value: value
      }
    });
  }

  updateCanceledHandler = () => {
    this.setState({
      updatable: false,
      itemUpdated: {},
      qty: {
        ...this.state.qty,
        value: '0'
      },
    });
  }

  updateContinuedHandler = (item) => {
    this.setState({
      updatable: true,
      itemUpdated: item,
    });
  }

  updateQtyHandler = () => {
    if (this.state.qty.value < 0) { return; }

    this.setState({ finish: false });

    if (!this.state.finish) {
      return;
    } else {
      let updatedListing = [
        ...this.state.listingData
      ]

      const item = this.state.itemUpdated;
  
      const index = updatedListing.findIndex(items => {
        return items.id === item.id;
      })
  
      if (index >= 0) {
        updatedListing[index].qtyOrders = this.state.qty.value * 1;
        const itemCart = {
          id: updatedListing[index].id,
          name: updatedListing[index].name,
          price: updatedListing[index].price,
          oldPrice: updatedListing[index].oldPrice,
          qty: this.state.qty.value * 1,
          stock: updatedListing[index].qty,
          subtotal: updatedListing[index].price * this.state.qty.value,
          category: updatedListing[index].category,
          remark: '',
          merchantId: updatedListing[index].merchantId,
          image: updatedListing[index].image,
          parentId: updatedListing[index].parentId,
          merchantName: updatedListing[index].merchantName,
          order: updatedListing[index].order,
          supplierId: updatedListing[index].supplierId,
        }

        this.setState({
          finish: true,
          updatable: false,
          itemUpdated: {},
          qty: {
            ...this.state.qty,
            value: '0'
          },
          ...this.state.listingData,
          ...updatedListing
        }, () => this.props.onUpdateCart(itemCart));
      }
    }    
  }  

  render() {
    const list = this.filterList();
    let spin = null;
    let listing = null;
    let buttonCart = null;
    const totalCart = this.props.cart.reduce( (sum, el) => {
      return sum + el.qty;
    }, 0);

    if(totalCart > 0) {
      buttonCart = <div className="app-bottom button-cart-foodcourt button-cart-list">
                    <Link to="/cart">
                      <RoundedBottom width="80%" className="button-cart-list" positions="Bottom">Continue to Cart ( {totalCart} )</RoundedBottom>
                    </Link>
                  </div>
    }

    let filterBox = <Input 
                      elementType={this.state.filterValue.elementType}
                      elementConfig={this.state.filterValue.elementConfig}
                      value={this.state.filterValue.value}
                      isForms='false'
                      changed={(event) => this.inputChangedHandler(event, 'filterValue')} />

    if ( this.state.loading ) { spin = <Spinner />; }
    if ( !this.state.finish ) { spin = <Spinner isOpacity="true" /> }

    let qtyBox = ( 
      <div className="justify-content-center">
        <h6>Total Quantity</h6>
        <div className="information justify-content-center text-middle">
          Insert total quantity
        </div>
        <Input 
            elementType={this.state.qty.elementType}
            elementConfig={this.state.qty.elementConfig}
            value={this.state.qty.value}
            isForms='false'
            changed={(event) => this.qtyChangedHandler(event)} />
          <div className = "d-flex justify-content-center push-up">
            <RoundedBottom color="black" width="30%" height="auto" className="no-color" clicked={this.updateCanceledHandler}>Cancel</RoundedBottom>
            <RoundedBottom color="white" width="30%" height="auto" clicked={this.updateQtyHandler}>Ok</RoundedBottom>
          </div>
      </div>
    )
    
    return (
      <div>
        <Modal show={this.state.updatable} modalClosed={this.updateCanceledHandler}>
          {qtyBox}
        </Modal>
        <div className="app-fixed-center front">
          {spin}
        </div>
        <div className="listing-list-header">
          <h6 className="push-bottom-md">{this.props.merchantName}</h6>
          {filterBox}
        </div>

        <div className="listing-list-content">
          <PaginatedMenus items={list} 
            itemsPerPage={15}
            triggerInc={(el) => {this.incrementQty(el)}}
            triggerDec={(el) => {this.decrementQty(el)}}
            triggerUpdateQty= {(el) => {this.updateContinuedHandler(el)}}
          />
        </div>

        {buttonCart}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      merchantId: state.cart.merchantId,
      merchantName: state.cart.merchantName,
      creator: state.cart.creator,
      cartType: state.cart.cartType
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onUpdateCart: (item) => dispatch(cartActions.updateQtyCart(item)),
      onStoreCart: (item) => dispatch(cartActions.addCart(item)),
      onRemoveCart: (item) => dispatch(cartActions.removeCart(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingPaginatedMenu);