import * as actionTypes from './actionTypes';
import showNotifications from '../../components/toast';
import {loginApiPath, loginSosmedApiPath} from '../../services/networkApi';
import api from '../../services/network';


export const authRemove = () => {
  return {
    type: actionTypes.REMOVE_AUTHENTICATION
  }
}

export const authStart = () => {
  return {
      type: actionTypes.AUTHENTICATION_START
  };
};

export const authSuccess = (user) => {
  return {
      type: actionTypes.SET_AUTHENTICATION,
      userInfo: user
  };
};

export const authFail = (error) => {
  return {
      type: actionTypes.AUTHENTICATION_FAIL,
      error: error
  };
};

export const switchSosmed = (status) => {
  return {
    type: actionTypes.SWITCH_SOSMED,
    status: status
  }
}

export const auth = (email, password) => {
  return async dispatch => {
    dispatch(authStart());
    const authData = {
      email: email,
	    password: password
    }

    const loginApi = api(loginApiPath)
    const loginRes = await loginApi.post(authData);
    // console.log(loginRes)
    if (loginRes.errors[0].message === '') {
      const user = {
        userId: loginRes.id,
        fullName: loginRes.fullname,
        email: loginRes.email,
        phone: loginRes.phone,
        sosmed: loginRes.sosmed,
        token: loginRes.token,
      }
      // showNotifications('Login successfully', 'success', 'Login')
      dispatch(authSuccess(user));
    } else {
      dispatch(authFail("Failed to login '" + loginRes.errors[0].message + "'" ));
    }
  }
}

export const authWithGoogle = (source, email, displayName, uid, regId) => {
  return async dispatch => {
    dispatch(authStart());
    const payload = {
      source: source,
      email: email,
      fullname: displayName,
      sourceId: uid,
      regid: regId
    }
    const sosmedLoginApi = api(loginSosmedApiPath)
    const sosmedLoginRes = await sosmedLoginApi.post(payload);
    if (sosmedLoginRes.errors[0].message === '') {
      const user = {
        userId: sosmedLoginRes.id,
        fullName: sosmedLoginRes.fullname,
        email: sosmedLoginRes.email,
        phone: sosmedLoginRes.phone,
        sosmed: sosmedLoginRes.sosmed,
        token: sosmedLoginRes.token,
      }
      // showNotifications('Login successfully', 'success', 'Login');
      dispatch(authSuccess(user));
    } else {
      dispatch(authFail("Failed to login"));
    }
  }
}



