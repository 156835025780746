//Trisakti
// import React, {Component} from 'react';
// import { NavLink, Link } from 'react-router-dom';
// import Aux from '../../hoc/Aux/Aux';
// import Carousels from '../../components/UI/Carousel/Carousels';
// import './Home.css';
// import {Row, Col} from 'react-bootstrap';
// import imageScan from '../../assets/images/okgo-scan.png';
// import imageDrive from '../../assets/images/okgo-drive.png';
// import imageReservation from '../../assets/images/okgo-reservation.png';
// import imagePreorder from '../../assets/images/po.png';
// import imageDaftar from '../../assets/images/daftar-remake.png';
// import imageJadwal from '../../assets/images/jadwal-remake.png';
// import imageEvent from '../../assets/images/event-remake.png';

// // import imagePreorder from '../../assets/images/okgo-preorder.png';
// import Footer from '../../components/Navigation/Footer/Footer';
// import RoundButton from '../../components/UI/Button/RoundedButton/RoundedButton';
// import showNotifications from '../../components/toast';
// import * as actions from '../../store/actions/index';
// import { connect } from 'react-redux';
// import firebase from 'firebase/app';
// import 'firebase/auth';

// class Home extends Component {
//   onBtnLoginClick = () => {
//     const provider = new firebase.auth.GoogleAuthProvider();
//     firebase.auth().signInWithPopup(provider)
//     .then((res) => {
//       this.props.onAuthWithSosmed('GOOGLE', res.user.email, res.user.displayName, res.user.uid, '');
//       firebase.auth().signOut()
//       .then((res) => {})
//       .catch((err) => {
//         showNotifications("Authentication failed.", 'error', 'Reset Password');
//       })
//     })
//     .catch((err) => {
//       console.log(err)
//       showNotifications("Authentication failed.", 'error', 'Reset Password');
//     })
//   }

//   onBtnLogoutClick = () =>{
//     firebase.auth().signOut()
//     .then((res) => this.setState({user : []}))
//     .catch((err) => console.log(err))
//   }

//   render() {
//     let loginForms = null;
//     if (!this.props.isLoggedIn) {
//       loginForms = (<div className="container marginsections authsections">
//                       <Link to="/login">
//                         <div className="d-flex justify-content-center">
//                             <RoundButton className="button-login">LOGIN</RoundButton>
//                         </div>
//                       </Link>  
//                       <div className="d-flex justify-content-center marginsections">
//                         <Link to="/forgot-password">Forgot Password?</Link>
//                       </div>
//                       <div className="d-flex justify-content-center marginsections">
//                         Not yet registered? <Link to="/register"><u className="push-left">Register here</u></Link>
//                       </div>
//                       <div className="d-flex justify-content-center marginsections">
//                         OR
//                       </div>
//                       <div className="d-flex justify-content-center marginsections">
//                         <button className="authsections btn btn-outline-secondary" onClick={this.onBtnLoginClick}>
//                           <img className="google-img" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
//                           Login with Google
//                         </button>
//                       </div>
//                     </div>)
//     }

//     return(
//       <Aux>
//         <Carousels></Carousels>
//         {/* <div className="section">
//           <CircleButton>1</CircleButton>
//           <CircleButton>2</CircleButton>
//           <CircleButton>3</CircleButton>
//         </div> */}
//         <div className="container">
//           <Row>
//               <Col md={1} ></Col>
//               <Col xs={6} md={5} className="paddingcolumns">
//                 <NavLink to='/orders'>
//                   <div className="responsive">
//                     <div className="gallery">                  
//                         <img src={imageScan} alt=""/>
//                     </div>
//                   </div>
//                 </NavLink>
//               </Col>
//               <Col xs={6} md={5} className="paddingcolumns">
//                 <a href="http://event.okgo.co.id/">
//                   <div className="responsive">
//                     <div className="gallery">                  
//                         <img src={imageEvent} alt=""/>
//                     </div>
//                   </div>
//                 </a>
                  
//               </Col>
//               {/* <Col xs={6} md={5} className="paddingcolumns">
//                 <NavLink to='/preorder'>
//                   <div className="responsive">
//                     <div className="gallery">                  
//                         <img src={imagePreorder} alt=""/>
//                     </div>
//                   </div>
//                 </NavLink>
//               </Col> */}
//               <Col xs={3} md={1}></Col>
//             </Row>

//             <Row>
//               <Col md={1} ></Col>
//               <Col xs={6} md={5} className="paddingcolumns">
//                 <NavLink to='/coming-soon'>
//                   <div className="responsive">
//                     <div className="gallery">                  
//                         <img src={imageJadwal} alt=""/>
//                     </div>
//                   </div>
//                 </NavLink>
//               </Col>
//               <Col xs={6} md={5} className="paddingcolumns">
//                 <NavLink to='/coming-soon'>
//                   <div className="responsive">
//                     <div className="gallery">                  
//                         <img src={imageDaftar} alt="daftar"/>
//                     </div>
//                   </div>
//                 </NavLink>
//               </Col>
//               <Col xs={3} md={1}></Col>
//             </Row>
//         </div>
//         {loginForms}
//         <div className="d-flex justify-content-center marginsections">
//           <Footer></Footer>
//         </div>
        
//       </Aux>
//     )
//   }
// }

// const mapDispatchToProps = dispatch => {
//   return {
//       onAuthWithSosmed: (source, email, displayName, uid, regId) => dispatch( actions.authWithGoogle(source, email, displayName, uid, regId)),
//       onStart: () => dispatch(actions.authStart())
//   };
// };

// const mapStateToProps = state => {
//   return {
//       loading: state.user.loading,
//       error: state.user.error,
//       id: state.user.userId,
//       isLoggedIn: state.user.isLoggedIn
//   };
// };

// export default connect(mapStateToProps, mapDispatchToProps )( Home );

//Sahabat
import React, {Component} from 'react';
import { NavLink, Link } from 'react-router-dom';
import Aux from '../../hoc/Aux/Aux';
import Carousels from '../../components/UI/Carousel/Carousels';
import './Home.css';
import {Row, Col} from 'react-bootstrap';
import imageScan from '../../assets/images/okgo-scan.png';
import imageDrive from '../../assets/images/okgo-drive.png';
import imageReservation from '../../assets/images/okgo-reservation.png';
import imagePreorder from '../../assets/images/po2.png';
// import imagePreorder from '../../assets/images/okgo-preorder.png';
import Footer from '../../components/Navigation/Footer/Footer';
import RoundButton from '../../components/UI/Button/RoundedButton/RoundedButton';
import showNotifications from '../../components/toast';
import * as actions from '../../store/actions/index';
import { connect } from 'react-redux';
import firebase from 'firebase/app';
import 'firebase/auth';

class Home extends Component {
  onBtnLoginClick = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
    .then((res) => {
      this.props.onAuthWithSosmed('GOOGLE', res.user.email, res.user.displayName, res.user.uid, '');
      firebase.auth().signOut()
      .then((res) => {})
      .catch((err) => {
        showNotifications("Authentication failed.", 'error', 'Reset Password');
      })
    })
    .catch((err) => {
      console.log(err)
      showNotifications("Authentication failed.", 'error', 'Reset Password');
    })
  }

  onBtnLogoutClick = () =>{
    firebase.auth().signOut()
    .then((res) => this.setState({user : []}))
    .catch((err) => console.log(err))
  }

  render() {
    let loginForms = null;
    if (!this.props.isLoggedIn) {
      loginForms = (<div className="container marginsections authsections">
                      <Link to="/login">
                        <div className="d-flex justify-content-center">
                            <RoundButton className="button-login">LOGIN</RoundButton>
                        </div>
                      </Link>  
                      <div className="d-flex justify-content-center marginsections">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </div>
                      <div className="d-flex justify-content-center marginsections">
                        Not yet registered? <Link to="/register"><u className="push-left">Register here</u></Link>
                      </div>
                      <div className="d-flex justify-content-center marginsections">
                        OR
                      </div>
                      <div className="d-flex justify-content-center marginsections">
                        <button className="authsections btn btn-outline-secondary" onClick={this.onBtnLoginClick}>
                          <img className="google-img" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                          Login with Google
                        </button>
                      </div>
                    </div>)
    }

    return(
      <Aux>
        <Carousels></Carousels>
        {/* <div className="section">
          <CircleButton>1</CircleButton>
          <CircleButton>2</CircleButton>
          <CircleButton>3</CircleButton>
        </div> */}
        <div className="container">
          <Row>
              <Col md={1} ></Col>
              <Col xs={6} md={5} className="paddingcolumns">
                <NavLink to='/orders'>
                  <div className="responsive">
                    <div className="gallery">                  
                        <img src={imageScan} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </Col>
              <Col xs={6} md={5} className="paddingcolumns">
                <NavLink to='/preorder'>
                  <div className="responsive">
                    <div className="gallery">                  
                        <img src={imagePreorder} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </Col>
              <Col xs={3} md={1}></Col>
            </Row>

            {/* <Row>
              <Col md={1} ></Col>
              <Col xs={6} md={5} className="paddingcolumns">
                <NavLink to='/coming-soon'>
                  <div className="responsive">
                    <div className="gallery">                  
                        <img src={imagePreorder} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </Col>
              <Col xs={6} md={5} className="paddingcolumns">
                <NavLink to='/coming-soon'>
                  <div className="responsive">
                    <div className="gallery">                  
                        <img src={imageDrive} alt=""/>
                    </div>
                  </div>
                </NavLink>
              </Col>
              <Col xs={3} md={1}></Col>
            </Row> */}
        </div>
        {loginForms}
        <div className="d-flex justify-content-center marginsections">
          <Footer></Footer>
        </div>
        
      </Aux>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onAuthWithSosmed: (source, email, displayName, uid, regId) => dispatch( actions.authWithGoogle(source, email, displayName, uid, regId)),
      onStart: () => dispatch(actions.authStart())
  };
};

const mapStateToProps = state => {
  return {
      loading: state.user.loading,
      error: state.user.error,
      id: state.user.userId,
      isLoggedIn: state.user.isLoggedIn
  };
};

export default connect(mapStateToProps, mapDispatchToProps )( Home );

