import React, { Component } from 'react';
import Modal from '../../components/UI/Modal/Modal';
import CheckoutBar from '../../components/Cart/CheckoutBar/CheckoutBar';
import { connect } from 'react-redux';
import Cart from '../../components/Cart/Cart';
import Input from '../../components/UI/Input/Input';
import RoundedBottom from '../../components/UI/Button/RoundedButton/RoundedButton';
import showNotifications from '../../components/toast';
import { merchantListFeeInfoPath } from '../../services/networkApi';
import api from '../../services/network';
import './CartList.css';
import { getTotalDeliveryFee } from '../../utils/utils';
import * as cartActions from '../../store/actions/index';
import Spinner from '../../components/UI/Spinner/Spinner';

class CartList extends Component {
  state = {
    remarkValue: {
      id: '',
      elementType: 'text-area',
      elementConfig: {
          type: 'text',
          placeholder: 'Add your remark here'
      },
      value: ''
    },
    showRemark: false,
    purchasable: false,
    finish: true,
    feeInfo: [],
    merchantFee: {
      totalTax: 0,
      totalServiceTax: 0,
      totalPrice: 0,
      totalDelivery: 0
    },
  }

  calculateTax() {
    let totalTax = 0;
		let totalServiceTax = 0;

    const totalPrice = this.props.cart.reduce( (sum, el) => {
      return sum + (el.qty * el.price)
    }, 0)

    let totalDeliveryFee = getTotalDeliveryFee(this.props.cart);

    for (let data of this.props.cart) {
      let taxMerchant = 0;
      let serviceTaxMerchant = 0;
      let totalTransPerListing = data.qty * data.price;
      let merchantInfo = this.state.feeInfo.filter(function(item) {
        return data.merchantId.toString() === item.merchantId.toString();
      })

      if (merchantInfo || merchantInfo.length !== 0) {
        taxMerchant = (totalTransPerListing * parseInt(merchantInfo[0].tax)) / 100;
				serviceTaxMerchant = (totalTransPerListing * parseInt(merchantInfo[0].serviceTax)) / 100;
      }
      totalTax += taxMerchant;
			totalServiceTax += serviceTaxMerchant;
    }

    this.setState({ merchantFee: {
      totalTax: totalTax,
      totalServiceTax: totalServiceTax,
      totalPrice: totalPrice,
      totalDelivery: totalDeliveryFee
    }})
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.cart !== this.props.cart) {
      this.calculateTax()
    } 
  }

  async componentDidMount() {
    const merchantFeeApi = api(merchantListFeeInfoPath)
    let payload = {
      cart: this.props.cart
    }

    const merchantFeeRes = await merchantFeeApi.post(payload)
    if (merchantFeeRes) {
      const { errors } = merchantFeeRes;
      if (errors) {
        showNotifications(errors[0].message, 'error', 'Gagal memuat informasi merchant, silakan hubungi pihak okgo');
      } else {
        this.setState({
          feeInfo: merchantFeeRes
        })
      }
    }

    this.calculateTax()
  }

  remarkCancelHandler = () => {
    this.setState({
      showRemark: false,
      remarkValue: {
        ...this.state.remarkValue,
        value: '',
        id: ''
      }
    });
  }

  remarkActivated = (el) => {
    this.setState({
      showRemark: true,
      remarkValue: {
        ...this.state.remarkValue,
        value: el.remark,
        id: el.id
      }
    });
  }

  incrementQty = (item) => {
    this.setState({ finish: false });
    let carts = [ ...this.props.cart ];

    const cart = carts.filter(items => {
      return items.id === item.id;
    });

    if (cart.length > 0) {
      if (cart[0].stock < cart[0].qty + 1) {
        showNotifications('Maaf, menu ' + cart[0].name + ' telah habis', 'error', 'Listing');
        this.setState({ finish: true });
        return;
      }
    }

    this.props.onStoreCart(item);
    this.setState({ finish: true });
  }

  decrementQty = (item) => {
    this.setState({ finish: false });
    this.props.onRemoveCart(item);
    this.setState({ finish: true });
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      remarkValue: {
        ...this.state.remarkValue,
        value: value
      }
    });
  }

  remarkSaveHandler = () => {
    let data = [ ...this.props.cart ];
    const updatedCart = data.filter(items => {
      return items.id === this.state.remarkValue.id;
    })
    
    if (updatedCart.length > 0) {
      updatedCart[0].remark = this.state.remarkValue.value;
    }
    this.props.onUpdateCart(data);

    this.setState({
      showRemark: false,
      remarkValue: {
        ...this.state.remarkValue,
        value: '',
        id: ''
      }
    });
  }

  payContinuedHandler = () => {
    if (this.props.isLogin) {
      this.setState({
        purchasable: true,
      });
    } else {
      this.props.history.push('/login');
    }
  }

  payCanceledHandler = () => {
    this.setState({
      purchasable: false,
    });
  }

  startPaymentHandler = () => {
    if (this.props.cartType === 'PREORDER') {
      this.props.history.push('/preorder/forms');
    } else {
      this.props.history.push('/payment');
    } 
  }

  backToListHandler = () => {
    this.props.history.push('/orders/foodcourt');
  }

  render() {
    let listing = null;
    let remarkBox = null;
    let remark = null;
    let purchaseModal = null;

    // const totalPrice = this.props.cart.reduce( (sum, el) => {
    //   return sum + (el.qty * el.price)
    // }, 0)

    remarkBox = <Input 
                      elementType={this.state.remarkValue.elementType}
                      elementConfig={this.state.remarkValue.elementConfig}
                      value={this.state.remarkValue.value}
                      isForms='false'
                      changed={(event) => this.inputChangedHandler(event)} />

    remark = (
      <div className="justify-content-center">
        <h6>Make Remark</h6>
        <div className="information">
          {remarkBox}
          <div className = "d-flex justify-content-center push-up">
            <RoundedBottom color="black" width="30%" height="auto" className="no-color" clicked={this.remarkCancelHandler}>Cancel</RoundedBottom>
            <RoundedBottom color="white" width="30%" height="auto" clicked={this.remarkSaveHandler}>Save</RoundedBottom>
          </div>          
        </div>
      </div>
    )

    purchaseModal = (
      <div className="justify-content-center">
        <h6>Checkout</h6>
        <div className="information">
          Are you sure want to continued this process? <br></br> 

          <br></br> <br></br>
          <div className = "d-flex justify-content-center push-up">
            <RoundedBottom color="black" width="30%" height="auto" className="no-color" clicked={this.payCanceledHandler}>Cancel</RoundedBottom>
            <RoundedBottom color="white" width="30%" height="auto" clicked={this.startPaymentHandler}>Ok</RoundedBottom>
          </div>
        </div>
      </div>
    )
  
    listing = this.props.cart.map( el => (
      <Cart key={el.id} menuName={el.name} 
        menuPrice={el.price} 
        merchant={el.merchantName} 
        menuImage={el.image} 
        menuQty={el.qty}
        menuRemark={el.remark}
        incQty={() => {this.incrementQty(el)}}
        decQty={() => {this.decrementQty(el)}}
        addRemark={() => {this.remarkActivated(el)}}
        ></Cart>
    ))
    
    return (
      <div className="container">
        <h6 className="push-bottom-md">SHOPPING CART</h6>
        <div className="padding-bottom-checkout">
          {listing}
        </div>
        
        {/* <CheckoutBar backToList={this.backToListHandler} payContinued={this.payContinuedHandler} price={totalPrice} creator={this.props.creator}></CheckoutBar> */}

        <CheckoutBar backToList={this.backToListHandler} 
          payContinued={this.payContinuedHandler} 
          price={this.state.merchantFee.totalPrice} creator={this.props.creator} 
          orderType={this.props.cartType} 
          deliveryFee={this.state.merchantFee.totalDelivery}
          tax={this.state.merchantFee.totalTax}
          serviceTax={this.state.merchantFee.totalServiceTax}></CheckoutBar>

        <Modal show={this.state.showRemark} modalClosed={this.remarkCancelHandler}>
          {remark}
        </Modal>

        <Modal show={this.state.purchasable} modalClosed={this.payCanceledHandler}>
          {purchaseModal}
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      isLogin: state.user.isLoggedIn,
      creator: state.cart.creator,
      cartType: state.cart.cartType
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onStoreCart: (item) => dispatch(cartActions.addCart(item)),
      onRemoveCart: (item) => dispatch(cartActions.removeCart(item)),
      onUpdateCart: (item) => dispatch(cartActions.updateCart(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CartList);