import React from 'react';
import './Toolbar.css';
import DrawerToggler from '../SideDrawer/DrawerToggler/DrawerToggler';
// import Switcher from '../../../components/UI/Switcher/Switcher';
// import { useLocation } from 'react-router-dom';


const toolbar = (props) => {
  // // eslint-disable-next-line react-hooks/rules-of-hooks
  // let location = useLocation();  
  // let toolbarSwitch = null;
  // if (location.pathname === '/preorder/listing') {
  //   toolbarSwitch = <Switcher switchState={props.switchState} switch={props.switchClicked}/>
  // }

  return (
    <header className="Toolbar">
      <DrawerToggler clicked={props.drawerToggleClicked}></DrawerToggler>
      {/* {toolbarSwitch} */}
    </header>
  );
  
}

export default toolbar;