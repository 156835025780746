import React from 'react';
import Layout from './hoc/Layout/Layout';
import Routes from './Routes/Routes';

const App = (props) => {
  return (
    <Layout>
      <Routes></Routes>
    </Layout>
  )
}

export default App;
