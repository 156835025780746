import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import api from '../../../services/network';
import Input from '../../../components/UI/Input/Input';
import { checkValidity } from '../../../utils/utils';
import RoundedButton from '../../../components/UI/Button/RoundedButton/RoundedButton';
import showNotifications from '../../../components/toast';
import { registerApiPath } from '../../../services/networkApi';
import { updateObject } from '../../../utils/utils';
import './Register.css';

class Register extends Component {
  state = {
    controls: [
      {
        id: 'fullname',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Fullname'
        },
        value: '',
        validation: {
            required: true
        },
        valid: false,
        touched: false
      },
      {
        id: 'email',
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'Email'
        },
        value: '',
        validation: {
            required: true,
            isEmail: true
        },
        valid: false,
        touched: false
      },
      {
        id: 'phone',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Phone'
        },
        value: '',
        validation: {
            required: true,
            minLength: 9,
            maxLength: 20,
            isNumeric: true
        },
        valid: false,
        touched: false
      },
      {
        id: 'password',
        elementType: 'input',
        elementConfig: {
            type: 'password',
            placeholder: 'Password'
        },
        value: '',
        validation: {
            required: true,
            minLength: 6
        },
        valid: false,
        touched: false
      }
    ],
    termsChecked: false,
    formIsValid: false,
  }

  submitRegisterHandler = async(event) => {
    event.preventDefault();
    if (this.state.termsChecked === false) {
      showNotifications('Please read Terms and Condition first', 'error', 'Reset Password');
    } else {
      const registerApi = api(registerApiPath);
      const payload = {
        phone: this.state.controls[2].value,
        fullname: this.state.controls[0].value,
        email: this.state.controls[1].value,
        password: this.state.controls[3].value
      }

      const res = await registerApi.post(payload);
      const { errors } = res;
      if (errors) {
        if(errors[0].message !== '') {
          showNotifications(errors[0].message, 'error', 'Register');
        } else {
          // showNotifications('Register telah berhasil', 'success', 'Register');
          this.props.history.push('/');
        }
      } 
    }
  }

  inputChangedHandler = ( event, controlName ) => {
    let updatedControls = [...this.state.controls];
    let index = updatedControls.findIndex(x => x.id === controlName);

    const updatedControlslements = { 
        ...updatedControls[index]
    };

    updatedControlslements.value = event.target.value;
    updatedControlslements.valid = checkValidity( event.target.value, updatedControlslements.validation );
    updatedControlslements.touched = true;

    updatedControls[index] = updateObject(updatedControls[index], updatedControlslements);

    let formIsValid = true;

    updatedControls.map( formElement => (
      formIsValid = formElement.valid && formIsValid
    ))

    this.setState( { controls: updatedControls, formIsValid: formIsValid } );
  }

  checkedChangeHandler = () => {
    this.setState({termsChecked: !this.state.termsChecked});
  }

  render() {
    const formElementsArray = this.state.controls;

    let form = formElementsArray.map( formElement => (
      <Input
          key={formElement.id}
          elementType={formElement.elementType}
          elementConfig={formElement.elementConfig}
          value={formElement.value}
          invalid={!formElement.valid}
          shouldValidate={formElement.validation}
          touched={formElement.touched}
          changed={( event ) => this.inputChangedHandler( event, formElement.id )} 
          isForms='true'
          />
    ));

    const termsStyle ={
      fontSize: '2vw',
      wordWwrap:'break-word'
    }

    return (
      <div className="app-center auth">
        <h5 className="auth-header">Welcome,</h5>
        <p className="auth-string">For easy payment, do sign up !</p>
        <form onSubmit={this.submitRegisterHandler}>
          {form}
          <label style={termsStyle}>
              <input id="checkid"  type="checkbox" onChange={this.checkedChangeHandler} /> By creating an account you agree to OkGo <a href="http://okgo.co.id/terms.php">Terms and Condition</a>
          </label>

          <div className="auth-padding">
            <RoundedButton width="100%" disabled={!this.state.formIsValid}>Register</RoundedButton>
          </div>
        </form>
        <div className="container marginsections authsections">
          <div className="d-flex justify-content-center marginsections">
            Already have an account?<Link to="/login">Login</Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Register;