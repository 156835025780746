import React, {Component} from 'react';
import { connect } from 'react-redux';
import { updatePasswordApiPath } from '../../../services/networkApi';
import api from '../../../services/network';
import { checkValidity } from '../../../utils/utils';
import Input from '../../../components/UI/Input/Input';
import RoundedButton from '../../../components/UI/Button/RoundedButton/RoundedButton';
import './Password.css';
import showNotifications from '../../../components/toast';
import { updateObject } from '../../../utils/utils';

class Password extends Component {
  state = {
    controls: [
      {
          id: 'oldPassword',
          elementType: 'input',
          elementConfig: {
              type: 'password',
              placeholder: 'Old Password'
          },
          value: '',
          validation: {
              required: true,
              minLength: 0
          },
          valid: false,
          touched: false
      },
      {
        id: 'newPassword',
        elementType: 'input',
        elementConfig: {
            type: 'password',
            placeholder: 'New Password'
        },
        value: '',
        validation: {
            required: true,
            minLength: 6
        },
        valid: false,
        touched: false
      },
      {
        id: 'confirmPassword',
        elementType: 'input',
        elementConfig: {
            type: 'password',
            placeholder: 'Confirm Password'
        },
        value: '',
        validation: {
            required: true,
            minLength: 6
        },
        valid: false,
        touched: false
      },
    ],
    formIsValid: false,
  }

  inputChangedHandler = ( event, controlName ) => {
    let updatedControls = [...this.state.controls];
    let index = updatedControls.findIndex(x => x.id === controlName);

    const updatedControlslements = { 
        ...updatedControls[index]
    };

    updatedControlslements.value = event.target.value;
    updatedControlslements.valid = checkValidity( event.target.value, updatedControlslements.validation );
    updatedControlslements.touched = true;

    updatedControls[index] = updateObject(updatedControls[index], updatedControlslements);

    let formIsValid = true;

    updatedControls.map( formElement => (
      formIsValid = formElement.valid && formIsValid
    ))

    this.setState( { controls: updatedControls, formIsValid: formIsValid } );
  }

  submitHandler = async( event ) => {
    event.preventDefault();
    
    if (this.state.controls[1].value === this.state.controls[2].value) {
      const changePasswordApi = api(updatePasswordApiPath);
      const payload = {
        email: this.props.email,
        old: this.state.controls[0].value,
        password: this.state.controls[1].value,
        token: this.props.token
      }

      const res = await changePasswordApi.post(payload);
      const { errors } = res;
      
      if (errors) {
        if(errors[0].message !== '') {
          showNotifications(errors[0].message, 'error', 'Change Password');
        } else {
          // showNotifications('Successfully changes password', 'success', 'Change Password');
          this.props.history.push('/');
        }
      } 
    } else {
      showNotifications("Password does not match.", 'error', 'Change Password');
    }
  }

  componentDidMount() {
    if (!this.props.isLogin) { this.props.history.push('/'); }
  }

  render() {
    const formElementsArray = this.state.controls;

    let form = formElementsArray.map( formElement => (
      <Input
          key={formElement.id}
          elementType={formElement.elementType}
          elementConfig={formElement.elementConfig}
          value={formElement.value}
          invalid={!formElement.valid}
          shouldValidate={formElement.validation}
          touched={formElement.touched}
          changed={( event ) => this.inputChangedHandler( event, formElement.id )} 
          isForms='true'
          />
      ) );

    return (
      <div className="app-center auth">
        <h5 className="auth-header">Change Password</h5>
        <form onSubmit={this.submitHandler}>
          {form}
          <div className="auth-padding">
            <RoundedButton width="100%" disabled={!this.state.formIsValid}>Change Password</RoundedButton>
          </div>
        </form>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    token: state.user.token,
    userId: state.user.userId,
    isLogin: state.user.isLoggedIn
  }
}

export default connect(mapStateToProps, null) (Password);