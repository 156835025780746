import React from 'react';
import completeImage from '../assets/images/complete.png';
import './complete.css';

const complete = (props) => {
  return (
    <div className="container">
      <div className="app-center">
        <img className="complete-img" alt="" src={completeImage} ></img>

        <h6>
          {props.payvia === 'ovo' ? 'Untuk pembayaran melalui OVO klik notifikasi ovo anda dan isi PIN anda, Terina kasih' : 'your order will be serve + 15 minutes from now'}
        </h6>
        
      </div>
    </div>
  )
}

export default complete;