import React, { Component } from 'react';
import { checkValidity } from '../../../utils/utils';
import { forgotApiPath } from '../../../services/networkApi';
import api from '../../../services/network';
import Input from '../../../components/UI/Input/Input';
import RoundedButton from '../../../components/UI/Button/RoundedButton/RoundedButton';
import showNotifications from '../../../components/toast';
import './ForgotPassword.css';

class ForgotPassword extends Component {
  state = {
    controls: {
      email: {
          elementType: 'input',
          elementConfig: {
              type: 'email',
              placeholder: 'Mail Address'
          },
          value: '',
          validation: {
              required: true,
              isEmail: true
          },
          valid: false,
          touched: false
      }
    },
    formIsValid: false,
  }

  inputChangedHandler = ( event, controlName ) => {
    const updatedControls = {
        ...this.state.controls,
        [controlName]: {
            ...this.state.controls[controlName],
            value: event.target.value,
            valid: checkValidity( event.target.value, this.state.controls[controlName].validation ),
            touched: true
        }
    };

    let formIsValid = true;
    for (let inputIdentifier in updatedControls) {
        formIsValid = updatedControls[inputIdentifier].valid && formIsValid;
    }
    this.setState( { controls: updatedControls, formIsValid: formIsValid } );
  }

  submitForgotHandler = async(event) => {
    event.preventDefault();
    const forgotApi = api(forgotApiPath);
    const payload = {
      email: this.state.controls.email.value,
    }

    const res = await forgotApi.post(payload);
    const { errors, success } = res;
    
    if (errors) {
      if(errors[0].message !== '') {
        showNotifications(errors[0].message, 'error', 'Reset Password');
      } else {
        showNotifications(success, 'success', 'Reset Password');
        this.props.history.push('/');
      }
    } 
  }

  render () {
    const formElementsArray = [];
    for ( let key in this.state.controls ) {
        formElementsArray.push( {
            id: key,
            config: this.state.controls[key]
        } );
    }

    let form = formElementsArray.map( formElement => (
      <Input
          key={formElement.id}
          elementType={formElement.config.elementType}
          elementConfig={formElement.config.elementConfig}
          value={formElement.config.value}
          invalid={!formElement.config.valid}
          shouldValidate={formElement.config.validation}
          touched={formElement.config.touched}
          changed={( event ) => this.inputChangedHandler( event, formElement.id )} 
          isForms='true'
          />
    ));

    return (
      <div className="app-center auth">
        <h5 className="auth-header">Forgot?</h5>
        <p className="auth-string">Reset your password, and send it to your email</p>
        <form onSubmit={this.submitForgotHandler}>
          {form}
          <div className="auth-padding">
            <RoundedButton width="100%" disabled={!this.state.formIsValid}>Reset</RoundedButton>
          </div>
        </form>
      </div>
    )
  }
}

export default ForgotPassword;


