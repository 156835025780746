import React, {Component} from 'react';
import './Carousels.css';
import { Carousel } from 'react-bootstrap';

class Carousels extends Component {
  render() {
    return(        
      <Carousel>
          <Carousel.Item>
            <div className="inner">
              <div className="vertical-center">
                  <h3>Gunakan aplikasi OKGO untuk Reservasi</h3>
                  {/* Bundaran Cemara Asri <br/>
                  Manage by OKGO */}
              </div>
            </div>            
          </Carousel.Item>

          <Carousel.Item>
            <div className="inner">
              <div className="vertical-center">
                  <h3>Gunakan aplikasi OKGO untuk Drive thru</h3>
                  {/* Use scan to order aplikasi OKGO */}
              </div>
            </div>            
          </Carousel.Item>

          <Carousel.Item>
            <div className="inner">
              <div className="vertical-center">
                  {/* <h3>DRIVE THRU</h3>
                  Drive Thru dengan menggunakan Aplikasi OKGO */}
                  <h3>Gunakan aplikasi OKGO untuk pengganti Buku, Menu dan Order</h3>
              </div>
            </div>            
          </Carousel.Item>

          <Carousel.Item>
            <div className="inner">
              <div className="vertical-center">
                  {/* <h3>DRIVE THRU</h3>
                  Drive Thru dengan menggunakan Aplikasi OKGO */}
                  <h3>Gunakan aplikasi OKGO untuk Laundry</h3>
              </div>
            </div>            
          </Carousel.Item>

          <Carousel.Item>
            <div className="inner">
              <div className="vertical-center">
                  {/* <h3>DRIVE THRU</h3>
                  Drive Thru dengan menggunakan Aplikasi OKGO */}
                  <h3>Bayar Cashless pakai OVO dan Linkaja lewat aplikasi OKGO</h3>
              </div>
            </div>            
          </Carousel.Item>

          <Carousel.Item>
            <div className="inner">
              <div className="vertical-center">
                  {/* <h3>DRIVE THRU</h3>
                  Drive Thru dengan menggunakan Aplikasi OKGO */}
                  <h3>OKGO satu aplikasi banyak kegunaan</h3>
              </div>
            </div>            
          </Carousel.Item>
        </Carousel>
    )
  }
}

export default Carousels;