import React, { Component } from 'react';
import './Sales.css';
import { connect } from 'react-redux';
import RoundedButton from '../../../components/UI/Button/RoundedButton/RoundedButton';
import { userLocationApiPath } from '../../../services/networkApi';
import api from '../../../services/network';
import showNotifications from '../../../components/toast';

class Sales extends Component {
  state = {
    lat: '',
    lng: ''
  }

  getCurrentLocation = () => {
    const success = async(position) => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      
      const locationApi = api(userLocationApiPath);
      const payload = {
        token: this.props.token,
        latitude: latitude,
        longitude: longitude,
        accuracy: null
      }
      const userLocationRes = await locationApi.post(payload);
    
      const { errors } = userLocationRes;
      if (errors && errors[0].message !== '') {
        showNotifications('Kesalahan pada sistem, user sementara tidak dapat melakukan check in', 'error', 'Check IN');
      } else {
        // showNotifications('Check In Berhasil', 'success', 'Check IN');
        this.props.history.push('/');
      }
    };
    navigator.geolocation.getCurrentPosition(success);
  }

  componentDidMount() {
    if ("geolocation" in navigator) {
    } else {
      alert('Location service not available')
    }
    
    if (!this.props.isLoggedIn) {
      this.props.history.push('/login');
    }
  }

  render () {
    return (
      <div>
        <div className="foodcourt-list-header">
          <h6>{this.props.merchantName}</h6>
        </div>
        <div className="app-center">
          <RoundedButton color="white" width="50vw" height="10vh" clicked={this.getCurrentLocation}>CHECK IN</RoundedButton>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
    merchantId: state.cart.merchantId,
    merchantName: state.cart.merchantName,
    isLoggedIn: state.user.isLoggedIn,
    token: state.user.token
  };
};

export default connect(mapStateToProps, null)(Sales);