import React from 'react';
import './Merchant.css';
import { Card, Row, Col } from 'react-bootstrap';
import imageDefault from '../../assets/images/okgo.png';
import { imageApi } from '../../config';

const Merchant = (props) => {
  const addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }

  return (
    <Card className="card-no-border" tag='a' onClick={props.clicked}>
        <Row noGutters={true}>
          <Col xs={4} md={4}>
            <div className="list-merchant">
              <img onError={addDefaultSrc} src={imageApi+props.images} alt=""/>
            </div>
          </Col>
          <Col xs={8} md={8} className="align-self-center"> 
            <div className="listbodyVCenter">
              <div className="information">{props.foodcourtName}</div>
            </div>
          </Col>
        </Row>            
    </Card>
  )
}

export default Merchant;