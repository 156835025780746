import React, { Component } from 'react';
import { Row, Col, Card, CardBody, CardTitle} from 'reactstrap';
import RoundedBottom from '../../../components/UI/Button/RoundedButton/RoundedButton';
import NumberFormat from 'react-number-format';
import { preOrderListingDetailApiPath } from '../../../services/networkApi';
import api from '../../../services/network';
import showNotifications from '../../../components/toast';
import { imageApi } from '../../../config';
import imageDefault from '../../../assets/images/okgo.png';
import { connect } from 'react-redux';
import * as actions from '../../../store/actions/index';
import moment from 'moment';
import {daysOfNumber} from '../../../utils/utils';
import './PreorderCart.css';

class PreorderCart extends Component {
  state = {
    preorderDetail: {}
  };

  async componentDidMount() {
    if (!this.props.isLoggedIn) {
      this.props.history.push('/login');
      return;
    }

    if (this.props.cartType !== 'PREORDER') {
      this.props.history.push('/');
    }

    const query = new URLSearchParams(this.props.location.search);
    let listingId = '';
    for (let param of query.entries()) {
      if (param[0] === 'listingId') {
        listingId = param[1];
      }
    }
    if (listingId !== '') {
      const specificMenuApi = api(preOrderListingDetailApiPath);
      const menu = await specificMenuApi.getWithParams(listingId);
      const { errors } = menu;
      if (errors) {
        if(errors[0].message !== '') {
          showNotifications(errors[0].message, 'error', 'Mohon maaf, ada kesalahan pada sistem');
        } 
      } else {
        if (menu.length <= 0) { 
          showNotifications("No Listing Menu Found", 'error', 'Listing Id Salah');
          return; 
        } 
        this.props.onRefreshCart();
        this.props.onStoreOrderType("PREORDER");
        this.props.onUpdateMerchant(menu[0].merchantId, menu[0].merchantName);

        let result = menu.map(el => {
          let o = Object.assign({}, el);
          let lastOrders = 'Closed';
          let lastOrderDate = '';
          let deliveryDate = null;

          if (typeof el.preorderDetails !== 'undefined' && el.preorderDetails.length > 0) {
            const now = moment(new Date()); 
            o.preorderImage = el.preorderDetails[0].image;
            o.preorderName = el.preorderDetails[0].name;
            o.preorderDescription = el.preorderDetails[0].description;
            let detailOrders = el.preorderDetails[0].listingPreorderDetails;
            
            if (typeof detailOrders !== 'undefined' && detailOrders.length > 0) {
              if (detailOrders[0].endPODate !== null) {
                deliveryDate = detailOrders[0].deliveryDate !== null ? moment(detailOrders[0].deliveryDate).format("DD-MM-YYYY") : null;
                let endPODate = moment(detailOrders[0].endPODate);
                let duration = moment.duration(endPODate.diff(now));
                let days = Math.floor(duration.asDays());
                let hours = Math.floor((duration.asHours() % 24));
                let minutes = Math.floor((duration.asMinutes() % 60));
              
                if (days >= 0) {
                  lastOrderDate = moment(endPODate).format("DD-MM-YYYY");
                  lastOrders = days + ' hari : ' + hours + ' Jam : ' + minutes + ' Menit';
                }
              } else if (detailOrders[0].days !== null) {
                let arrayDays = [];
                let daysNowofNumber = daysOfNumber(now.format('dddd'));
    
                let upperDaysList = detailOrders.filter(function(item) {
                  return daysOfNumber(item.days) > daysNowofNumber;
                })
    
                let underDaysList = detailOrders.filter(function(item) {
                  return daysOfNumber(item.days) <= daysNowofNumber;
                })
    
                upperDaysList = upperDaysList.sort((a, b) => {
                  return daysOfNumber(a.days) - daysOfNumber(b.days)
                })
    
                underDaysList = underDaysList.sort((a, b) => {
                  return daysOfNumber(a.days) - daysOfNumber(b.days)
                })
    
                arrayDays = [
                  ...upperDaysList,
                  ...underDaysList
                ]
    
                for (let arrData in arrayDays) {
                  let daysPO = daysOfNumber(arrayDays[arrData].days);
                  if (daysPO - 1 === 0) {
                    daysPO = 7;
                  } else {
                    daysPO = daysPO - 1;
                  }
  
                  let selisih = 0;
  
                  if (daysPO >= daysNowofNumber) {
                    selisih = daysPO - daysNowofNumber;
                  } else {
                    selisih = (7 - daysNowofNumber) + daysPO;
                  }
  
                  let new_date = moment(now, "DD-MM-YYYY").add(selisih, 'days');
                  deliveryDate = new_date.add(1, 'days').format('DD-MM-YYYY');
                  new_date.set({hour:12,minute:0,second:0,millisecond:0})
                  let duration = moment.duration(new_date.diff(now));
                  let days = Math.floor(duration.asDays());
                  let hours = Math.floor((duration.asHours() % 24));
                  let minutes = Math.floor((duration.asMinutes() % 60));
                  if (days >= 0) {
                    lastOrderDate = moment(new_date).format("DD-MM-YYYY");
                    lastOrders = days + ' hari : ' + hours + ' Jam : ' + minutes + ' Menit';
                    break;
                  }
                }
              }
            }
          }

          o.lastOrder = lastOrders;
          o.lastOrderDate = lastOrderDate;
          o.deliveryDate = deliveryDate;
          return o;
        })

        this.setState({
          preorderDetail: result
        })
      }
    }
  }

  addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }

  addToCart = () => {
    if (Object.keys(this.state.preorderDetail).length !== 0) {
      const obj = this.state.preorderDetail[0];

      if (obj.lastOrder === 'Closed') {
        showNotifications('PO telah ditutup', 'error', 'Add to Cart Preorder'); 
        return;
      }

      if (obj.qty === 0) {
        showNotifications('Maaf, menu telah habis', 'error', 'Listing');
        return;
      }

      const itemCart = {
        id: obj.id,
        name: obj.name,
        price: obj.price,
        qty: 1,
        subtotal: obj.price,
        category: obj.category,
        remark: '',
        merchantId: obj.merchantId,
        parentId: obj.merchantId,
        image: obj.image,
        merchantName: obj.merchantName,
        order: 0,
        deliveryDate: obj.deliveryDate,
        deliveryFee: obj.deliveryFee,
        supplierId: obj.supplierId
      }
      this.props.onStoreCart(itemCart);
      this.props.history.push('/cart');
    }
  }

  backToList = () => {
    this.props.history.push('/preorder/listing-menu');
  }

  render() {
    let imgUrl = imageDefault;
    let title = '';
    let periodeOrder = '';
    let deliveryDate = '';
    let detail = '';
    let priceView = null;

    if (Object.keys(this.state.preorderDetail).length !== 0) {
      title = this.state.preorderDetail[0].preorderName;
      imgUrl = imageApi + this.state.preorderDetail[0].preorderImage;
      periodeOrder = this.state.preorderDetail[0].lastOrderDate;
      deliveryDate = this.state.preorderDetail[0].deliveryDate;
      detail = this.state.preorderDetail[0].detail;

      if (this.state.preorderDetail[0].oldPrice !== 0) {
        priceView = (
          <React.Fragment>
            {/* <span className="outer">
              <span className="inner">
                <NumberFormat value={this.state.preorderDetail[0].oldPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
              </span>
            </span> {" - "} */}
            <div>
              <span className="outer">
                <span className="inner">
                  <NumberFormat value={this.state.preorderDetail[0].oldPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                </span>
              </span>
            </div>
            <div>
              <NumberFormat value={this.state.preorderDetail[0].price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
            </div>
          </React.Fragment>
        )
      } else {
        priceView = (<NumberFormat value={this.state.preorderDetail[0].price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />)
      }
    }

    return (
      <React.Fragment>
        <div className="carding">
          <div className="text-middle">
            <Row>
              <Col>
                <Card className="card-no-border">
                  <img className="img-menu-cart" width="100%" src={imgUrl} alt=""/>
                  <CardBody>
                    <div className="information-header">
                      <CardTitle>
                        <b>{title}</b>
                        <div className="information-small">{detail}</div>
                      </CardTitle>
                    </div>

                    <div className="information">
                      <div className="push-bottom-md">
                        {priceView}
                      </div>
                      
                      <div>
                        Periode Order : {periodeOrder}
                      </div>

                      <div>
                        Tanggal Antar : {deliveryDate}
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          
          <div className="app-bottom cart-box carding-checkout">
            <div className="d-flex justify-content-center section">
              <RoundedBottom clicked={this.addToCart} className="push-right" width="35%" >Add to Cart</RoundedBottom>
              <RoundedBottom clicked={this.backToList} width="35%" bgcolor="green">Shop More</RoundedBottom>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = state => {
  return {
    cart: state.cart.items,
    isLoggedIn: state.user.isLoggedIn,
    token: state.user.token,
    cartType: state.cart.cartType
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRefreshCart: () => dispatch(actions.deleteCart()),
    onStoreCart: (item) => dispatch(actions.addCart(item)),
    onUpdateMerchant: (merchant, name) => dispatch(actions.updateMerchant(merchant, name)),
    onStoreOrderType: (orderType) => dispatch(actions.setCartType(orderType))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)( PreorderCart );