import React, { Component } from 'react';
import { Row, Col } from 'react-bootstrap';
import WalletBar from '../../../components/Payment/WalletBar/WalletBar';
import {connect} from 'react-redux';
import api from '../../../services/network';
import { balanceApiPath, orderCheckoutPath } from '../../../services/networkApi';
import * as Actions from '../../../store/actions/index';
import showNotifications from '../../../components/toast';
import imageDefault from '../../../assets/images/wallet.png';
import './Wallet.css';

class Wallet extends Component{
  state = {
    totalPrice: 0,
    balance: 0,
  }

  async componentDidMount() {
    if (this.props.isLogin !== true) {
      this.props.history.push('/');
    } else {
      const totalPrice = this.props.cart.reduce( (sum, el) => {
        return sum + (el.qty * el.price)
      }, 0)

      const balanceApi = api(balanceApiPath);
      let payload = {
        email: this.props.email,
        token: this.props.token
      }
      const balanceRes = await balanceApi.post(payload);
      if (balanceRes) { 
        const { errors } = balanceRes;
      
        if (errors) {
          if(errors[0].message !== '') {
            showNotifications(errors[0].message, 'error', 'Balance Wallet');
          } else {
            this.setState({ balance: balanceRes.balance, totalPrice: totalPrice})
          }
        }
      }
    }
  }

  payContinuedHandler = async() => {
    if (this.state.balance === 0) {
      showNotifications("Maaf, anda tidak bisa melanjutkan orderan dengan balance 0", 'error', 'Balance Wallet');
    } else if (this.state.totalPrice > this.state.balance) {
      showNotifications("Maaf, balance anda tidak mencukupi untuk melakukan orderan", 'error', 'Balance Wallet');
    } else {
      let orderSuccess = false;
      const checkoutApi = api(orderCheckoutPath);
      let carts = JSON.stringify(this.props.cart)
      let payload = {
        order: carts.toString(),
        preorder: '{}',
        promo: '{}',
        payment: 'WALLET',
        tableNo: this.props.tableNo === null ? '' : this.props.tableNo,
        token: this.props.token
      }

      const checkoutRes = await checkoutApi.post(payload);
      if (checkoutRes) { 
        const { errors } = checkoutRes;
        if (errors) {
          if(errors[0].message !== '') {
            orderSuccess = false;
            showNotifications(errors[0].message, 'error', 'Checkout error');
          } else {
            orderSuccess = true;
          }
        } 
      }

      if (orderSuccess === true) {
        // showNotifications('Terima kasih telah menggunakan OkGo', 'success', 'Checkout');
        this.props.onRefreshCart();
        this.props.history.push('/payment/success');
      }
    }
  }

  render() {
    return(
      <div className="container">
        <h5 className="auth-header">Which payment do you want to choose?</h5>
        <div className="wallet-box">
          <Row noGutters={true} className="wallet-info-box">
            <Col xs={9} md={9}>
              <div className="listbody">
                <div className="information">{this.props.userName} Voucher</div>
                <div className="information-header">Current Point</div>
                  <div className="push-up information-header">pts {this.state.balance}</div>
              </div>
            </Col>
            <Col xs={3} md={3}>
              <div className="img-wallet">
                <img src={imageDefault} alt=""/>
              </div>
            </Col>
          </Row>            
        </div>
        <WalletBar payContinued={this.payContinuedHandler} price={this.state.totalPrice} creator={this.props.creator}></WalletBar>
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      merchantId: state.cart.merchantId,
      token: state.user.token,
      tableNo: state.cart.table,
      isLogin: state.user.isLoggedIn,
      userName: state.user.fullName,
      email: state.user.email
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onStoreCart: (item) => dispatch(Actions.addCart(item)),
      onRemoveCart: (item) => dispatch(Actions.removeCart(item)),
      onUpdateCart: (item) => dispatch(Actions.updateCart(item)),
      onRefreshCart: () => dispatch(Actions.deleteCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (Wallet)