import React from 'react';
import './FloatingButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown } from '@fortawesome/free-solid-svg-icons'

const FloatingButton = (props) => {
  return (
    <div tag="a" className="float front" onClick={props.clicked}>
      <div className="my-float">
        <FontAwesomeIcon icon={faCartArrowDown} size="lg"></FontAwesomeIcon> {props.cartCount}
      </div>
    </div>
  )
  
};

export default FloatingButton;