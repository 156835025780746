import React, {Component} from 'react';
import imageMapPreorder from '../../assets/images/map-preorder.png';
import api from '../../services/network';
import Aux from '../../hoc/Aux/Aux';
import Input from '../../components/UI/Input/Input';
import {areaApiPath} from '../../services/networkApi';
import Spinner from '../../components/UI/Spinner/Spinner';
import { connect } from 'react-redux';
import * as actions from '../../store/actions/index';
import { filterList } from '../../utils/utils';
import './PreOrder.css';

class PreOrder extends Component {
  state = {
    filterValue: {
      elementType: 'input-filter',
      elementConfig: {
          type: 'text',
          placeholder: 'Search'
      },
      value: ''
    },
    areaData: [],
    loading: true,
  }

  async componentDidMount() {
    if (this.props.isLoggedIn !== true) {
      this.props.history.push('/login');
    } else {
      this.props.onRefreshCart()
      this.props.onStoreOrderType("PREORDER")
      const areaListApi = api(areaApiPath)
      const areaList = await areaListApi.get();
      this.setState({
        areaData: areaList,
        loading: false,
      });
    }
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      filterValue: {
        ...this.state.filterValue,
        value: value
      }
    });
  }

  filterList = () => {
    const val = this.state.filterValue.value;
    let filteredList = [
      ...this.state.areaData
    ]

    filteredList = filteredList.filter(function(item) {
      return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
    })

    return filteredList
  }

  clicked = (el) => {
    this.props.onUpdateArea(el.name);
    const queryParams = [];
    queryParams.push('area=' + el.id);
    const queryString = queryParams.join('&');
    this.props.history.push({
      pathname: this.props.match.path + '/listing',
      search: '?' + queryString
    });
  }

  render() {
    const area = filterList(this.state.areaData, this.state.filterValue.value);
    let areaListing = null;

    areaListing = area.map( el => (
      <div className="area-list area-header-info text-middle" key={el.id} tag='a' onClick={() => this.clicked(el)}>{el.name}</div>
    ))

    if ( this.state.loading ) {
      areaListing = <Spinner isOpacity="true"/>;
    }

    let filterBox = <Input 
                      elementType={this.state.filterValue.elementType}
                      elementConfig={this.state.filterValue.elementConfig}
                      value={this.state.filterValue.value}
                      isForms='false'
                      changed={(event) => this.inputChangedHandler(event)} />

    return (
      <Aux>
        <img src={imageMapPreorder} className="bg-area" alt=""></img>
        <div className="area-header">
          <div className="row h-100 justify-content-center align-items-center">
            <div className="text-middle d-flex justify-content-center flex-column area-header-filter">
              <div className="area-header-info">PRE-ORDER</div>
              <div className="area-header-info2 push-bottom">LOCATION</div>
              <div className="justify-content-center push-up">{filterBox}</div>
            </div>
            
          </div>
        </div>
        <div className="area area-content">
          {areaListing}
        </div>
      </Aux>
    )
  }
}

const mapStateToProps = state => {
  return {
      isLoggedIn: state.user.isLoggedIn
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onRefreshCart: () => dispatch(actions.deleteCart()),
    onStoreOrderType: (orderType) => dispatch(actions.setCartType(orderType)),
    onUpdateArea: (area) => dispatch(actions.updateArea(area))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)( PreOrder );