import React from 'react';
import { Row, Col } from 'react-bootstrap';
import RoundedBottom from '../../UI/Button/RoundedButton/RoundedButton';
import './WalletBar.css';
import NumberFormat from 'react-number-format';

const WalletBar = (props) => {
  let disabled = props.price <= 0 ? true : false;
  let buttons = <RoundedBottom width="100%" disabled={disabled} clicked={props.payContinued}>SUBMIT ORDER</RoundedBottom>;

  return(
    <div className="app-bottom wallet-box information d-flex justify-content-center">
      <div className="wallet-box-info">
        <Row>
          <Col>
            <div className="float-left">Total</div>
          </Col>
          <Col>
            <div className="float-right">
              <NumberFormat value={props.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
            </div>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          {buttons}
        </Row>
      </div>
    </div>
  )
}

export default WalletBar;