import React, { Component } from 'react';
import Input from '../../../components/UI/Input/Input';
import RoundedButton from '../../../components/UI/Button/RoundedButton/RoundedButton';
import { checkValidity } from '../../../utils/utils';
import { Link } from 'react-router-dom';
import api from '../../../services/network';
import { connect } from 'react-redux'
import { profileApiPath, sosmedApiPath, updateProfileApiPath } from '../../../services/networkApi';
import showNotifications from '../../../components/toast';
import * as actions from '../../../store/actions/index';
import firebase from 'firebase/app';
import 'firebase/auth';
import './Profile.css';
import { updateObject } from '../../../utils/utils';

class Profile extends Component {
  state = {
    controls: [
      {
          id: 'fullname',
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Fullname'
          },
          value: '',
          validation: {
              required: true
          },
          valid: true,
          touched: false
      },
      {
          id: 'email',
          elementType: 'input',
          elementConfig: {
              type: 'email',
              placeholder: 'Email'
          },
          value: '',
          validation: {
              required: true,
              isEmail: true
          },
          valid: true,
          touched: false
      },
      {
          id: 'phone',
          elementType: 'input',
          elementConfig: {
              type: 'text',
              placeholder: 'Phone'
          },
          value: '',
          validation: {
              required: true,
              minLength: 9,
              maxLength: 20,
              isNumeric: true
          },
          valid: true,
          touched: false
      }
    ],
    isSosmed: false,
    formIsValid: true,
  }

  async componentDidMount() {
    if (!this.props.isLogin) {
      this.props.history.push('/');
    } else {
      const profileApi = api(profileApiPath);
      const profileRes = await profileApi.getWithParams(this.props.email);
      const { errors } = profileRes;
      if (errors) {
        if(errors[0].message !== '') {
          showNotifications(errors[0].message, 'error', 'Show Profile');
        } else {
          const updatedControls = [ ...this.state.controls ];
          updatedControls[0].value = profileRes.fullname;
          updatedControls[0].valid = true;
          updatedControls[0].touched = false;

          updatedControls[1].value = profileRes.email;
          updatedControls[1].valid = true;
          updatedControls[1].touched = false;

          updatedControls[2].value = (profileRes.phone === null) ? '' : profileRes.phone;
          updatedControls[2].valid = true;
          updatedControls[2].touched = false;

          this.setState( { controls: updatedControls, isSosmed: profileRes.sosmed } );
        }
      }
    }
  }

  updateSosmed = async(payload) => {
    const sosmedApi = api(sosmedApiPath);
    const sosmedRes = await sosmedApi.post(payload);
    const { errors } = sosmedRes;
    if (errors) {
      if(errors[0].message !== '') {
        showNotifications(errors[0].message, 'error', 'Update Sosmed');
      } else {
        this.setState( { isSosmed: !this.state.isSosmed }, 
          () => {this.props.onSwitchSosmed(this.state.isSosmed)});
        
        firebase.auth().signOut()
        .then((res) => {})
        .catch((err) => {
          showNotifications(err, 'error', 'Reset Password');
        })
      }
    }
  }

  updateSosmedHandler = async () => {
    let payload = {};

    if (this.state.isSosmed) {
      payload = {
        token: this.props.token,
        userId: this.props.userId,
        email: '',
        source: 'GOOGLE',
        sourceId: ''
      }
      this.updateSosmed(payload);
    } else {
      const provider = new firebase.auth.GoogleAuthProvider();
      firebase.auth().signInWithPopup(provider)
      .then((res) => {
        payload = {
          token: this.props.token,
          userId: this.props.userId,
          email: res.user.email,
          source: 'GOOGLE',
          sourceId: res.user.uid
        }
        this.updateSosmed(payload);
      })
      .catch((err) => {
        console.log(err)
        showNotifications("Authentication failed.", 'error', 'Reset Password');
      })
    }
  }

  updateHandler = async() => {
    const updateProfileApi = api(updateProfileApiPath);
    const payload = {
      phone: this.state.controls[2].value,
      email: this.state.controls[1].value,
      fullname: this.state.controls[0].value,
      token: this.props.token
    }

    const updatedRes = await updateProfileApi.post(payload);
    const { errors } = updatedRes;
    if (errors) {
      if(errors[0].message !== '') {
        showNotifications(errors[0].message, 'error', 'Update Sosmed');
      } else {
        // showNotifications('Profile updated', 'success', 'Update Sosmed');
        this.props.history.push('/');
      }
    }
  }

  inputChangedHandler = ( event, controlName ) => {
    let updatedControls = [...this.state.controls];
    let index = updatedControls.findIndex(x => x.id === controlName);

    const updatedControlslements = { 
        ...updatedControls[index]
    };

    updatedControlslements.value = event.target.value;
    updatedControlslements.valid = checkValidity( event.target.value, updatedControlslements.validation );
    updatedControlslements.touched = true;

    updatedControls[index] = updateObject(updatedControls[index], updatedControlslements);

    let formIsValid = true;

    updatedControls.map( formElement => (
      formIsValid = formElement.valid && formIsValid
    ))

    this.setState( { controls: updatedControls, formIsValid: formIsValid } );
  }

  render() {
    const formElementsArray = this.state.controls;
    let connectGoogleString = (this.state.isSosmed) ? 'Connected' : 'Not Connected';
    const whitespace ={
      marginRight: '2px'
    }

    let form = formElementsArray.map( formElement => (
                <Input
                    key={formElement.id}
                    elementType={formElement.elementType}
                    elementConfig={formElement.elementConfig}
                    value={formElement.value}
                    invalid={!formElement.valid}
                    shouldValidate={formElement.validation}
                    touched={formElement.touched}
                    changed={( event ) => this.inputChangedHandler( event, formElement.id )} 
                    isForms='true'
                    />
              ));

    return (
      <div className="app-center auth">
        <h5 className="auth-header">My Profile</h5>
        <div>
          {form}
          <div className="d-flex justify-content-center marginsections">
            <button className="authsections btn btn-outline-secondary" onClick={this.updateSosmedHandler}>
              <img className="google-img" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
              {connectGoogleString}
            </button>
          </div>
          
          <div className="auth-padding">
            <RoundedButton width="100%" disabled={!this.state.formIsValid} clicked={this.updateHandler}>Change</RoundedButton>
          </div>
        </div>
        
        <div className="container marginsections authsections">
          <div className="d-flex justify-content-center marginsections">
            Change your password <span style={whitespace}> </span> <Link to="/profile/password">here</Link>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    email: state.user.email,
    token: state.user.token,
    userId: state.user.userId,
    isLogin: state.user.isLoggedIn
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onSwitchSosmed: (status) => dispatch(actions.switchSosmed(status))
  };
};


export default connect(mapStateToProps, mapDispatchToProps) (Profile);
