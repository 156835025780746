import React from 'react';
import { NavLink } from 'react-router-dom';
import './NavigationItem.css';

const navigationItem = (props) => (
  <div className="SidebarMenu">
    <li className="NavigationItem">
      <NavLink
          onClick={props.clicked} 
          to={props.link}
          exact={props.exact}
          activeClassName="active">
          <div className="sides"></div>
          {props.children}</NavLink>
    </li>
  </div>
  
);

export default navigationItem;
