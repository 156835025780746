export const baseApi = process.env.NODE_ENV === 'production' ? 'https://apis.okgo.co.id/' : 'https://apis.okgo.co.id/';

// export const baseApi = process.env.NODE_ENV === 'production' ? 'http://localhost:6200/' : 'http://localhost:6200/';
export const imageApi = 'https://apis.okgo.co.id/image/';
export const iv = '0000006851989#99';
export const secretKey = '00kg0006859189000000000001324092';
export const secretKeyStorage = '00kg000172881000000sdhag7119d8s1212';



// export const xendit_SecretKey = 'xnd_development_nIEFX7zbwPG7DPNFb5V3eW6vg0NG65tE1btFLm9MkEHCxPXBbPuHP6O3hUsvcB:';

export const firebaseConfig = {
  apiKey: "AIzaSyDYG_U6T1otIkZlMKG118dfC8I6ApU4MTY",
  authDomain: "okgo-dev.firebaseapp.com",
  databaseURL: "https://okgo-dev.firebaseio.com",
  projectId: "okgo-dev",
  storageBucket: "okgo-dev.appspot.com",
  messagingSenderId: "1005633106852",
  appId: "1:1005633106852:web:72ea56be4c221f6eec8731"
};