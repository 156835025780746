import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { checkValidity } from '../../../utils/utils';
import Input from '../../../components/UI/Input/Input';
import RoundedButton from '../../../components/UI/Button/RoundedButton/RoundedButton';
import * as actions from '../../../store/actions/index';
import Spinner from '../../../components/UI/Spinner/Spinner';
import './Auth.css';
import firebase from 'firebase/app';
import 'firebase/auth';
import showNotifications from '../../../components/toast';
import { updateObject } from '../../../utils/utils';

class Auth extends Component {
  state = {
    controls: [
      {
          id: 'email',
          elementType: 'input',
          elementConfig: {
              type: 'email',
              placeholder: 'Mail Address'
          },
          value: '',
          validation: {
              required: true,
              isEmail: true
          },
          valid: false,
          touched: false
      },
      {
          id: 'password',
          elementType: 'input',
          elementConfig: {
              type: 'password',
              placeholder: 'Password'
          },
          value: '',
          validation: {
              required: true,
              minLength: 6
          },
          valid: false,
          touched: false
      }
    ],
    formIsValid: false,
  }

  // componentDidMount() {
  //   // this.props.onStart()
  //   // window.location.reload();
  // }

  inputChangedHandler = ( event, controlName ) => {
    let updatedControls = [...this.state.controls];
    let index = updatedControls.findIndex(x => x.id === controlName);

    const updatedControlslements = { 
        ...updatedControls[index]
    };

    updatedControlslements.value = event.target.value;
    updatedControlslements.valid = checkValidity( event.target.value, updatedControlslements.validation );
    updatedControlslements.touched = true;

    updatedControls[index] = updateObject(updatedControls[index], updatedControlslements);

    let formIsValid = true;

    updatedControls.map( formElement => (
      formIsValid = formElement.valid && formIsValid
    ))

    this.setState( { controls: updatedControls, formIsValid: formIsValid } );
  }

  submitHandler = ( event ) => {
    event.preventDefault();
    this.props.onAuth( this.state.controls[0].value, this.state.controls[1].value);
  }

  onBtnLoginClick = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().signInWithPopup(provider)
    .then((res) => {
      this.props.onAuthWithSosmed('GOOGLE', res.user.email, res.user.displayName, res.user.uid, '');
      firebase.auth().signOut()
      .then((res) => {})
      .catch((err) => {
        showNotifications("Authentication failed.", 'error', 'Reset Password');
      })
    })
    .catch((err) => {
      console.log(err)
      showNotifications("Authentication failed.", 'error', 'Reset Password');
    })
  }

  render () {
    const formElementsArray = this.state.controls;
    let result = null;
    let errorMessage = null;

    if (this.props.id === '') {
        let form = formElementsArray.map( formElement => (
        <Input
            key={formElement.id}
            elementType={formElement.elementType}
            elementConfig={formElement.elementConfig}
            value={formElement.value}
            invalid={!formElement.valid}
            shouldValidate={formElement.validation}
            touched={formElement.touched}
            changed={( event ) => this.inputChangedHandler( event, formElement.id )} 
            isForms='true'
            />
        ) );
      
        if (this.props.loading) {
          form = <Spinner />
        }

        if (this.props.error) {
            errorMessage = (
                <p className="error-message">{this.props.error}</p>
            );
        }

        result = (<div className="app-center auth">
                    <h5 className="auth-header">Welcome</h5>
                    <p className="auth-string">For easy payment, login your account</p>
                    {errorMessage}
                    <form onSubmit={this.submitHandler}>
                      {form}
                      <div className="auth-padding">
                        <RoundedButton width="100%" disabled={!this.state.formIsValid}>Login</RoundedButton>
                      </div>
                    </form>
                    <div className="container marginsections authsections">
                      <div className="d-flex justify-content-center marginsections">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </div>
                      <div className="d-flex justify-content-center marginsections">
                        Not yet registered? <Link to="/register"><u>Register here</u></Link>
                      </div>
                      <div className="d-flex justify-content-center marginsections">
                        OR
                      </div>
                      <div className="d-flex justify-content-center marginsections">
                      <button className="authsections btn btn-outline-secondary" onClick={this.onBtnLoginClick}>
                          <img className="google-img" alt="Google sign-in" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png" />
                          Login with Google
                        </button>
                      </div>
                    </div>
                  </div>)
    } else {
      this.props.history.goBack();
    }

    return result;
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onAuth: (email, password) => dispatch( actions.auth(email, password )),
      onAuthWithSosmed: (source, email, displayName, uid, regId) => dispatch( actions.authWithGoogle(source, email, displayName, uid, regId)),
      onStart: () => dispatch(actions.authStart())
  };
};

const mapStateToProps = state => {
  return {
      loading: state.user.loading,
      error: state.user.error,
      id: state.user.userId
  };
};

export default connect(mapStateToProps, mapDispatchToProps )( Auth );