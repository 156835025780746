import React from 'react';
import './RoundedButton.css';

const button = (props) => {
  let classes = ["round", "information"];

  if (props.positions === 'Bottom') {
    classes = [...classes, 'app-bottom']
  }

  classes = [...classes, props.className];

  const myStyle = {
    width: props.width ? props.width : null,
    height: props.height ? props.height : null,
    color: props.color ? props.color : null,
    backgroundColor: props.bgcolor ? props.bgcolor : null,
    border: props.border ? props.border : 'none',
  }

  return (
    <button onClick={props.clicked} className={classes.join(' ')}
        disabled={props.disabled} 
        style={ myStyle }
        >{props.children}
    </button>
  )
}

export default button;