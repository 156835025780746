import React, { Component } from 'react';
import { connect } from 'react-redux';
import QrReader from 'react-qr-reader';
import { orderApiPath, listSpecificMenuApiPath } from '../../services/networkApi';
import Aux from '../../hoc/Aux/Aux';
import api from '../../services/network';
import showNotifications from '../../components/toast';
import * as actions from '../../store/actions/index';
import { isJson } from '../../utils/utils';

class Orders extends Component {
  state = {
    result: ''
  }
 
  handleScan = data => {
    if (data) {
      this.setState({
        result: data
      }, () => {this.init()})
    }
  }

  handleError = err => {
    showNotifications('Maaf, mohon gunakan QR Code yang benar', 'error', 'QR Barcode')
  }

  passing = () => {
    // {"merchantId":"16","table":"sales"} => barcode
    this.setState({
      result: '{"merchantId":"1","table":"A-1"}'
    }, () => {this.init()})

    // this.setState({
    //   result: '{"merchantId":"16","table":"sales"}'
    // }, () => {this.init()})
  }

  init = async() => {
    
    if (!isJson(this.state.result)) {
      if (this.state.result === 'http://reward.okgo.co.id') {
        window.location.href = 'http://reward.okgo.co.id'
      }
    } else {
      let data = JSON.parse(this.state.result);

      if(data.merchantId !== undefined && data.table !== undefined) {
        // const merchantTypeApi = api(orderApiPath);
        // const merchantType = await merchantTypeApi.post(data);
        // this.props.onRefreshCart();

        // if (merchantType) {  
        //   this.props.onStoreTable(data.table);
        //   this.props.onStoreMerchant(data.merchantId, merchantType.name, merchantType.creator, merchantType.parentId, merchantType.name);

        //   if (merchantType.creator === 'MERCHANT' || merchantType.creator === 'LAUNDRY') {  
        //     this.props.history.push('/orders/listing-menu');
        //   } else {
        //     this.props.history.push('/orders/foodcourt');
        //   }
        // } else {
        //   showNotifications('Maaf, mohon scan barcode kembali', 'error', 'QR Barcode')
        // }

        const merchantTypeApi = api(orderApiPath);
        const merchantType = await merchantTypeApi.post(data);
        this.props.onRefreshCart();
        this.props.onStoreOrderType("ORDER");
        if (merchantType) {  
          if (data.table === 'sales') {
            this.props.onStoreMerchant(data.merchantId, merchantType.name, '', '', '');
            this.props.history.push('/sales');
          } else {
            this.props.onStoreTable(data.table);
            this.props.onStoreMerchant(data.merchantId, merchantType.name, merchantType.creator, merchantType.parentId, merchantType.name);

            if (merchantType.creator === 'MERCHANT' || merchantType.creator === 'LAUNDRY') {  
              this.props.history.push('/orders/listing-menu');
            } else {
              this.props.history.push('/orders/foodcourt');
            }
          }
        } else {
          showNotifications('Maaf, mohon scan barcode kembali', 'error', 'QR Barcode')
        }

      } else if (data.listingId !== undefined) {
        this.props.onRefreshCart();
        this.props.onStoreOrderType("ORDER");
        const specificMenuApi = api(listSpecificMenuApiPath);
        const menu = await specificMenuApi.getWithParams(data.listingId);
        const { errors } = menu;
        if (errors) {
          if(errors[0].message !== '') {
            showNotifications(errors[0].message, 'error', 'QR Barcode');
          } 
        } else {
          this.props.onStoreMerchant(menu[0].merchantId, menu[0].merchantName, '', '', '');
          const itemCart = {
            id: menu[0].id,
            name: menu[0].name,
            price: menu[0].price,
            qty: 1,
            subtotal: menu[0].price,
            category: menu[0].category,
            remark: '',
            merchantId: menu[0].merchantId,
            parentId: menu[0].parentId,
            image: menu[0].image,
            merchantName: menu[0].merchantName,
            order: menu[0].order,
            deliveryFee: 0,
            deliveryDate: null,
            supplierId: menu[0].supplierId,
          }
          this.props.onStoreCart(itemCart);
          this.props.history.push('/cart');
        }
      } else {
        showNotifications('Maaf, mohon gunakan barcode yang benar', 'error', 'QR Barcode')
      }
    } 
    
  }  

  render() {
    return (
      <Aux>
        <h6 className="push-bottom-md">SCAN TO ORDER</h6>
        <QrReader
            delay={300}
            onError={this.handleError}
            onScan={this.handleScan}
            style={{ width: '100%' }}
        />
        {/* <button onClick={this.passing}>Test</button> */}
      </Aux>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onRefreshCart: () => dispatch(actions.deleteCart()),
    onStoreTable: (table) => dispatch(actions.storeTable(table)),
    onStoreCart: (item) => dispatch(actions.addCart(item)),
    onStoreMerchant: (merchant, name, creator, parentId, parentName) => dispatch(actions.storeMerchant(merchant, name, creator, parentId, parentName)),
    onStoreOrderType: (orderType) => dispatch(actions.setCartType(orderType))
  };
};

export default connect(null, mapDispatchToProps)(Orders);