import React from 'react';
import { NavLink } from 'react-router-dom';
import './FooterItem.css';

const footerItem = (props) => {
  let navItem = (
    <NavLink 
          to={props.link}
          exact={props.exact}
          activeClassName="active">{props.children}</NavLink>
  )

  if (props.isUrl) {
    navItem = (
      <a href={props.link}>{props.children}</a>
    )
  }
  
  return (
    <li className="FooterItem">
        {/* <NavLink 
            to={props.link}
            exact={props.exact}
            activeClassName="active">{props.children}</NavLink> */}
            {navItem}
    </li>)
};

export default footerItem;