import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import RoundedBottom from '../../components/UI/Button/RoundedButton/RoundedButton';
import Complete from '../../components/complete';

class Success extends Component {
  state = {
    payvia: '',
  }

  componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let trxId = '';
    for (let param of query.entries()) {
      if (param[0] === 'payvia') {
        trxId = param[1];
      }
    }
    this.setState({payvia: trxId});
  }

  render() {
    return (
      <div>
        <Complete payvia={this.state.payvia}></Complete>
        <div className="button-cart">
          <Link to="/">
            <RoundedBottom bgcolor="#8A4537" className="button-cart-list" positions="Bottom">Home</RoundedBottom>
          </Link>
        </div>
      </div>
    )
  }
}

export default Success;