import React from 'react';
import FooterItem from './FooterItem/FooterItem';
import './FooterItems.css'

const footerItems = (props) => (
  <ul className="FooterItems">
        <FooterItem link="/about-us">About Us</FooterItem>
        <FooterItem link="/contact-us">Contact Us</FooterItem> 
        {/* <FooterItem link="/terms" isUrl="true">Terms</FooterItem>  */}
        <FooterItem link="http://okgo.co.id/terms.php" isUrl="true">Terms</FooterItem> 
        <FooterItem link="/privacy-policy">Privacy Policy</FooterItem> 
    </ul>
)

export default footerItems;