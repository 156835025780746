import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../../utils/utils';

const initState = {
  cartType: null,
  creator: null,
  parentId: null,
  parentName: null,
  merchantId: null,
  merchantName: null,
  table: null,
  address: null,
  time: null,
  phone: null,
  areaId: null,
  items: []
}

const setCartType = (state, action) => {
  return updateObject(state, {cartType: action.typeId})
}

const updateMerchant = (state, action) => {
  return updateObject(state, {merchantId: action.merchant.merchantId, merchantName: action.merchant.merchantName})
}

const setMerchant = (state, action) => {
  return updateObject(state, {creator: action.merchant.creator, parentId: action.merchant.parentId, parentName: action.merchant.parentName, merchantId: action.merchant.merchantId, merchantName: action.merchant.merchantName})
}

const setTable = (state, action) => {
  return updateObject(state, {table: action.table});
}

const clearCart = (state, action) => {
  return updateObject(state, {creator: null, parentId: null, parentName: null, merchantId: null, merchantName: null, table: null, address: null, areaId: null, items: []});
}

const addCart = (state, action) => {
  let itemArray = [...state.items];
  let index = itemArray.findIndex(x => x.id === action.item.id);

  if (index < 0) {
    itemArray = [...state.items, ...[action.item]]
  } else { 
    const updatedCartElements = { 
        ...itemArray[index]
    };
    updatedCartElements.qty += 1;
    updatedCartElements.subtotal += action.item.price;
    itemArray[index] = updateObject(itemArray[index], updatedCartElements);
  }
  return updateObject(state, {items: itemArray});
}

const updateQtyCart = (state, action) => {
  let itemArray = [...state.items];
  let index = itemArray.findIndex(x => x.id === action.item.id);

  if (index < 0) {
    itemArray = [...state.items, ...[action.item]]
  } else { 
    const updatedCartElements = { 
        ...itemArray[index]
    };
    updatedCartElements.qty = action.item.qty;
    updatedCartElements.subtotal = action.item.subtotal;

    itemArray[index] = updateObject(itemArray[index], updatedCartElements);
  }
  return updateObject(state, {items: itemArray});
}

const deleteCart = (state, action) => {
  let itemArray = [...state.items];
  let index = itemArray.findIndex(x => x.id === action.item.id);
  
  if (index >= 0) {
    const totalQty = itemArray[index].qty;
    if (totalQty > 1) {
      const updatedCartElements = { 
        ...itemArray[index]
      };
      updatedCartElements.qty -= 1;
      updatedCartElements.subtotal -= action.item.price;
      itemArray[index] = updateObject(itemArray[index], updatedCartElements);
    } else {
      itemArray.splice(index, 1)
    }
  }
  return updateObject(state, {items: itemArray});
}

const updateCart = (state, action) => {
  return updateObject(state, {items: action.item})
}

const updateAddress = (state, action) => {
  return updateObject(state, {address: action.address});
}

const updatePhone = (state, action) => {
  return updateObject(state, {phone: action.phone});
}

const updateAreaId = (state, action) => {
  return updateObject(state, {areaId: action.areaId});
}

const updateTime = (state, action) => {
  return updateObject(state, {time: action.time});
}

const reducer = ( state = initState, action ) => {
  switch ( action.type ) {
      case actionTypes.SET_TYPE: return setCartType(state, action);
      case actionTypes.SET_MERCHANT: return setMerchant(state, action);
      case actionTypes.UPDATE_MERCHANT: return updateMerchant(state, action);
      case actionTypes.SET_TABLE: return setTable(state, action);
      case actionTypes.CLEAR_CART: return clearCart(state, action);
      case actionTypes.ADD_CART: return addCart(state, action);        
      case actionTypes.DEL_CART: return deleteCart(state, action);
      case actionTypes.UPDATE_CART: return updateCart(state, action);
      case actionTypes.UPDATE_ADDRESS: return updateAddress(state, action);
      case actionTypes.UPDATE_PHONE: return updatePhone(state, action);
      case actionTypes.UPDATE_AREA: return updateAreaId(state, action);
      case actionTypes.UPDATE_QTY_CART: return updateQtyCart(state, action);
      case actionTypes.UPDATE_TIME: return updateTime(state, action);
      default:
  }
  return state;
};

export default reducer;