import React, { Component } from 'react';
import { listmenuApiPath } from '../../../services/networkApi';
import api from '../../../services/network';
import Input from '../../../components/UI/Input/Input';
import Menu from '../../../components/Menu/Menu';
import RoundedBottom from '../../../components/UI/Button/RoundedButton/RoundedButton';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Spinner from '../../../components/UI/Spinner/Spinner';
import Modal from '../../../components/UI/Modal/Modal';
import showNotifications from '../../../components/toast';
import './ListingMenu.css';
import * as cartActions from '../../../store/actions/index';

class ListingMenu extends Component {
  state = {
    filterValue: {
      elementType: 'input-filter',
      elementConfig: {
          type: 'text',
          placeholder: 'What would you like to eat?'
      },
      value: ''
    },
    qty: {
      elementType: 'input',
      elementConfig: {
          type: 'number',
          placeholder: 'Quantity'
      },
      value: '0',
    },
    listingData: [],
    loading: true,
    finish: true,
    updatable: false,
    itemUpdated: {},
  }

  async componentDidMount() {
    if (this.props.merchantId === 'undefined' || this.props.merchantId === '' || this.props.merchantId === null) {
      this.props.history.push('/');
    } else {
      const listingApi = api(listmenuApiPath);
      const payload = {
        merchantId: this.props.merchantId, 
        category:'ALL', 
        preorder: this.props.cartType === 'PREORDER' ? 'true' : 'false', 
        specific: 'true'
      };
      const listingMenu = await listingApi.post(payload);

      const results = listingMenu.map( el => {
        let o = Object.assign({}, el);
        o.qtyOrders = 0;
        return o;
      })

      this.setState({
        listingData: results,
        loading: false,
      });
    }
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      filterValue: {
        ...this.state.filterValue,
        value: value
      }
    });
  }

  filterList = () => {
    const val = this.state.filterValue.value;
    let filteredList = [
      ...this.state.listingData
    ]

    filteredList = filteredList.filter(function(item) {
      return item.name.toLowerCase().indexOf(val.toLowerCase()) > -1;
    })

    return filteredList
  }

  incrementQty = (item) => {

    let carts = [ ...this.props.cart ];

    const cart = carts.filter(items => {
      return items.id === item.id;
    });

    if (cart.length > 0) {
      if (cart[0].stock < cart[0].qty + 1) {
        showNotifications('Maaf, menu ' + cart[0].name + ' telah habis', 'error', 'Listing');
        return;
      }
    }

    if (item.qty === 0) {
      showNotifications('Maaf, stok telah habis', 'error', 'Listing');
      return;
    }

    this.setState({ finish: false });

    if (!this.state.finish) {
      return;
    } else {
      setTimeout(() => {
        let updatedListing = [
          ...this.state.listingData
        ]
    
        const itemList = updatedListing.filter(items => {
          return items.id === item.id && items.category === item.category;
        });
        
        if (itemList.length > 0) {
          const itemCart = {
            id: itemList[0].id,
            name: itemList[0].name,
            price: itemList[0].price,
            oldPrice: itemList[0].oldPrice,
            qty: 1,
            stock: itemList[0].qty,
            subtotal: itemList[0].price,
            category: itemList[0].category,
            remark: '',
            merchantId: itemList[0].merchantId,
            parentId: itemList[0].parentId,
            image: itemList[0].image,
            merchantName: itemList[0].merchantName,
            order: itemList[0].order,
            supplierId: itemList[0].supplierId,
          }
          
          itemList[0].qtyOrders += 1;
          this.setState({
            finish: true,
            ...this.state.listingData,
            ...updatedListing
          }, () => this.props.onStoreCart(itemCart)); 
        }   
      }, 100);
    }
  }

  decrementQty = (item) => {
    this.setState({ finish: false });

    if (!this.state.finish) {
      return;
    } else {
      setTimeout(() => {
        let updatedListing = [
          ...this.state.listingData
        ]
    
        const itemList = updatedListing.filter(items => {
          return items.id === item.id;
        })
    
        if (itemList.length > 0) {
          if(itemList[0].qtyOrders > 0) {
            itemList[0].qtyOrders -= 1;
            const itemCart = {
              id: itemList[0].id,
              name: itemList[0].name,
              price: itemList[0].price,
              oldPrice: itemList[0].oldPrice,
              qty: 1,
              stock: itemList[0].qty,
              subtotal: itemList[0].price,
              category: itemList[0].category,
              remark: '',
              merchantId: itemList[0].merchantId,
              image: itemList[0].image,
              parentId: itemList[0].parentId,
              merchantName: itemList[0].merchantName,
              order: itemList[0].order,
              supplierId: itemList[0].supplierId
            }
            this.setState({
              finish: true,
              ...this.state.listingData,
              ...updatedListing
            }, () => this.props.onRemoveCart(itemCart));
          } else {
            this.setState({ finish: true });
          }
        }
      }, 100);
    }    

    
  }

  qtyChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      qty: {
        ...this.state.qty,
        value: value
      }
    });
  }

  updateCanceledHandler = () => {
    this.setState({
      updatable: false,
      itemUpdated: {},
      qty: {
        ...this.state.qty,
        value: '0'
      },
    });
  }

  updateContinuedHandler = (item) => {
    this.setState({
      updatable: true,
      itemUpdated: item,
    });
  }

  updateQtyHandler = () => {
    if (this.state.qty.value < 0) { return; }

    this.setState({ finish: false });

    if (!this.state.finish) {
      return;
    } else {
      setTimeout(() => {
        let updatedListing = [
          ...this.state.listingData
        ]

        const item = this.state.itemUpdated;
    
        const itemList = updatedListing.filter(items => {
          return items.id === item.id;
        })
    
        if (itemList.length > 0) {
          itemList[0].qtyOrders = this.state.qty.value * 1;
          const itemCart = {
            id: itemList[0].id,
            name: itemList[0].name,
            price: itemList[0].price,
            oldPrice: itemList[0].oldPrice,
            qty: this.state.qty.value * 1,
            stock: itemList[0].qty,
            subtotal: itemList[0].price * this.state.qty.value,
            category: itemList[0].category,
            remark: '',
            merchantId: itemList[0].merchantId,
            image: itemList[0].image,
            parentId: itemList[0].parentId,
            merchantName: itemList[0].merchantName,
            order: itemList[0].order,
            supplierId: itemList[0].supplierId,
          }

          this.setState({
            finish: true,
            updatable: false,
            itemUpdated: {},
            qty: {
              ...this.state.qty,
              value: '0'
            },
            ...this.state.listingData,
            ...updatedListing
          }, () => this.props.onUpdateCart(itemCart));
        }
      }, 800);
    }    
  }  

  render() {
    const list = this.filterList();
    let spin = null;
    let listing = null;
    let buttonCart = null;
    const totalCart = this.props.cart.reduce( (sum, el) => {
      return sum + el.qty;
    }, 0);

    if(totalCart > 0) {
      // if (this.props.creator === 'FOODCOURT') {
      //   buttonCart = 
      //   (
      //     <div className="app-bottom button-cart-foodcourt button-cart-list">
      //       <Link to="/cart">
      //         <RoundedBottom>Continue to Cart ( {totalCart} )</RoundedBottom>
      //       </Link>
      //       <Link to="/orders/foodcourt">
      //         <RoundedBottom bgcolor="green">Keranjang +</RoundedBottom>
      //       </Link>
      //     </div>
      //   )
      // } else {
      //   buttonCart = <div className="button-cart">
      //               <Link to="/cart">
      //                 <RoundedBottom className="button-cart-list" positions="Bottom">Continue to Cart ( {totalCart} )</RoundedBottom>
      //               </Link>
      //             </div>
      // } 
      buttonCart = <div className="app-bottom button-cart-foodcourt button-cart-list">
                    <Link to="/cart">
                      <RoundedBottom width="80%" className="button-cart-list" positions="Bottom">Continue to Cart ( {totalCart} )</RoundedBottom>
                    </Link>
                  </div>
    }

    let filterBox = <Input 
                      elementType={this.state.filterValue.elementType}
                      elementConfig={this.state.filterValue.elementConfig}
                      value={this.state.filterValue.value}
                      isForms='false'
                      changed={(event) => this.inputChangedHandler(event, 'filterValue')} />

    listing = list.map( el => (
      <Menu key={el.id} menuName={el.name} 
        menuPrice={el.price} 
        menuOldPrice={el.oldPrice}
        merchant={el.merchantName} 
        menuImage={el.image} 
        menuQty={el.qtyOrders}
        disabled={!this.state.finish}
        incQty={() => {this.incrementQty(el)}}
        decQty={() => {this.decrementQty(el)}}
        updateQty= {() => {this.updateContinuedHandler(el)}}
        ></Menu>
    ))

    if ( this.state.loading ) {
      spin = <Spinner />;
    }

    if ( !this.state.finish ) {
      spin = <Spinner isOpacity="true" />
    }

    let qtyBox = ( 
      <div className="justify-content-center">
        <h6>Total Quantity</h6>
        <div className="information justify-content-center text-middle">
          Insert total quantity
        </div>
        <Input 
            elementType={this.state.qty.elementType}
            elementConfig={this.state.qty.elementConfig}
            value={this.state.qty.value}
            isForms='false'
            changed={(event) => this.qtyChangedHandler(event)} />
          <div className = "d-flex justify-content-center push-up">
            <RoundedBottom color="black" width="30%" height="auto" className="no-color" clicked={this.updateCanceledHandler}>Cancel</RoundedBottom>
            <RoundedBottom color="white" width="30%" height="auto" clicked={this.updateQtyHandler}>Ok</RoundedBottom>
          </div>
      </div>
    )
    
    return (
      // <div className="container">
      //   {/* <h6>{this.props.merchantName}</h6>
      //   {filterBox}
      //   {listing}
      //   {buttonCart} */}

      //   <div className="listing-list-header">
      //     <h6>{this.props.merchantName}</h6>
      //     {filterBox}
      //   </div>

      //   <div className="listing-list-content">
      //     {listing}
      //   </div>
        
      //   {buttonCart}
      // </div>
      <div>
        <Modal show={this.state.updatable} modalClosed={this.updateCanceledHandler}>
          {qtyBox}
        </Modal>
        <div className="app-fixed-center front">
          {spin}
        </div>
        <div className="listing-list-header">
          <h6 className="push-bottom-md">{this.props.merchantName}</h6>
          {filterBox}
        </div>

        <div className="listing-list-content">
          {listing}
        </div>

        {buttonCart}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      merchantId: state.cart.merchantId,
      merchantName: state.cart.merchantName,
      creator: state.cart.creator,
      cartType: state.cart.cartType
  };
};

const mapDispatchToProps = dispatch => {
  return {
      // onStoreCart: (item) => dispatch({type: actionTypes.ADD_CART, item: item}),
      // onRemoveCart: (item) => dispatch({type: actionTypes.DEL_CART, item: item})
      onUpdateCart: (item) => dispatch(cartActions.updateQtyCart(item)),
      onStoreCart: (item) => dispatch(cartActions.addCart(item)),
      onRemoveCart: (item) => dispatch(cartActions.removeCart(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingMenu);