import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { imageApi } from '../../config';
import StarRatings from 'react-star-ratings';
import imageDefault from '../../assets/images/okgo.png';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import './Menu.css';

const Menu = (props) => {
  let oldPriceDiv = null;
  const addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }

  if (props.menuOldPrice !== 0) {
    oldPriceDiv = (<div className="information">
                      <span className="outer">
                        <span className="inner">
                          <NumberFormat value={props.menuOldPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                        </span>
                      </span>
                    {/* IDR {props.menuPrice/1000} */}
                    {/* <s><NumberFormat value={props.menuOldPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /></s> */}
                  </div>)
  }

  return (
    <Card className="card-no-border box-shadow" tag="a" onClick={props.selectMenu}>
        <Row noGutters={true}>
          {/* <Col xs={4} md={4}>
            <div className="list">
              <img onError={addDefaultSrc} src={imageApi+props.menuImage} alt=""/>
            </div>
          </Col> */}
          <Col xs={6} md={6}>
            <div className="listbody">
              <div className="information-header">{props.menuName}</div>
              <div className="information">{props.merchant}</div>
              <div className="information">
              <StarRatings
                  rating={4.8}
                  starSpacing="0.2vw"
                /> 4.8
              </div>
              {oldPriceDiv}
              <div className="information">
                <NumberFormat value={props.menuPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
              </div>
              <div>
                {/* <button onClick={props.incQty} className="button-order push-right">+</button> {props.menuQty} 
                <button onClick={props.decQty} className="button-order push-left">-</button> */}
                
              </div>
            </div>
          </Col>

          <Col xs={6} md={6}>
            {/* <button onClick={props.decQty} disabled={props.disabled} className="button-order-box push-right"><p className="v-align-center">-</p></button> 
            {props.menuQty} 
            <button onClick={props.incQty} disabled={props.disabled} className="button-order-box push-left"><p className="v-align-center">+</p></button>
            <button onClick={props.updateQty} className="button-edit-qty"><FontAwesomeIcon icon={faEdit} size="1x"/></button>  */}
            <div className='float-end space-margin-r-10'>
              <div className="button-counter-plus-minus space-margin-b-10 bottom-0 start-50 translate-middle-x">
                <button
                  className="dec qty" onClick={props.decQty}> - </button>
                <input
                  className="plus-minus-box"
                  type="text"
                  value={props.menuQty}
                  readOnly />
                <button
                  className="inc qty" onClick={props.incQty}> + </button>
              </div>

              <button onClick={props.updateQty} className="button-edit-qty"><FontAwesomeIcon icon={faEdit} size="1x"/>
              Update Qty
              </button>
            </div>
            
          </Col>
        </Row>            
    </Card>
  )
}

export default Menu;