import React, { Component } from 'react';
import showNotifications from '../../../components/toast';

class LinkAjaPaymentFailed extends Component {
  async componentDidMount() {
    const query = new URLSearchParams(this.props.location.search);
    let trxId = '';
    for (let param of query.entries()) {
      if (param[0] === 'trxId') {
        trxId = param[1];
      }
    } 

    if (trxId !== '') {
      showNotifications('Maaf terjadi kesalahan pada app, mohon hubungi pihak Okgo', 'error', 'Checkout with linkaja'); 
      this.props.history.push('/');
    }
  }

  render() {
    return (
      <div></div>
    )
  }
}

export default LinkAjaPaymentFailed;


