import React from 'react';
import './completePreorder.css';
import completeImage from '../assets/images/thanks.png';

const completePreorder = (props) => {
  return (
    <div className="container">
      <div className="app-center complete-preorder-w">
        <img className="complete-preorder-img" alt="" src={completeImage} ></img>

        <h6>Terima kasih Orderan anda akan diproses dan di antar sesuai Pesanan </h6>
      </div>
    </div>
  )
}

export default completePreorder;
