import React from 'react';
import Home from '../containers/Home/Home';
import Orders from '../containers/Orders/Orders';
import FoodcourtList from '../containers/Orders/FoodcourtList/FoodcourtList';
import ListingMenu from '../containers/Orders/ListingMenu/ListingMenu';
import CartList from '../containers/CartList/CartList';
import Auth from '../containers/Users/Auth/Auth';
import ForgotPassword from '../containers/Users/ForgotPassword/ForgotPassword';
import Register from '../containers/Users/Register/Register';
import Profile from '../containers/Users/Profile/Profile';
import Password from '../containers/Users/Password/Password';
import Payment from '../containers/Payment/Payment';
import Success from '../containers/Orders/Success';
import SuccessPreorder from '../containers/PreOrder/Success';
import MyOrders from '../containers/Orders/OrderList/OrderList';
import MyOrderDetails from '../containers/Orders/OrderList/OrderListDetail/OrderListDetail';
import AboutUs from '../containers/Home/AboutUs/AboutUs';
import ContactUs from '../containers/Home/ContactUs/ContactUs';
import Wallet from '../containers/Payment/Wallet/Wallet';
import Voucher from '../containers/Home/Voucher/Voucher';
import LinkAjaPaymentSuccess from '../containers/Payment/LinkAja/LinkAjaPaymentSuccess';
import LinkAjaPaymentFailed from '../containers/Payment/LinkAja/LinkAjaPaymentFailed';
import ListingPaginatedMenu from '../containers/Orders/ListingPaginatedMenu/ListingPaginatedMenu';
import ComingSoon from '../containers/Home/NewFeatures';
import PreOrder from '../containers/PreOrder/PreOrder';
import PreorderListing from '../containers/PreOrder/PreorderListing/PreorderListing';
import PreorderListingMenu from '../containers/PreOrder/PreorderListingMenu/PreorderListingMenu';
import PreorderListineMenuMerchant from '../containers/PreOrder/PreorderListingMenuMerchant/PreorderListingMenuMerchant';
import PreorderForm from '../containers/PreOrder/PreorderForm/PreorderForm';
import Privacy from '../containers/Home/Privacy/Privacy';
import Sales from '../containers/Orders/Sales/Sales';
import Sample from '../containers/sample';
import PreorderCart from '../containers/PreOrder/PreorderCart/PreorderCart';
import { Route, Switch } from 'react-router-dom';

const Routes = (props) => (
    <Switch>
      <Route path='/' exact component={Home}></Route>
      <Route path='/orders' exact component={Orders}></Route>
      <Route path='/orders/foodcourt' component={FoodcourtList}></Route>
      <Route path='/orders/listing-menu' component={ListingPaginatedMenu}></Route>
      <Route path='/cart' component={CartList}></Route>
      <Route path='/payment' exact component={Payment}></Route>
      <Route path='/payment/linkaja/success' exact component={LinkAjaPaymentSuccess}></Route>
      <Route path='/payment/linkaja/failed' component={LinkAjaPaymentFailed}></Route>

      <Route path='/sales' component={Sales}></Route>

      <Route path="/preorder" exact component={PreOrder}></Route>
      <Route path="/preorder/listing" exact component = {PreorderListing}></Route>
      <Route path="/preorder/forms" component = {PreorderForm}></Route>
      <Route path='/preorder/listing-menu' exact component={PreorderListingMenu}></Route>
      <Route path='/preorder/spesific/listing-menu' component={PreorderListineMenuMerchant}></Route>
      <Route path="/preorder/listing/details" component = {PreorderCart}></Route>
      {/* <Route path="/preorder/listing" render = {() => (<PreorderListing {...props}></PreorderListing>)}></Route> */}

      <Route path='/payment/success' component={Success}></Route>
      <Route path='/payment/success-preorder' component={SuccessPreorder}></Route>
      <Route path='/payment/wallet' component={Wallet}></Route>
      <Route path='/my-orders' exact component={MyOrders}></Route>
      <Route path='/my-orders/details' component={MyOrderDetails}></Route>
      <Route path='/my-voucher' component={Voucher}></Route>
      
      <Route path='/login' component={Auth}></Route>
      <Route path='/forgot-password' component={ForgotPassword}></Route>
      <Route path='/register' component={Register}></Route>
      <Route path='/profile' exact component={Profile}></Route>
      <Route path='/profile/password' component={Password}></Route>
      <Route path='/coming-soon' component={ComingSoon}></Route>

      <Route path='/about-us' component={AboutUs}></Route>
      <Route path='/contact-us' component={ContactUs}></Route>
      <Route path='/privacy-policy' component={Privacy}/>


      <Route path='/sample' component={Sample}></Route>
    </Switch>
)

export default Routes;


