import React from 'react';
import './Foodcourt.css';
import { Card, Row, Col } from 'react-bootstrap';
import imageDefault from '../../assets/images/okgo.png';
import { imageApi } from '../../config';

const Foodcourt = (props) => {
  let status = 'CLOSED';
  let statusClasses = "warning";

  if(props.status) {
    status = 'OPENED'
    statusClasses = "success"
  }

  const addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }

  return (
    <Card className="card-no-border" tag='a' onClick={props.clicked}>
        <Row noGutters={true}>
          <Col xs={4} md={4}>
            <div className="list-foodcourt">
              <img onError={addDefaultSrc} src={imageApi+props.images} alt=""/>
            </div>
          </Col>
          <Col xs={8} md={8}>
            <div className="listbody">
              <div className="information">{props.foodcourtName}</div>
              <div className={statusClasses}>{status}</div>
            </div>
          </Col>
        </Row>            
    </Card>
  )
}

export default Foodcourt;