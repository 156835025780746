import React from 'react';
import './Order.css';
import { Card, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import RoundedBottom from '../../components/UI/Button/RoundedButton/RoundedButton';
import imageDefault from '../../assets/images/order-complete.png';

const Order = (props) => {

  return (
    <Card className="card-no-border" tag='a' onClick={props.clicked}>
        <Row noGutters={true}>
          <Col xs={4} md={4}>
            <div className="list">
              <img src={imageDefault} alt=""/>
            </div>
          </Col>
          <Col xs={8} md={8}>
            <div className="listbody">
              <div className="information-header">{props.orderInvoice}</div>
              <div className="information">{props.merchant}</div>
              <div className="information">{moment(props.orderDate).format('MMMM DD YYYY, h:mm')}</div>
              <div className="information push-bottom">
                <RoundedBottom bgcolor="#822512" color="white" width="70%" height="auto">{props.status}</RoundedBottom>
              </div>
            </div>
          </Col>
        </Row>            
    </Card>
  )
}

export default Order;