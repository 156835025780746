import React, {Component} from 'react';
import Input from '../../../components/UI/Input/Input';
import RoundedButton from '../../../components/UI/Button/RoundedButton/RoundedButton';
import { checkValidity } from '../../../utils/utils';
import { updateObject } from '../../../utils/utils';
import showNotifications from '../../../components/toast';
import { connect } from 'react-redux';
import api from '../../../services/network';
import { profileApiPath } from '../../../services/networkApi';
import * as actions from '../../../store/actions/index';

class PreorderForm extends Component {
  state = {
    controls: [
      {
        id: 'fullname',
        name: 'Full Name',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Fullname',
            disabled: true
        },
        value: '',
        validation: {
            required: true
        },
        valid: true,
        touched: false
      },
      {
        id: 'email',
        name: 'Email',
        elementType: 'input',
        elementConfig: {
            type: 'email',
            placeholder: 'Email',
            disabled: true
        },
        value: '',
        validation: {
            required: true,
            isEmail: true
        },
        valid: true,
        touched: false
      },
      {
        id: 'phone',
        name: 'Telephone',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Phone',
            disabled: true
        },
        value: '',
        validation: {
            required: true,
            minLength: 9,
            maxLength: 20,
            isNumeric: true
        },
        valid: true,
        touched: false
      },
      {
        id: 'city',
        name: 'Kota',
        elementType: 'input',
        elementConfig: {
            type: 'text',
            placeholder: 'Kota',
            disabled: true
        },
        value: '',
        validation: {
            required: true,
        },
        valid: true,
        touched: false
      },
      {
        id: 'address',
        name: 'Alamat',
        elementType: 'text-area',
        elementConfig: {
          type: 'text',
          placeholder: 'Add your address here'
        },
        value: '',
        validation: {
          required: true
        },
        valid: true,
        touched: false
      },
    ],
    formIsValid: true,
  }

  async componentDidMount() {
    if (this.props.cartType !== 'PREORDER') {
      this.props.history.push('/');
    }

    const profileApi = api(profileApiPath);
    const profileRes = await profileApi.getWithParams(this.props.email);
    const { errors } = profileRes;
    if (errors) {
      if(errors[0].message !== '') {
        showNotifications(errors[0].message, 'error', 'Show Profile');
      } else if(profileRes.phone === null || profileRes.phone === '') {
        showNotifications('Please enter phone number information', 'error', 'Update Profile');
        this.props.history.push('/profile');
      } else {
        const updatedControls = [ ...this.state.controls ];
        updatedControls[0].value = profileRes.fullname;
        updatedControls[0].valid = true;
        updatedControls[0].touched = false;

        updatedControls[1].value = profileRes.email;
        updatedControls[1].valid = true;
        updatedControls[1].touched = false;

        updatedControls[2].value = (profileRes.phone === null) ? '' : profileRes.phone;
        updatedControls[2].valid = true;
        updatedControls[2].touched = false;

        updatedControls[3].value = (this.props.area === null) ? '' : this.props.area;
        updatedControls[3].valid = true;
        updatedControls[3].touched = false;

        updatedControls[4].value = (this.props.address === null) ? '' : this.props.address;
        updatedControls[4].valid = true;
        updatedControls[4].touched = false;

        this.setState( { controls: updatedControls, isSosmed: profileRes.sosmed } );
      }
    }
  }

  inputChangedHandler = ( event, controlName ) => {
    let updatedControls = [...this.state.controls];
    let index = updatedControls.findIndex(x => x.id === controlName);

    const updatedControlslements = { 
        ...updatedControls[index]
    };

    updatedControlslements.value = event.target.value;
    updatedControlslements.valid = checkValidity( event.target.value, updatedControlslements.validation );
    updatedControlslements.touched = true;

    updatedControls[index] = updateObject(updatedControls[index], updatedControlslements);

    let formIsValid = true;

    updatedControls.map( formElement => (
      formIsValid = formElement.valid && formIsValid
    ))

    this.setState( { controls: updatedControls, formIsValid: formIsValid } );
  }

  gotoPayment = async() => {
    if (!this.state.formIsValid) {
      showNotifications("Some data is not valid, please enter valid information", 'error', 'Preorder');
    } else {
      this.props.onUpdateAddress(this.state.controls[4].value);
      this.props.onUpdatePhone(this.state.controls[2].value);
      this.props.history.push('/payment');
    }
  }

  render() {
    const formElementsArray = this.state.controls;
    let form = formElementsArray.map( formElement => (
      <div className="form-group information" key={formElement.id}>
        <label className="information no-margin">{formElement.name}</label>
        <Input
          className="information"
          key={formElement.id}
          elementType={formElement.elementType}
          elementConfig={formElement.elementConfig}
          value={formElement.value}
          invalid={!formElement.valid}
          shouldValidate={formElement.validation}
          touched={formElement.touched}
          changed={( event ) => this.inputChangedHandler( event, formElement.id )} 
          isForms='true'
        />
      </div>
      
    ));
    return(
      <div className="container">
        <h6 className="push-bottom-lg">Enter your information</h6>
        <div>
          {form}

          <div className="information-small">
            Kurir by Raja Express (tarif Rp10.000 max 10 km, kelebihan jarak Rp1.000/km)
          </div>
          
          <div className="auth-padding">
            <RoundedButton width="100%" clicked={this.gotoPayment}>Submit</RoundedButton>
          </div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = dispatch => {
  return {
      onUpdateAddress: (address) => dispatch(actions.updateAddress(address)),
      onUpdatePhone: (phone) => dispatch(actions.updatePhone(phone)),
  };
};

const mapStateToProps = (state) => {
  return {
    address: state.cart.address,
    email: state.user.email,
    area: state.cart.areaId,
    cartType: state.cart.cartType
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (PreorderForm);