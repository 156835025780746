import React, { Component } from 'react';
import { Row, Col, Card, CardImg, CardBody, CardTitle, CardText} from 'reactstrap';
import cardImg08 from "../assets/images/08.jpg";
import SuccessAnimation from '../components/UI/Animation/Success/SuccessAnimation';
import CheckoutBar from '../components/Cart/CheckoutBar/CheckoutBar';
import './sample.css';

class sample extends Component {
  render() {
    return (
      // <React.Fragment>
      //   <input type="submit" value="submit" className="button" />
      //   <SuccessAnimation></SuccessAnimation>
      // </React.Fragment>

      <React.Fragment>
        <div className="carding text-middle padding-bottom-checkout">
          <Row>
            <Col>
              <Card className="card-no-border">
                <CardImg top width="100%" src={cardImg08} alt="Card cap" />
                <CardBody>
                  <div className="information-header push-bottom-md">
                    <CardTitle>Kwetiao Maknyuss</CardTitle>
                  </div>

                  <div className="information">
                    <CardText>
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                        Some quick example text to build on the card title and make up the bulk of the card's content.
                    </CardText>
                  </div>
                    {/* <Button className="btn btn-danger">Button</Button> */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

        <CheckoutBar backToList={this.backToListHandler} payContinued={this.payContinuedHandler} price='0' creator={this.props.creator}></CheckoutBar>
        
      </React.Fragment>
      
    )
  }
}

export default sample;