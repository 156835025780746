import React from 'react';
import PropTypes from 'prop-types';
import Menu from '../Menu/Menu';

const ListMenu = (props) => {
  return props.currentItems ? 
    props.currentItems.map((el, index) => {
      return (
        <Menu key={el.id} menuName={el.name} 
          menuPrice={el.price} 
          menuOldPrice={el.oldPrice}
          merchant={el.merchantName} 
          menuImage={el.image} 
          menuQty={el.qtyOrders}
          incQty={() => {props.incMenu(el)}}
          decQty={() => {props.decMenu(el)}}
          updateQty= {() => {props.updateMenuQty(el)}}
        ></Menu>
      )
    }) : null;
}

ListMenu.propTypes = {
  currentItems: PropTypes.array,
  incMenu: PropTypes.func,
  decMenu: PropTypes.func,
  updateMenuQty: PropTypes.func
}

export default ListMenu;