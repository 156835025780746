import React, { Component } from 'react';
import { preOrderListingMerchantApiPath, merchantSubCategoryInfoApiPath } from '../../../services/networkApi';
import classnames from "classnames";
import api from '../../../services/network';
import Input from '../../../components/UI/Input/Input';
import { imageApi } from '../../../config';
import RoundedBottom from '../../../components/UI/Button/RoundedButton/RoundedButton';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import imageDefault from '../../../assets/images/okgo.png';
import Spinner from '../../../components/UI/Spinner/Spinner';
import * as cartActions from '../../../store/actions/index';
import moment from 'moment';
import showNotifications from '../../../components/toast';
import { daysOfNumber } from '../../../utils/utils';
import { groupBy } from 'underscore';
import NumberFormat from 'react-number-format';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faMinusCircle, faShareAlt } from '@fortawesome/free-solid-svg-icons';
import { filterListByCategory } from '../../../utils/utils';
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Row,
  Col
} from "reactstrap";

class PreorderListingMenu extends Component {
  state = {
    filterValue: {
      elementType: 'input-filter',
      elementConfig: {
          type: 'text',
          placeholder: 'What would you like to eat?'
      },
      value: ''
    },
    listingData: [],
    loading: true,
    finish: true,
    merchantSubCategory: [],
    activeFilter: 0
  }

  async componentDidMount() {
    if (this.props.cartType !== 'PREORDER') {
      this.props.history.push('/');
    }

    if (this.props.merchantId === 'undefined' || this.props.merchantId === '' || this.props.merchantId === null) {
      this.props.history.push('/');
    } else {
      let results;
      const listingApi = api(preOrderListingMerchantApiPath);
      const payload = {
        merchantId: this.props.merchantId, 
        category:'ALL', 
        specific: 'true'
      };
      const listingMenu = await listingApi.post(payload);

      results = listingMenu.map( el => {
        let o = Object.assign({}, el);
        const now = moment(new Date());
        let lastOrders = 'Closed';
        let lastOrderDate = '';
        let deliveryDate = null;
        let detailOrders = el.listingPreorders[0].listingPreorderDetails;
      

        if (Object.keys(el.merchantSpecific).length !== 0) {
          o.deliveryFee = el.merchantSpecific.deliveryFee;
        } else {
          o.deliveryFee = 0;
        }

        if (detailOrders !== undefined) {
          if (detailOrders.length > 0) {
            deliveryDate = detailOrders[0].deliveryDate !== null ? moment(detailOrders[0].deliveryDate).format("DD-MM-YYYY") : null;
            if (detailOrders[0].endPODate !== null) {
              let endPODate = moment(detailOrders[0].endPODate);
              
              let duration = moment.duration(endPODate.diff(now));
              let days = Math.floor(duration.asDays());
              let hours = Math.floor((duration.asHours() % 24));
              let minutes = Math.floor((duration.asMinutes() % 60));
              if (days >= 0) {
                lastOrderDate = moment(endPODate).format("DD-MM-YYYY");
                lastOrders = days + ' hari : ' + hours + ' Jam : ' + minutes + ' Menit';
              }
            } else if (detailOrders[0].days !== null) {
              let arrayDays = [];
              let daysNowofNumber = daysOfNumber(now.format('dddd'));
  
              let upperDaysList = detailOrders.filter(function(item) {
                return daysOfNumber(item.days) > daysNowofNumber;
              })
  
              let underDaysList = detailOrders.filter(function(item) {
                return daysOfNumber(item.days) <= daysNowofNumber;
              })
  
              upperDaysList = upperDaysList.sort((a, b) => {
                return daysOfNumber(a.days) - daysOfNumber(b.days)
              })
  
              underDaysList = underDaysList.sort((a, b) => {
                return daysOfNumber(a.days) - daysOfNumber(b.days)
              })
  
              arrayDays = [
                ...upperDaysList,
                ...underDaysList
              ]
  
              for (let arrData in arrayDays) {
                let daysPO = daysOfNumber(arrayDays[arrData].days);
                if (daysPO - 1 === 0) {
                  daysPO = 7;
                } else {
                  daysPO = daysPO - 1;
                }

                let selisih = daysPO - daysNowofNumber;
                let new_date = moment(now, "DD-MM-YYYY").add(selisih, 'days');
                deliveryDate = new_date.add(1, 'days').format('DD-MM-YYYY');
                new_date.set({hour:12,minute:0,second:0,millisecond:0})
                let duration = moment.duration(new_date.diff(now));
                let days = Math.floor(duration.asDays());
                let hours = Math.floor((duration.asHours() % 24));
                let minutes = Math.floor((duration.asMinutes() % 60));
                if (days >= 0) {
                  lastOrderDate = moment(new_date).format("DD-MM-YYYY")
                  lastOrders = days + ' hari : ' + hours + ' Jam : ' + minutes + ' Menit';
                  break;
                }
              }
            }
          }
        }
        o.lastOrder = lastOrders;
        o.lastOrderDate = lastOrderDate
        o.qtyOrders = 0;
        o.parentId = o.merchantId;
        o.merchantId = o.merchantSpecificId;
        o.deliveryDate = deliveryDate
        return o;
      })

      const merchantSubCategoryApi = api(merchantSubCategoryInfoApiPath);
      const payloadSubCategory = {
        merchantId: this.props.merchantId
      }
      const merchantSubCategory = await merchantSubCategoryApi.post(payloadSubCategory);

      let merchantSubCategoryData = [
        {
          id: 0,
          name: 'ALL'
        }
      ]

      for (let obj in merchantSubCategory) {
        let object = {
          id: merchantSubCategory[obj].id,
          name: merchantSubCategory[obj].name
        }
        merchantSubCategoryData.push(object)
      }

      this.setState({
        listingData: results,
        loading: false,
        merchantSubCategory: merchantSubCategoryData
      });
    }
  }

  inputChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      filterValue: {
        ...this.state.filterValue,
        value: value
      }
    });
  }

  addDefaultSrc = (ev) => {
    ev.target.src = imageDefault;
  }

  incrementQty = (item) => {
    let carts = [ ...this.props.cart ];

    const cart = carts.filter(items => {
      return items.id === item.id;
    });

    if (cart.length > 0) {
      if (cart[0].stock < cart[0].qty + 1) {
        showNotifications('Maaf, menu ' + cart[0].name + ' telah habis', 'error', 'Listing');
        return;
      }
    }

    if (item.qty === 0) {
      showNotifications('Maaf, menu telah habis', 'error', 'Listing');
      return;
    }

    this.setState({ finish: false });

    if (!this.state.finish) {
      return;
    } else {
      setTimeout(() => {
        let updatedListing = [
          ...this.state.listingData
        ]
    
        const itemList = updatedListing.filter(items => {
          return items.id === item.id && items.category === item.category;
        });
        
        if (itemList.length > 0) {
          const itemCart = {
            id: itemList[0].id,
            name: itemList[0].name,
            price: itemList[0].price,
            oldPrice: itemList[0].oldPrice,
            qty: 1,
            stock: itemList[0].qty,
            subtotal: itemList[0].price,
            category: itemList[0].category,
            remark: '',
            merchantId: itemList[0].merchantId,
            parentId: itemList[0].parentId,
            image: itemList[0].image,
            merchantName: itemList[0].merchantName,
            order: itemList[0].order,
            deliveryFee: itemList[0].deliveryFee,
            deliveryDate: itemList[0].deliveryDate,
            supplierId: itemList[0].supplierId,
          }
          
          itemList[0].qtyOrders += 1;
          this.setState({
            finish: true,
            ...this.state.listingData,
            ...updatedListing
          }, () => this.props.onStoreCart(itemCart)); 
        }   
      }, 800);
    }
  }

  decrementQty = (item) => {
    this.setState({ finish: false });

    if (!this.state.finish) {
      return;
    } else {
      setTimeout(() => {
        let updatedListing = [
          ...this.state.listingData
        ]
    
        const itemList = updatedListing.filter(items => {
          return items.id === item.id;
        })
    
        if (itemList.length > 0) {
          if(itemList[0].qtyOrders > 0) {
            itemList[0].qtyOrders -= 1;
            const itemCart = {
              id: itemList[0].id,
              name: itemList[0].name,
              price: itemList[0].price,
              oldPrice: itemList[0].oldPrice,
              qty: 1,
              stock: itemList[0].qty,
              subtotal: itemList[0].price,
              category: itemList[0].category,
              remark: '',
              merchantId: itemList[0].merchantId,
              image: itemList[0].image,
              parentId: itemList[0].parentId,
              merchantName: itemList[0].merchantName,
              order: itemList[0].order,
              deliveryFee: itemList[0].deliveryFee,
              deliveryDate: itemList[0].deliveryDate,
              supplierId: itemList[0].supplierId,
            }
            this.setState({
              ...this.state.listingData,
              ...updatedListing
            }, () => this.props.onRemoveCart(itemCart));
          }
        } 
        this.setState({ finish: true });
      }, 800);
    }    
  }

  toggle = (index) => {
    this.setState({
      activeFilter: index,
    })
  }

  sharePreorder = async(el) => {
    if (navigator.share) {
      let shareData = {
        title: el.merchantName,
        text: el.name,
        url: 'https://okgo.co.id/preorder/listing/details?listingId=' + el.id,
      }
    
      if (this.props.cartType === 'ORDER') {
        shareData = {
          title: el.merchantName,
          text: el.name,
          url: 'https://okgo.co.id/orders/listing-menu/details?listingId=' + el.id,
        }
      } 
      await navigator.share(shareData)
    } else {
      console.log('failed')
    }
  }

  render() {
    let spin = null;
    let categoryBar = null;
    let buttonCart = null;
    let content = [];
    let list = [];
    let rowsId = '';
    let rowCount = 0;
    let priceDiv = null;

    const totalCart = this.props.cart.reduce( (sum, el) => {
      return sum + el.qty;
    }, 0);

    if(totalCart > 0) {
      buttonCart = <div className="app-bottom button-cart-foodcourt button-cart-list">
                    <Link to="/cart">
                      <RoundedBottom width="70%" className="button-cart-list" positions="Bottom">Continue to Cart ( {totalCart} )</RoundedBottom>
                    </Link>
                  </div>
    }

    let filterBox = <Input 
                      elementType={this.state.filterValue.elementType}
                      elementConfig={this.state.filterValue.elementConfig}
                      value={this.state.filterValue.value}
                      isForms='false'
                      changed={(event) => this.inputChangedHandler(event, 'filterValue')} />

    filterListByCategory(this.state.listingData, this.state.filterValue.value, this.state.activeFilter).map((el, index) => {
      let oldPrice = el.oldPrice !== null ? el.oldPrice : 0;
      let periodePo = 'Periode Order : - ';
      let countEndPo = 'Closed';
      let deliveryDate = 'Tanggal Antar : - ';

      if (el.lastOrder !== 'Closed') {
        periodePo = 'Periode Order : ' + el.lastOrderDate
        countEndPo = 'PO akah berakhir dalam (' + el.lastOrder + ')'
        deliveryDate = 'Tanggal Antar : ' + el.deliveryDate;
      }
      
      if (oldPrice === 0) {
        priceDiv = (
          <div className="float-right">
            <NumberFormat value={el.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
          </div>
        )
      } else {
        priceDiv = (<div className="float-right">
                    <div>
                      <span className="outer">
                        <span className="inner">
                          <NumberFormat value={el.oldPrice} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} />
                        </span>
                      </span>
                    </div>
                    <div><NumberFormat value={el.price} displayType={'text'} thousandSeparator={true} prefix={'Rp. '} /></div>
                  </div>)
      }

      content.push(
        <Col sm="6" key={el.id}>
          <Card className="text-left margin-upper">
            <CardBody>
              <div className="information-small"><CardTitle><b><i>{el.merchantSpecific.name}</i></b></CardTitle></div>
            </CardBody>
            <img onError={this.addDefaultSrc} className="img-order" width="100%" src={imageApi + el.image} alt=""/>
            <CardBody>
              <div className="information push-bottom-padd">
                <div className="float-left order-description">
                  <b>{el.name}</b>
                  <div className="information-small">{el.detail}</div>
                </div>
                {priceDiv}
              </div>
            </CardBody>
              
            <CardFooter>
              <div className="information-small">
                <div> {periodePo} </div>
                <div> {deliveryDate} </div>
                <div> {countEndPo}</div>
                
                <div className="float-left" tag="a" onClick={() => {this.sharePreorder(el)}}>
                  <FontAwesomeIcon icon={faShareAlt} size="2x" /> 
                </div>

                <div className="push-left-md float-right" tag="a" onClick={() => {this.incrementQty(el)}}>
                  <FontAwesomeIcon icon={faPlusCircle} size="2x" /> 
                </div>
                <div className="push-left float-right information">{el.qtyOrders}</div>
                <div className="push-right float-right" tag="a" onClick={() => {this.decrementQty(el)}}>
                  {el.likes} <FontAwesomeIcon icon={faMinusCircle} size="2x"/> 
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      );
      
      if (index % 2 !== 0) {
        rowCount += 1;
        rowsId = 'row' + (rowCount);
        list.push(
          <Row key={rowsId}>
            {content}
          </Row>
        )
        content = [];
      }
      return index;
    });

    if (content.length > 0) {
      rowCount += 1;
      rowsId = 'row' + (rowCount);
      list.push(
        <Row key={rowsId}>
          {content}
        </Row>
      )
    }

    if ( this.state.loading ) {
      spin = <Spinner />;
    }

    if ( !this.state.finish ) {
      spin = <Spinner isOpacity="true" />
    }
    
    if (this.state.merchantSubCategory.length > 0) {
      let detailCategory = this.state.merchantSubCategory.map((el, idx) => {
        return (       
          <div tag="a" onClick={() => {this.toggle(el.id)}} key={el.id} className={classnames({ activeCategory: this.state.activeFilter === el.id })}>{el.name}</div>
        )
      })

      categoryBar = (
        <div className="scrolling-filter information-small push-up">
          {detailCategory}
        </div>
      )
    }
    
    return (
      <div>
        <div className="app-fixed-center front">
          {spin}
        </div>
        <div className="listing-list-header">
          <h6 className="push-bottom-md">{this.props.merchantName}</h6>
          {filterBox}
          {categoryBar}
        </div>

        <div className="listing-list-content">
          { list }
        </div>

        {buttonCart}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      merchantId: state.cart.merchantId,
      merchantName: state.cart.merchantName,
      creator: state.cart.creator,
      cartType: state.cart.cartType
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onStoreCart: (item) => dispatch(cartActions.addCart(item)),
      onRemoveCart: (item) => dispatch(cartActions.removeCart(item)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreorderListingMenu);