// FOR USER
export const AUTHENTICATION_START = 'AUTHENTICATION_START';
export const AUTHENTICATION_FAIL = 'AUTHENTICATION_FAIL';
export const SET_AUTHENTICATION = 'SET_AUTHENTICATION';
export const REMOVE_AUTHENTICATION = 'REMOVE AUTHENTICATION';
export const SWITCH_SOSMED = 'SWITCH_SOSMED';

// FOR CART
export const SET_TABLE = 'SET_TABLE';
export const SET_MERCHANT = 'SET_MERCHANT';
export const UPDATE_MERCHANT = 'UPDATE_MERCHANT';
export const CLEAR_CART = 'CLEAR_CART';
export const ADD_CART = 'ADD_CART';
export const DEL_CART = 'DEL_CART';
export const UPDATE_QTY_CART = 'UPDATE_QTY_CART';
export const UPDATE_CART = 'UPDATE_CART';
export const SET_TYPE = 'SET_TYPE';
export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_PHONE = 'UPDATE_PHONE';
export const UPDATE_AREA = 'UPDATE_AREA';
export const UPDATE_TIME = 'UPDATE_TIME';