import  React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import './ContactUs.css';

class ContactUs extends Component {
  render() {
    return (
      <div className="app-center">
        <div className="section-contact-text">
          <h2 className="contact-header">Contact Us</h2> <br></br>
          <p>okgospace@gmail.com</p>
          <br></br>
          <p>Office Address:</p>
          <p>PT. Bar Code Indonesia</p>
          <p>Komplek Cemara Asri</p>
          <p>Medan</p>
          <p>North Sumatera</p>
        </div>	
        <div className="section-contact">
          <ul className="d-flex justify-content-center section-icons">
            <li className="list-brands">
              <a href="https://www.facebook.com/okgo.okgo.140"><FontAwesomeIcon icon={faFacebook} size="2x"/></a>
            </li>
            
            <li className="list-brands">
              <a href="https://www.instagram.com/okgospace"> <FontAwesomeIcon icon={faInstagram} size="2x"/></a>
            </li>

            <li className="list-brands">
              <a href="mailto:okgospace@gmail.com">
                <FontAwesomeIcon icon={faEnvelope} size="2x"/>
              </a>
            </li>
          </ul>

          <div className="section-contact-text">
            <p>&copy;    2020 OKGO! - PT Bar Code Indonesia</p>
          </div>	
        </div>
      </div>
    )
  }
}

export default ContactUs;