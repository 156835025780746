import React, { Component } from 'react';
import Modal from '../../components/UI/Modal/Modal';
import Input from '../../components/UI/Input/Input';
import Spinner from '../../components/UI/Spinner/Spinner';
import {connect} from 'react-redux';
import api from '../../services/network';
import * as Actions from '../../store/actions/index';
import { orderApiPath, orderCheckoutPath, linkAjaTokenApi, linkAjaCheckoutApi, merchantListFeeInfoPath, preOrderCheckoutPath } from '../../services/networkApi';
import RoundedBottom from '../../components/UI/Button/RoundedButton/RoundedButton';
import showNotifications from '../../components/toast';
import imageLinkAja from '../../assets/images/linkaja.png';
import moment from 'moment';
import { groupBy, size } from 'underscore';
import { isBlockedPaymentCashless, getTotalDeliveryFee } from '../../utils/utils';
import './Payment.css';

class Payment extends Component {
  state = {
    pinCode: {
      elementType: 'input',
      elementConfig: {
          type: 'password',
          placeholder: 'Password'
      },
      value: '',
    },
    purchasable: false,
    creator: '',
    pin: '',
    loading: false,
    feeInfo: [],
    merchantFee: {
      totalTax: 0,
      totalServiceTax: 0,
      totalPrice: 0,
      totalDelivery: 0
    },
  }

  async componentDidMount() {
    if (this.props.isLogin !== true || this.props.cart.length === 0) {
      this.props.history.push('/');
    }
    
    if (this.props.cartType === "ORDER") {
      const totalParentCart = groupBy(this.props.cart, (obj) => {
        return obj.parentId;
      }); 

      if (this.props.merchantId === 'undefined' || this.props.merchantId === '' || this.props.merchantId === null || size(totalParentCart) > 1) {
        this.props.history.push('/');
      } else {
        const merchantApi = api(orderApiPath);
        let payload = {
          merchantId: this.props.merchantId
        }
        const merchantRes = await merchantApi.post(payload);
        if (merchantRes) { 
          this.setState({ creator: merchantRes.creator, pin: merchantRes.pinCode })
        }
      }
    }

    const merchantFeeApi = api(merchantListFeeInfoPath)
    let payload = {
      cart: this.props.cart
    }

    const merchantFeeRes = await merchantFeeApi.post(payload)
    if (merchantFeeRes) {
      const { errors } = merchantFeeRes;
      if (errors) {
        showNotifications(errors[0].message, 'error', 'Gagal memuat informasi merchant, silakan hubungi pihak okgo');
      } else {
        this.setState({
          feeInfo: merchantFeeRes
        })
      }
    }

    this.calculateTax();
  }

  calculateTax() {
    let totalTax = 0;
		let totalServiceTax = 0;

    const totalPrice = this.props.cart.reduce( (sum, el) => {
      return sum + (el.qty * el.price)
    }, 0)

    let totalDeliveryFee = getTotalDeliveryFee(this.props.cart);

    for (let data of this.props.cart) {
      let taxMerchant = 0;
      let serviceTaxMerchant = 0;
      let totalTransPerListing = data.qty * data.price;
      let merchantInfo = this.state.feeInfo.filter(function(item) {
        return data.merchantId.toString() === item.merchantId.toString();
      })

      if (merchantInfo || merchantInfo.length !== 0) {
        taxMerchant = (totalTransPerListing * parseInt(merchantInfo[0].tax)) / 100;
				serviceTaxMerchant = (totalTransPerListing * parseInt(merchantInfo[0].serviceTax)) / 100;
      }

      totalTax += taxMerchant;
			totalServiceTax += serviceTaxMerchant;
    }

    
    this.setState({ merchantFee: {
      totalTax: totalTax,
      totalServiceTax: totalServiceTax,
      totalPrice: totalPrice,
      totalDelivery: totalDeliveryFee
    }})
  }

  pinChangedHandler = (event) => {
    const value = event.target.value;
    this.setState({
      pinCode: {
        ...this.state.pinCode,
        value: value
      }
    });
  }

  payCanceledHandler = () => {
    this.setState({
      purchasable: false,
    });
  }

  payContinuedHandler = () => {
    if (this.state.creator !== 'FOODCOURT' && (this.state.pin !== '' && this.state.pin !== null)) {
      this.setState({
        purchasable: true,
      });
    } else {
      this.startPaymentHandler('CASHIER')
    }
  }

  payWalletContinueHandler = () => {
    let blockPaymentCashless = isBlockedPaymentCashless(this.props.cart);
    if (blockPaymentCashless.found === true) {
      const errorMessage = 'Hanya pembayaran melalui kasir yang dapat dipakai untuk merchant ' + blockPaymentCashless.merchant;
      showNotifications(errorMessage, 'error', 'Payment Failed');
      return;
    } 

    this.props.history.push('/payment/wallet');
  }

  startPaymentHandler = async(payType) => {
    // if (this.state.loading !== true) {
    //   this.setState({loading: true});
    //   let valid = false;
    //   let orderSuccess = false;
    //   let invoice = '';
    //   const totalPrice = this.props.cart.reduce( (sum, el) => {
    //     return sum + (el.qty * el.price)
    //   }, 0)

    //   if (this.state.creator !== 'FOODCOURT' && payType === 'CASHIER' && this.props.cartType !== "PREORDER") {
    //     if (this.state.pinCode.value === this.state.pin.toString()) {
    //       valid = true;
    //     } else {
    //       showNotifications('Pincode not match', 'error', 'Pin Code');
    //     }
    //   } else {
    //     valid = true;
    //   }

    //   if (valid) {
    //     const checkoutApi = api(orderCheckoutPath);
    //     let carts = JSON.stringify(this.props.cart);
    //     let preorderObj = '{}';

    //     if (this.props.cartType === 'PREORDER') {
    //       let obj = {
    //         type: 3,
    //         date: moment().format('YYYY-MM-DD'),
    //         people: 1,
    //         address: this.props.address
    //       }
    //       preorderObj = JSON.stringify(obj)
    //     }

    //     let payload = {
    //       order: carts.toString(),
    //       preorder: preorderObj,
    //       promo: '{}',
    //       payment: payType,
    //       tableNo: this.props.tableNo === null ? '' : this.props.tableNo,
    //       token: this.props.token
    //     }
    //     const checkoutRes = await checkoutApi.post(payload);
    //     if (checkoutRes) { 
    //       const { errors } = checkoutRes;
    //       if (errors) {
    //         if(errors[0].message !== '') {
    //           orderSuccess = false;
    //           showNotifications(errors[0].message, 'error', 'Checkout error');
    //         } else {
    //           invoice = checkoutRes.invoice;
    //           orderSuccess = true;
    //         }
    //       } 
    //     }

    //     if (orderSuccess === true && payType === 'CASHIER') {
    //       showNotifications('Terima kasih telah menggunakan OkGo', 'success', 'Checkout');
    //       this.props.onRefreshCart();
    //       this.props.history.push('/payment/success');
    //     } else if (orderSuccess === true && payType === 'LINKAJA') {
    //       const dateMs = Date.now() / 1000;
    //       const trxId = invoice + '-' + dateMs.toString();
    //       // const trxId = invoice;

    //       const linkAjaApi = api(linkAjaTokenApi);
    //       let payload = {
    //         trxId: trxId,
    //         total: totalPrice.toString()
    //       }
    //       const linkAjaApiRes = await linkAjaApi.post(payload);
    //       const { errors } = linkAjaApiRes;
    //       if (linkAjaApiRes) {
    //         if (errors) {
    //           showNotifications('Maaf terjadi kesalahan pada app, mohon hubungi pihak Okgo', 'error', 'Change Password');
    //         } else {
    //           const { pgpToken, refNum } = linkAjaApiRes;
    //           if (refNum !== null || refNum !== '') {
    //             var form = document.createElement("form");
    //             form.setAttribute("method", "post");
    //             form.setAttribute("action", linkAjaCheckoutApi);

    //             var hiddenField = document.createElement("input"); 
    //             hiddenField.setAttribute("type", "hidden");
    //             hiddenField.setAttribute("name", "Message");
    //             hiddenField.setAttribute("value", pgpToken);
    //             form.appendChild(hiddenField);
    //             document.body.appendChild(form);

    //             form.submit();
    //             this.props.onRefreshCart();
    //           }
    //         } 
    //       }
    //     } else if(orderSuccess === true && payType === 'OVO') {
    //       showNotifications('Terima kasih telah menggunakan OkGo, Check status pembayaran anda di menu My-Order', 'success', 'Checkout');
    //       this.props.onRefreshCart();
    //       // this.props.history.push('/');

    //       const queryParams = [];
    //       queryParams.push('payvia=ovo');
    //       const queryString = queryParams.join('&');
    //       this.props.history.push({
    //         pathname: this.props.match.path + '/success',
    //         search: '?' + queryString
    //       });
    //     }
    //   }
    //   this.setState({loading: false});
    // }

    if (this.state.loading !== true) {
      this.setState({loading: true});
      let valid = false;
      let orderSuccess = false;
      let invoice = '';
      let isPreorder = false;
      let baseApiCheckout = '';
      let totalPrice = this.props.cartType === 'PREORDER' ? this.state.merchantFee.totalPrice + this.state.merchantFee.totalTax + this.state.merchantFee.totalDelivery : 
                        this.state.merchantFee.totalPrice + this.state.merchantFee.totalTax + this.state.merchantFee.totalServiceTax
      let blockPaymentCashless = isBlockedPaymentCashless(this.props.cart);

      if ((payType === 'OVO' || payType === 'LINKAJA') && blockPaymentCashless.found === true) {
        const errorMessage = 'Hanya pembayaran melalui kasir yang dapat dipakai untuk merchant ' + blockPaymentCashless.merchant;
        showNotifications(errorMessage, 'error', 'Payment Failed');
        this.setState({loading: false});
        return;
      } 
      

      if (this.state.creator !== 'FOODCOURT' && payType === 'CASHIER' && (this.props.cartType !== "PREORDER" || this.props.cartType !== 'DRIVETHRU')) {
        if (this.state.pin !== null && this.state.pin !== '') {
          if (this.state.pinCode.value === this.state.pin.toString()) {
            valid = true;
          } else {
            showNotifications('Pincode not match', 'error', 'Pin Code');
          }
        } else {
          valid = true
        }
      } else {
        valid = true;
      }

      if (valid) {
        switch(this.props.cartType) {
          case 'PREORDER':
            baseApiCheckout = preOrderCheckoutPath;
            break;
          default:
            baseApiCheckout = orderCheckoutPath;
            break;
        }

        const checkoutApi = api(baseApiCheckout);
        let carts = JSON.stringify(this.props.cart);
        let preorderObj = '{}';

        if (this.props.cartType === 'PREORDER') {
          isPreorder = true;
          let obj = {
            type: 3,
            date: moment().format('YYYY-MM-DD'),
            time: moment().format('HH:mm:ss'),
            people: 1,
            address: this.props.address,
            phone: this.props.phone,
          }
          preorderObj = JSON.stringify(obj)
        }

        let payload = {
          order: carts.toString(),
          preorder: preorderObj,
          promo: '{}',
          payment: payType,
          tableNo: this.props.tableNo === null ? '' : this.props.tableNo,
          token: this.props.token,
          orderTime: this.props.orderTime
        }

        const checkoutRes = await checkoutApi.post(payload);
        if (checkoutRes) { 
          const { errors } = checkoutRes;
          if (errors) {
            if(errors[0].message !== '') {
              orderSuccess = false;
              showNotifications(errors[0].message, 'error', 'Checkout error');
            } else {
              invoice = checkoutRes.invoice;
              orderSuccess = true;
            }
          } 
        }

        if (orderSuccess === true && payType === 'CASHIER') {
          // showNotifications('Terima kasih telah menggunakan OkGo', 'success', 'Checkout');
          this.props.onRefreshCart();
          if (!isPreorder) {
            this.props.history.push('/payment/success');
          } else {
            this.props.history.push('/payment/success-preorder');
          }
        } else if (orderSuccess === true && payType === 'LINKAJA') {
          const dateMs = Date.now() / 1000;
          const trxId = invoice + '-' + dateMs.toString();

          const linkAjaApi = api(linkAjaTokenApi);
          let payload = {
            trxId: trxId,
            total: totalPrice.toString()
          }
          const linkAjaApiRes = await linkAjaApi.post(payload);
          const { errors } = linkAjaApiRes;
          if (linkAjaApiRes) {
            if (errors) {
              showNotifications('Maaf terjadi kesalahan pada app, mohon hubungi pihak Okgo', 'error', 'Change Password');
            } else {
              const { pgpToken, refNum } = linkAjaApiRes;
              if (refNum !== null || refNum !== '') {
                var form = document.createElement("form");
                form.setAttribute("method", "post");
                form.setAttribute("action", linkAjaCheckoutApi);

                var hiddenField = document.createElement("input"); 
                hiddenField.setAttribute("type", "hidden");
                hiddenField.setAttribute("name", "Message");
                hiddenField.setAttribute("value", pgpToken);
                form.appendChild(hiddenField);
                document.body.appendChild(form);

                form.submit();
                this.props.onRefreshCart();
              }
            } 
          }
        } else if(orderSuccess === true && payType === 'OVO') {
          // showNotifications('Terima kasih telah menggunakan OkGo, Check status pembayaran anda di menu My-Order', 'success', 'Checkout');
          this.props.onRefreshCart();

          if (!isPreorder) {
            const queryParams = [];
            queryParams.push('payvia=ovo');
            const queryString = queryParams.join('&');
            this.props.history.push({
              pathname: this.props.match.path + '/success',
              search: '?' + queryString
            });
          } else {
            this.props.history.push('/payment/success-preorder');
          }
        }

        this.setState({loading: false});
      } else {
        this.setState({loading: false});
      }
    }
  }

  render() {
    let pinBox = ( 
      <div className="justify-content-center">
        <h6>PIN CODE</h6>
        <div className="information justify-content-center text-middle">
          Insert ur Pin Code?
        </div>
        <Input 
            elementType={this.state.pinCode.elementType}
            elementConfig={this.state.pinCode.elementConfig}
            value={this.state.pinCode.value}
            isForms='false'
            changed={(event) => this.pinChangedHandler(event)} />
          <div className = "d-flex justify-content-center push-up">
            <RoundedBottom color="black" width="30%" height="auto" className="no-color" clicked={this.payCanceledHandler}>Cancel</RoundedBottom>
            <RoundedBottom color="white" width="30%" height="auto" clicked={() => this.startPaymentHandler('CASHIER')}>Ok</RoundedBottom>
          </div>
          
      </div>
    )

    let eWalletPay = null;
    let paymentBar = null
    if (this.props.parentId !== 172) {
      eWalletPay = (
          <React.Fragment>
            <RoundedBottom disabled={this.state.loading} bgcolor="#571889" className="push-up" color="white" width="50vw" height="10vh" clicked={() => this.startPaymentHandler('OVO')}>OVO</RoundedBottom>
            <RoundedBottom disabled={this.state.loading} bgcolor="#FA0000" className="push-up" color="white" width="50vw" height="10vh" clicked={() => this.startPaymentHandler('LINKAJA')}><img className="img-linkaja" src={imageLinkAja} alt=""></img></RoundedBottom>
            <RoundedBottom disabled={this.state.loading} className="push-up" bgcolor="black" color="white" width="50vw" height="10vh" clicked={this.payWalletContinueHandler}>VOUCHER OKGO</RoundedBottom>
          </React.Fragment>
      )
    }

    let payviaWallet = eWalletPay !== null ? (
      <React.Fragment>
        <div className="push-up text-middle information-header">Or</div>
        <div className="push-up text-middle information-header">Want to pay via eWallet</div>
      </React.Fragment>
    ) : null;
    
    paymentBar = (<div className="app-center">
      <div className="text-middle information-header">For Order Click</div>
      <RoundedBottom disabled={this.state.loading} bgcolor="#2286D8" className="push-up" color="white" width="50vw" height="10vh" clicked={this.payContinuedHandler}>ORDER</RoundedBottom>
      {payviaWallet}
      {eWalletPay}
    </div>)

    return (
      <div className="container">
        <h5 className="text-middle">Payment Method</h5>
        <Modal show={this.state.purchasable} modalClosed={this.payCanceledHandler}>
          {pinBox}
        </Modal>
        <div className="app-center front">
          {this.state.loading === true ? <Spinner></Spinner>: null}
        </div>
        {/* <div className="app-center">
          <div className="text-middle information-header">For Order Click</div>
          <RoundedBottom disabled={this.state.loading} bgcolor="#2286D8" className="push-up" color="white" width="50vw" height="10vh" clicked={this.payContinuedHandler}>ORDER</RoundedBottom>
          {eWalletPay}
        </div> */}
        {paymentBar}
      </div>
    )
  }
}

const mapStateToProps = state => {
  return {
      cart: state.cart.items,
      cartType: state.cart.cartType,
      merchantId: state.cart.merchantId,
      parentId: state.cart.parentId,
      token: state.user.token,
      tableNo: state.cart.table,
      isLogin: state.user.isLoggedIn,
      address: state.cart.address,
      phone: state.cart.phone,
      orderTime: state.cart.time
  };
};

const mapDispatchToProps = dispatch => {
  return {
      onStoreCart: (item) => dispatch(Actions.addCart(item)),
      onRemoveCart: (item) => dispatch(Actions.removeCart(item)),
      onUpdateCart: (item) => dispatch(Actions.updateCart(item)),
      onRefreshCart: () => dispatch(Actions.deleteCart()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (Payment)